// react and js
import { React, useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

// mui
import {
  Step,
  StepLabel,
  Stepper,
  Box,
  Grid,
  Card,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// components
import {
  CustomStepperConnector,
  CustomStepperStepIcon,
  StepSx,
} from "../../components/Stepper";
import backendRequest from "../../components/BackendRequest";
import DialogueDistributionUnavailable from "./DialogueDistributionUnavailable";
import DialoguePaymentServiceMaintenance from "./DialoguePaymentServiceMaintenance";

// hooks
import defines from "../../hooks/defines";
import appContext from "../../hooks/appContext";
import { stateStorageInit } from "../../hooks/stateStorageInit";

// layouts

// pages

// theme

// utils

// widgets
import ProductPlan from "./checkout/ProductPlan";
import ClientAccount from "./checkout/ClientAccount";
import TradingAccounts from "./checkout/TradingAccounts";
import BillingPayment from "./checkout/BillingPayment";
import GettingStarted from "./checkout/GettingStarted";

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  const { stateStorage, setStateStorage } = useContext(appContext);
  const myLocalSettingStorage = JSON.parse(
    sessionStorage.getItem("myLocalSettingStorage")
  );
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);

  const [searchParams] = useSearchParams();
  const planId = searchParams.get("plan_id");
  const hiddenTag = searchParams.get("hidden_tag");
  const productTag = searchParams.get("product_tag");

  const [error, setError] = useState(false);
  const [, setWarning] = useState(false);
  const [, setSuccess] = useState(false);

  const [loading, setLoading] = useState(navigator.onLine);
  const [content, setContent] = useState();
  const [, setReturnMessage] = useState("");

  const [regularRefreshIntervalCounter, setRegularRefreshIntervalCounter] =
    useState(0);

  const [scrollCounter, setScrollCounter] = useState(0);
  const [scrollStepId, setScrollStepId] = useState("product-plan-scroll");

  const recaptchaRef = useRef();

  const hideDialoguesParam = searchParams.get(
    "d22675197fede620cb6c5504ddb4ef0d"
  );
  const [hideDialogues, setHideDialogues] = useState(false);

  if (
    hideDialoguesParam === "d74853d40d50a91d31b1625c1e938fad" &&
    hideDialogues === false
  ) {
    setHideDialogues(true);
  }

  // Example: http://localhost:3000/distribution?product_tag=meta_extender
  // Example: http://localhost:3000/distribution?plan_id=2
  // Example: http://localhost:3000/distribution?plan_id=7
  // Example: http://localhost:3000/distribution?plan_id=69
  // Example: http://localhost:3000/distribution?plan_id=69&hidden_tag=fbcd6ad7c7bee06a77661dd7eb02d334

  // Example: http://localhost:3000/distribution?d22675197fede620cb6c5504ddb4ef0d=d74853d40d50a91d31b1625c1e938fad

  // ######################################################
  // Main backend request
  // ######################################################
  function MainRequest(periodic) {
    if (
      sessionStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      if (periodic === false) {
        setError(false);
        setWarning(false);
        setSuccess(false);
        setReturnMessage("");
        setLoading(true);
      }
    }

    const sendData = {
      request_type: "checkout",
      route_info: "portfolio_request_distribution",
    };

    backendRequest(process.env.REACT_APP_URL_API_CHECKOUT, sendData)
      .then((contentFromBackend) => {
        setError(false);
        setLoading(false);
        setContent(contentFromBackend);

        let IsInList = false;
        let LocalizationLinePredicted = null;
        //
        contentFromBackend.localization.t_localization.forEach((val) => {
          if (
            contentFromBackend.localization.request_location
              .client_ip_country_code === val.country_code
          ) {
            LocalizationLinePredicted = val;
            IsInList = true;
          }
        });
        //
        if (IsInList === false) {
          LocalizationLinePredicted =
            contentFromBackend.localization.t_localization[0];
        }

        setStateStorage((previousState) => ({
          ...previousState,
          retailerInformation: contentFromBackend.retailer,
          localization: contentFromBackend.localization,
          localizationLine: LocalizationLinePredicted,
          localizationLineSelected: LocalizationLinePredicted,
        }));

        let newLocalSettingStorage = JSON.parse(
          sessionStorage.getItem("myLocalSettingStorage")
        );
        if (newLocalSettingStorage === null) {
          newLocalSettingStorage = stateStorageInit;
        }

        newLocalSettingStorage.retailerInformation =
          contentFromBackend.retailer;
        newLocalSettingStorage.localization = contentFromBackend.localization;
        newLocalSettingStorage.localizationLine = LocalizationLinePredicted;
        newLocalSettingStorage.localizationLineSelected =
          LocalizationLinePredicted;
        sessionStorage.setItem(
          "myLocalSettingStorage",
          JSON.stringify(newLocalSettingStorage)
        );

        if (planId === null) {
          if (myLocalSettingStorage !== null) {
            const lastActivity = JSON.parse(
              sessionStorage.getItem("lastActivity")
            );
            const TimestampSecond = Math.round(Date.now() / 1000);
            const lastActivityDiff = TimestampSecond - lastActivity;

            // if (myLocalSettingStorage.distributionCurrentStep !== 4 && lastActivityDiff < 18)
            if (periodic === false) {
              if (
                myLocalSettingStorage.distributionCurrentStep !== 4 &&
                lastActivityDiff < 3540
              ) {
                setStateStorage(myLocalSettingStorage);
              } else {
                setStateStorage(stateStorageInit);

                sessionStorage.setItem(
                  "myLocalSettingStorage",
                  JSON.stringify(stateStorageInit)
                );
                sessionStorage.removeItem("myLocalSettingStorage");

                setStateStorage((previousState) => ({
                  ...previousState,
                  retailerInformation: contentFromBackend.retailer,
                  localization: contentFromBackend.localization,
                  localizationLine: LocalizationLinePredicted,
                  localizationLineSelected: LocalizationLinePredicted,
                }));

                let newLocalSettingStorageInner = JSON.parse(
                  sessionStorage.getItem("myLocalSettingStorage")
                );
                if (newLocalSettingStorageInner === null) {
                  newLocalSettingStorageInner = stateStorageInit;
                }
                newLocalSettingStorageInner.retailerInformation =
                  contentFromBackend.retailer;
                newLocalSettingStorageInner.localization =
                  contentFromBackend.localization;
                newLocalSettingStorageInner.localizationLine =
                  LocalizationLinePredicted;
                newLocalSettingStorageInner.localizationLineSelected =
                  LocalizationLinePredicted;
                sessionStorage.setItem(
                  "myLocalSettingStorage",
                  JSON.stringify(newLocalSettingStorageInner)
                );
              }
            }
          }
        }

        if (planId !== null) {
          const keyOnly = Object.keys(contentFromBackend.distribution_plans);
          for (let i = 0; i < keyOnly.length; i += 1) {
            const keyOnly2 = Object.keys(
              contentFromBackend.distribution_plans[keyOnly[i]]
            );
            for (let x = 0; x < keyOnly2.length; x += 1) {
              if (
                planId ===
                contentFromBackend.distribution_plans[keyOnly[i]][x].plan_id
              ) {
                if (
                  contentFromBackend.distribution_plans[keyOnly[i]][x]
                    .checkout_visibility === "1" &&
                  contentFromBackend.product_visibility[
                    contentFromBackend.distribution_plans[keyOnly[i]][x]
                      .product_name
                  ] === "1"
                ) {
                  setStateStorage((previousState) => ({
                    ...previousState,
                    distributionProduct:
                      contentFromBackend.distribution_plans[keyOnly[i]][x]
                        .product_name,
                    backUrl:
                      contentFromBackend.distribution_plans[keyOnly[i]][x]
                        .home_url,
                    distributionCurrentStep: 1,
                    distributionPlan:
                      contentFromBackend.distribution_plans[keyOnly[i]][x],
                  }));

                  let newLocalSettingStorageInner = JSON.parse(
                    sessionStorage.getItem("myLocalSettingStorage")
                  );
                  if (newLocalSettingStorageInner === null) {
                    newLocalSettingStorageInner = stateStorageInit;
                  }

                  newLocalSettingStorageInner.distributionProduct =
                    contentFromBackend.distribution_plans[keyOnly[i]][
                      x
                    ].product_name;
                  newLocalSettingStorageInner.distributionPlan =
                    contentFromBackend.distribution_plans[keyOnly[i]][x];
                  newLocalSettingStorageInner.backUrl =
                    contentFromBackend.distribution_plans[keyOnly[i]][
                      x
                    ].home_url;
                  newLocalSettingStorageInner.distributionCurrentStep = 1;
                  sessionStorage.setItem(
                    "myLocalSettingStorage",
                    JSON.stringify(newLocalSettingStorageInner)
                  );
                } else if (hiddenTag === "fbcd6ad7c7bee06a77661dd7eb02d334") {
                  setStateStorage((previousState) => ({
                    ...previousState,
                    distributionProduct:
                      contentFromBackend.distribution_plans[keyOnly[i]][x]
                        .product_name,
                    backUrl:
                      contentFromBackend.distribution_plans[keyOnly[i]][x]
                        .home_url,
                    distributionCurrentStep: 1,
                    distributionPlan:
                      contentFromBackend.distribution_plans[keyOnly[i]][x],
                  }));

                  let newLocalSettingStorageInner = JSON.parse(
                    sessionStorage.getItem("myLocalSettingStorage")
                  );
                  if (newLocalSettingStorageInner === null) {
                    newLocalSettingStorageInner = stateStorageInit;
                  }

                  newLocalSettingStorageInner.distributionProduct =
                    contentFromBackend.distribution_plans[keyOnly[i]][
                      x
                    ].product_name;
                  newLocalSettingStorageInner.distributionPlan =
                    contentFromBackend.distribution_plans[keyOnly[i]][x];
                  newLocalSettingStorageInner.backUrl =
                    contentFromBackend.distribution_plans[keyOnly[i]][
                      x
                    ].home_url;
                  newLocalSettingStorageInner.distributionCurrentStep = 1;
                  sessionStorage.setItem(
                    "myLocalSettingStorage",
                    JSON.stringify(newLocalSettingStorageInner)
                  );
                }

                navigate("/distribution");
              }
            }
          }
        }

        if (productTag !== null) {
          const keyOnly = Object.keys(contentFromBackend.distribution_plans);
          for (let i = 0; i < keyOnly.length; i += 1) {
            const keyOnly2 = Object.keys(
              contentFromBackend.distribution_plans[keyOnly[i]]
            );
            for (let x = 0; x < keyOnly2.length; x += 1) {
              if (
                productTag ===
                contentFromBackend.distribution_plans[keyOnly[i]][x].product_tag
              ) {
                if (
                  contentFromBackend.distribution_plans[keyOnly[i]][x]
                    .checkout_visibility === "1" &&
                  contentFromBackend.product_visibility[
                    contentFromBackend.distribution_plans[keyOnly[i]][x]
                      .product_name
                  ] === "1"
                ) {
                  setStateStorage((previousState) => ({
                    ...previousState,
                    distributionProduct:
                      contentFromBackend.distribution_plans[keyOnly[i]][x]
                        .product_name,
                    backUrl:
                      contentFromBackend.distribution_plans[keyOnly[i]][x]
                        .home_url,
                    distributionCurrentStep: 0,
                    distributionPlan:
                      contentFromBackend.distribution_plans[keyOnly[i]][x],
                  }));

                  let newLocalSettingStorageInner = JSON.parse(
                    sessionStorage.getItem("myLocalSettingStorage")
                  );
                  if (newLocalSettingStorageInner === null) {
                    newLocalSettingStorageInner = stateStorageInit;
                  }

                  newLocalSettingStorageInner.distributionProduct =
                    contentFromBackend.distribution_plans[keyOnly[i]][
                      x
                    ].product_name;
                  newLocalSettingStorageInner.distributionPlan =
                    contentFromBackend.distribution_plans[keyOnly[i]][x];
                  newLocalSettingStorageInner.backUrl =
                    contentFromBackend.distribution_plans[keyOnly[i]][
                      x
                    ].home_url;
                  newLocalSettingStorageInner.distributionCurrentStep = 0;
                  sessionStorage.setItem(
                    "myLocalSettingStorage",
                    JSON.stringify(newLocalSettingStorageInner)
                  );

                  navigate("/distribution");

                  break;
                }
              }
            }
          }
        }
      })
      .catch(() => {
        if (navigator.onLine === true) {
          setError(true);
          setLoading(false);
          setReturnMessage("Unknown error!");
        }
      });
  }

  // ######################################################
  // Inital and triggered refreshs
  // ######################################################
  useEffect(() => {
    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ######################################################
  // Regular refreshs
  // ######################################################
  useEffect(() => {
    const timerID = setTimeout(() => {
      setRegularRefreshIntervalCounter(regularRefreshIntervalCounter + 1);
    }, stateStorage.widgetUpdateIntervalDistribution);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  useEffect(() => {
    if (stateStorage.distributionCurrentStep === 0) {
      MainRequest(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  // ######################################################
  // Auto scroll handling
  // ######################################################
  function scrollToStepWithId(stepId) {
    const autoScrollContainer = document.querySelector(
      "#auto-scroll-container"
    );
    const element = document.getElementById(stepId);
    const elementPosition = element.getBoundingClientRect().top;

    let offsetPosition = 0;
    if (isDesktop) {
      offsetPosition =
        elementPosition +
        autoScrollContainer.scrollTop -
        defines.headerHeightDesktop;
    } else {
      offsetPosition =
        elementPosition +
        autoScrollContainer.scrollTop -
        defines.headerHeightMobile;
    }

    if (autoScrollContainer !== null) {
      autoScrollContainer.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      scrollToStepWithId(scrollStepId);
    }, 50);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollCounter]);

  useEffect(() => {
    if (stateStorage.distributionCurrentStep === 0 && activeStep !== 0) {
      setActiveStep(0);
      if (loading === false) {
        setScrollStepId("product-plan-scroll");
        setScrollCounter(scrollCounter + 1);
      }
    }

    if (stateStorage.distributionCurrentStep === 1 && activeStep !== 1) {
      setActiveStep(1);
      if (loading === false) {
        setScrollStepId("client-account-scroll");
        setScrollCounter(scrollCounter + 1);
      }
    }

    if (stateStorage.distributionCurrentStep === 2 && activeStep !== 2) {
      setActiveStep(2);
      if (loading === false) {
        setScrollStepId("trading-accounts-scroll");
        setScrollCounter(scrollCounter + 1);
      }
    }

    if (stateStorage.distributionCurrentStep === 3 && activeStep !== 3) {
      setActiveStep(3);
      if (loading === false) {
        setScrollStepId("subscription-payment-scroll");
        setScrollCounter(scrollCounter + 1);
      }
    }

    if (stateStorage.distributionCurrentStep === 4 && activeStep !== 4) {
      setActiveStep(4);
      if (loading === false) {
        setScrollStepId("getting-started-scroll");
        setScrollCounter(scrollCounter + 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage]);

  useEffect(() => {
    if (!stateStorage.performRescroll) return;

    setStateStorage((previousState) => ({
      ...previousState,
      performRescroll: false,
    }));

    if (
      stateStorage.distributionCurrentStep === 0 &&
      stateStorage.performRescroll === true
    ) {
      if (loading === false) {
        setScrollStepId("product-plan-scroll");
        setScrollCounter(scrollCounter + 1);
      }
    }

    if (
      stateStorage.distributionCurrentStep === 1 &&
      stateStorage.performRescroll === true
    ) {
      if (loading === false) {
        setScrollStepId("client-account-scroll");
        setScrollCounter(scrollCounter + 1);
      }
    }

    if (
      stateStorage.distributionCurrentStep === 2 &&
      stateStorage.performRescroll === true
    ) {
      if (loading === false) {
        setScrollStepId("trading-accounts-scroll");
        setScrollCounter(scrollCounter + 1);
      }
    }

    if (
      stateStorage.distributionCurrentStep === 3 &&
      stateStorage.performRescroll === true
    ) {
      if (loading === false) {
        setScrollStepId("subscription-payment-scroll");
        setScrollCounter(scrollCounter + 1);
      }
    }

    if (
      stateStorage.distributionCurrentStep === 4 &&
      stateStorage.performRescroll === true
    ) {
      if (loading === false) {
        setScrollStepId("getting-started-scroll");
        setScrollCounter(scrollCounter + 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage.performRescroll]);

  useEffect(() => {
    if (stateStorage.distributionCurrentStep === 0) {
      setScrollStepId("product-plan-scroll");
      setScrollCounter(scrollCounter + 1);
    }

    if (stateStorage.distributionCurrentStep === 1) {
      setScrollStepId("client-account-scroll");
      setScrollCounter(scrollCounter + 1);
    }

    if (stateStorage.distributionCurrentStep === 2) {
      setScrollStepId("trading-accounts-scroll");
      setScrollCounter(scrollCounter + 1);
    }

    if (stateStorage.distributionCurrentStep === 3) {
      setScrollStepId("subscription-payment-scroll");
      setScrollCounter(scrollCounter + 1);
    }

    if (stateStorage.distributionCurrentStep === 4) {
      setScrollStepId("getting-started-scroll");
      setScrollCounter(scrollCounter + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  // ######################################################
  // Error step handling
  // ######################################################
  useEffect(() => {
    if (myLocalSettingStorage !== null) {
      if (
        myLocalSettingStorage.distributionErrorStep >
        myLocalSettingStorage.distributionCurrentStep
      ) {
        setStateStorage((previousState) => ({
          ...previousState,
          distributionErrorStep: -1,
          distributionErrorStepCode: "none",
        }));

        let newLocalSettingStorage = JSON.parse(
          sessionStorage.getItem("myLocalSettingStorage")
        );
        if (newLocalSettingStorage === null) {
          newLocalSettingStorage = stateStorageInit;
        }
        newLocalSettingStorage.distributionErrorStep = -1;
        newLocalSettingStorage.distributionErrorStepCode = "none";
        sessionStorage.setItem(
          "myLocalSettingStorage",
          JSON.stringify(newLocalSettingStorage)
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage]);

  // ######################################################
  // Stepper handling
  // ######################################################
  const steps = [
    "Product & Plan",
    "Client Account",
    "Trading Accounts",
    "Billing & Payment",
    "Getting Started",
  ];

  const isStepFailed = (step) => {
    if (myLocalSettingStorage !== null) {
      if (step === myLocalSettingStorage.distributionErrorStep) {
        return true;
      }
    }
    return false;
  };

  const StepperOutlet = (
    <Card
      sx={{
        background: "#F2F2F2",
        border: 0,
        borderColor: "#6D6E70",
        boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
      }}
    >
      <Box
        sx={{
          pt: {
            xs: 2,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 3,
          },
          pb: {
            xs: 2,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 3,
          },
          px: {
            xs: 1,
            sm: 1,
            md: 0,
            lg: 0,
            xl: 0,
          },
        }}
        dir="center"
      >
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<CustomStepperConnector />}
        >
          {steps.map((label, index) => {
            const labelProps = {};
            if (isStepFailed(index)) {
              labelProps.error = true;
            }

            return (
              <Step key={label} sx={StepSx}>
                <StepLabel
                  {...labelProps}
                  StepIconComponent={CustomStepperStepIcon}
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
    </Card>
  );

  // ######################################################
  // Recaptcha outlet (outsourced cause of remounting)
  // ######################################################
  const [RecaptchaOutlet, setRecaptchaOutlet] = useState(null);
  if (myLocalSettingStorage !== null && myLocalSettingStorage !== undefined) {
    if (
      myLocalSettingStorage.retailerInformation !== "" &&
      myLocalSettingStorage.retailerInformation !== undefined &&
      RecaptchaOutlet === null
    ) {
      setRecaptchaOutlet(
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={myLocalSettingStorage.retailerInformation.recaptcha_site_key}
          onErrored={() => {
            setStateStorage((previousState) => ({
              ...previousState,
              recaptcha: false,
              recaptchaValue: "unset",
            }));

            let newLocalSettingStorage = JSON.parse(
              sessionStorage.getItem("myLocalSettingStorage")
            );
            if (newLocalSettingStorage === null) {
              newLocalSettingStorage = stateStorageInit;
            }
            newLocalSettingStorage.recaptcha = false;
            newLocalSettingStorage.recaptchaValue = "unset";
            sessionStorage.setItem(
              "myLocalSettingStorage",
              JSON.stringify(newLocalSettingStorage)
            );
          }}
          onExpired={() => {
            setStateStorage((previousState) => ({
              ...previousState,
              recaptcha: false,
              recaptchaValue: "unset",
            }));

            let newLocalSettingStorage = JSON.parse(
              sessionStorage.getItem("myLocalSettingStorage")
            );
            if (newLocalSettingStorage === null) {
              newLocalSettingStorage = stateStorageInit;
            }
            newLocalSettingStorage.recaptcha = false;
            newLocalSettingStorage.recaptchaValue = "unset";
            sessionStorage.setItem(
              "myLocalSettingStorage",
              JSON.stringify(newLocalSettingStorage)
            );
          }}
          onChange={() => {
            setStateStorage((previousState) => ({
              ...previousState,
              recaptcha: true,
              recaptchaValue: recaptchaRef.current.getValue(),
            }));

            let newLocalSettingStorage = JSON.parse(
              sessionStorage.getItem("myLocalSettingStorage")
            );
            if (newLocalSettingStorage === null) {
              newLocalSettingStorage = stateStorageInit;
            }
            newLocalSettingStorage.recaptcha = true;
            newLocalSettingStorage.recaptchaValue =
              recaptchaRef.current.getValue();
            sessionStorage.setItem(
              "myLocalSettingStorage",
              JSON.stringify(newLocalSettingStorage)
            );
          }}
        />
      );
    }
  }

  // ######################################################
  // Step outlet
  // ######################################################
  const ProductPlanOutlet = (
    <div id="product-plan-scroll">
      {stateStorage.distributionCurrentStep === 0 ? (
        <Box sx={{ mt: 2 }}>{StepperOutlet}</Box>
      ) : null}

      <Box sx={{ mt: 2 }}>
        <ProductPlan
          content={content}
          loadingProp={loading}
          errorProp={error}
        />
      </Box>
    </div>
  );

  // ######################################################
  // Step outlet
  // ######################################################
  const ClientAccountOutlet = (
    <div id="client-account-scroll">
      {stateStorage.distributionCurrentStep === 1 ? (
        <Box sx={{ mt: 2 }}>{StepperOutlet}</Box>
      ) : null}
      <Box sx={{ mt: 2 }}>
        <ClientAccount
          content={content}
          loadingProp={loading}
          errorProp={error}
          RecaptchaOutlet={RecaptchaOutlet}
        />
      </Box>
    </div>
  );

  // ######################################################
  // Step outlet
  // ######################################################
  const TradingAccountsOutlet = (
    <div id="trading-accounts-scroll">
      {stateStorage.distributionCurrentStep === 2 ? (
        <Box sx={{ mt: 2 }}>{StepperOutlet}</Box>
      ) : null}
      <Box sx={{ mt: 2 }}>
        <TradingAccounts
          content={content}
          loadingProp={loading}
          errorProp={error}
        />
      </Box>
    </div>
  );

  // ######################################################
  // Step outlet
  // ######################################################
  const BillingPaymentOutlet = (
    <div id="subscription-payment-scroll">
      {stateStorage.distributionCurrentStep === 3 ? (
        <Box sx={{ mt: 2 }}>{StepperOutlet}</Box>
      ) : null}
      <Box sx={{ mt: 2 }}>
        <BillingPayment
          content={content}
          loadingProp={loading}
          errorProp={error}
        />
      </Box>
    </div>
  );

  // ######################################################
  // Step outlet
  // ######################################################
  const GettingStartedOutlet = (
    <div id="getting-started-scroll">
      {stateStorage.distributionCurrentStep === 4 ? (
        <Box sx={{ mt: 2 }}>{StepperOutlet}</Box>
      ) : null}
      <Box sx={{ mt: 2 }}>
        <GettingStarted
          content={content}
          loadingProp={loading}
          errorProp={error}
        />
      </Box>
    </div>
  );

  // ######################################################
  // Main JSX return
  // ######################################################
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        direction="column"
        alignItems="center"
        sx={{
          width: {
            xs: "95vw",
            sm: "90vw",
            md: "80vw",
            lg: "75vw",
            xl: "70vw",
          },
        }}
      >
        <Stack spacing={0}>
          {process.env.REACT_APP_PAYMENT_SERVICE_MAINTENANCE === "true" &&
          !(hideDialogues === true) ? (
            <DialoguePaymentServiceMaintenance />
          ) : null}

          {stateStorage.localizationLine !== null &&
          stateStorage.localizationLine.availability_distribution !== "1" ? (
            <DialogueDistributionUnavailable />
          ) : null}

          {stateStorage.distributionCurrentStep >= 0 ? ProductPlanOutlet : null}

          {stateStorage.distributionCurrentStep >= 1
            ? ClientAccountOutlet
            : null}

          {stateStorage.distributionCurrentStep >= 2
            ? TradingAccountsOutlet
            : null}

          {stateStorage.distributionCurrentStep >= 3
            ? BillingPaymentOutlet
            : null}

          {stateStorage.distributionCurrentStep >= 4
            ? GettingStartedOutlet
            : null}
        </Stack>
      </Box>
    </Grid>
  );
}
