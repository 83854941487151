// react and js
import PropTypes from "prop-types";
import { React, useState, useContext, useEffect } from "react";

// mui
import { Grid, Box, Typography, Stack, Link, Slide } from "@mui/material";

// components
import IncludedSymbols from "./IncludedSymbols";
import Iconify from "../../../components/Iconify";
import CardOutlet from "../../../components/CardOutlet";
import LoadingAnimation from "../../../components/LoadingAnimation";
import MissingContent from "../../../components/MissingContent";

// hooks
import appContext from "../../../hooks/appContext";
import { stateStorageInit } from "../../../hooks/stateStorageInit";

// layouts

// pages

// theme
import { B1Small } from "../../../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function PlanItemFeature({ feature }) {
  PlanItemFeature.propTypes = {
    feature: PropTypes.any.isRequired,
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "start",
        justifyContent: "start",
      }}
    >
      <Iconify
        color="#61CE70"
        icon="eva:checkmark-outline"
        minWidth={25}
        minHeight={25}
        sx={{ mr: 1, mt: "1px" }}
      />
      {feature}
    </Box>
  );
}

function PlanItem({ plan, selectable, categories }) {
  PlanItem.propTypes = {
    plan: PropTypes.any.isRequired,
    selectable: PropTypes.any.isRequired,
    categories: PropTypes.any.isRequired,
  };

  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);

  const priceBeforeDot = plan.plan_fixed_price_value.substring(
    0,
    plan.plan_fixed_price_value.length - 3
  );
  const priceAfterDot = plan.plan_fixed_price_value.substring(
    plan.plan_fixed_price_value.length - 2,
    plan.plan_fixed_price_value.length - 0
  );

  let paymentTenure = "";
  if (plan.plan_interval_unit === "DAY") {
    paymentTenure = "Daily";
  } else if (plan.plan_interval_unit === "WEEK") {
    paymentTenure = "Weekly";
  } else if (plan.plan_interval_unit === "MONTH") {
    paymentTenure = "Monthly";
  } else if (plan.plan_interval_unit === "YEAR") {
    paymentTenure = "Yearly";
  }

  let paymentCurrency = plan.plan_fixed_price_currency_code;
  if (plan.plan_fixed_price_currency_code === "EUR") {
    paymentCurrency = "€";
  }

  const categorySymbolGroups = {};
  categories[plan.t_tick_data_linking].forEach((item) => {
    const keys = Object.keys(categorySymbolGroups);
    if (keys.includes(item.symbol_group) === true) {
      categorySymbolGroups[item.symbol_group] += 1;
    } else {
      categorySymbolGroups[item.symbol_group] = 1;
    }
  });

  return (
    <Stack>
      <Box
        sx={{
          borderRadius: "0px",
          boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5);",
        }}
      >
        <Box
          sx={{ pb: "0px", px: 2, mb: 2, backgroundColor: "#F2F2F2" }}
          display="block"
          align="center"
        >
          <Typography
            variant="h5"
            display="block"
            align="center"
            sx={{ mt: "14px" }}
          >
            <Link
              variant="h5"
              underline="none"
              color="inherit"
              target="_blank"
              href={plan.home_url}
            >
              {plan.product_name}
            </Link>
          </Typography>

          <Typography variant="contentHuge">{plan.plan_description}</Typography>

          <br />
          <IncludedSymbols
            plan={plan}
            details={categories[plan.t_tick_data_linking]}
          />
        </Box>

        <Box sx={{ px: 2, pb: 2, backgroundColor: "#FFFFFF" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              pt: 3,
            }}
          >
            <Typography
              variant="h3"
              sx={{
                lineHeight: 1,
                display: "flex",
                alignItems: "end",
                mb: "4px",
              }}
            >
              {paymentCurrency}
            </Typography>

            <Typography variant="h1" sx={{ lineHeight: 1 }}>
              {priceBeforeDot}
            </Typography>

            <Typography variant="h3" sx={{ lineHeight: 1, mt: "7px" }}>
              {priceAfterDot}
              <sup>*</sup>
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              pb: 3,
            }}
          >
            <Typography variant="contentMiddle" sx={{ lineHeight: 1 }}>
              {paymentTenure}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box>
              {/* eslint-disable-next-line */}
              {Object.keys(categorySymbolGroups).map((symbolGroup, index) => {
                return (
                  <PlanItemFeature
                    key={index}
                    feature={
                      <Typography variant="contentMiddle">
                        {categorySymbolGroups[symbolGroup] === 1
                          ? `${categorySymbolGroups[symbolGroup]} ${symbolGroup} symbol`
                          : `${categorySymbolGroups[symbolGroup]} ${symbolGroup} symbols`}
                      </Typography>
                    }
                  />
                );
              })}

              <Box sx={{ mt: 2 }} />

              <PlanItemFeature
                feature={
                  <Typography variant="contentMiddle">
                    <Typography variant="contentMiddleBold">{`Low spread `}</Typography>
                    data included
                  </Typography>
                }
              />

              <PlanItemFeature
                feature={
                  <Typography variant="contentMiddle">
                    <Typography variant="contentMiddleBold">{`Medium spread `}</Typography>
                    data included
                  </Typography>
                }
              />

              <PlanItemFeature
                feature={
                  <Typography variant="contentMiddle">
                    <Typography variant="contentMiddleBold">{`High spread `}</Typography>
                    data included
                  </Typography>
                }
              />

              <Box sx={{ mt: 2 }} />

              <PlanItemFeature
                feature={
                  <Typography variant="contentMiddle">
                    <Typography variant="contentMiddleBold">{`Executable installer `}</Typography>
                    Executable installer for MetaTrader 5
                  </Typography>
                }
              />

              <Box sx={{ mt: 2 }} />
            </Box>
          </Box>

          {selectable ? (
            <B1Small
              sx={{ mt: 1 }}
              fullWidth
              variant="contained"
              onClick={() => {
                setStateStorage((previousState) => ({
                  ...previousState,
                  authState: "login",
                  distributionTickDataCurrentStep: 1,
                  distributionTickDataPlan: plan,
                }));

                let newLocalSettingStorage = JSON.parse(
                  sessionStorage.getItem("myLocalSettingStorage")
                );
                if (newLocalSettingStorage === null) {
                  newLocalSettingStorage = stateStorageInit;
                }

                newLocalSettingStorage.authState = "login";
                newLocalSettingStorage.distributionTickDataCurrentStep = 1;
                newLocalSettingStorage.distributionTickDataPlan = plan;
                sessionStorage.setItem(
                  "myLocalSettingStorage",
                  JSON.stringify(newLocalSettingStorage)
                );
              }}
            >
              Select
            </B1Small>
          ) : null}
        </Box>
      </Box>
    </Stack>
  );
}

function ListAvailablePlans({ plans, categories }) {
  ListAvailablePlans.propTypes = {
    plans: PropTypes.any.isRequired,
    categories: PropTypes.any.isRequired,
  };

  let isPrintable = false;

  if (plans !== undefined) {
    if (plans !== null) {
      if (Object.keys(plans).length > 0) {
        isPrintable = true;
      }
    }
  }

  let widthXs = 12;
  let widthSm = 6;
  let widthMd = 4;

  let visiblePlans = 0;
  plans.forEach((plan) => {
    if (plan.checkout_visibility === "1") {
      visiblePlans += 1;
    }
  });

  if (visiblePlans % 3 === 1) {
    widthXs = 12;
    widthSm = 12;
    widthMd = 12;
  } else if (visiblePlans % 3 === 2) {
    widthXs = 12;
    widthSm = 6;
    widthMd = 6;
  } else if (visiblePlans % 3 === 3) {
    widthXs = 12;
    widthSm = 6;
    widthMd = 4;
  }

  return isPrintable ? (
    <Grid container spacing={2}>
      {plans.map((plan, index) =>
        plan.checkout_visibility === "1" ? (
          <Grid key={index} item xs={widthXs} sm={widthSm} md={widthMd}>
            <PlanItem
              plan={plan}
              selectable={Boolean(true)}
              categories={categories}
            />
          </Grid>
        ) : null
      )}
    </Grid>
  ) : null;
}

function WidgetMainContentProcessed({ content }) {
  WidgetMainContentProcessed.propTypes = {
    content: PropTypes.any.isRequired,
  };

  const plans = [];

  for (
    let index = 0;
    index <
    content.distribution_mt5_tick_data_plans.distribution_mt5_tick_data_plans
      .length;
    index += 1
  ) {
    plans.push(
      content.distribution_mt5_tick_data_plans.distribution_mt5_tick_data_plans[
        index
      ]
    );
  }

  const [timeCounterSlider] = useState(0);
  const [sliderStatus, setSliderStatus] = useState(false);

  useEffect(() => {
    const timerID = setTimeout(() => {
      setSliderStatus(true);
    }, 80);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeCounterSlider]);

  return (
    <CardOutlet
      variant="outlet"
      backgroundProp="#F2F2F2"
      title="Product & Plan"
      subheader="Please choose a plan that suits your requirements."
      mainOutlet={
        <>
          <Stack spacing={3} sx={{ mt: 2 }}>
            <Box sx={{ height: "min-content", minHeight: 250 }}>
              <Slide direction="up" in={sliderStatus}>
                <Box>
                  {sliderStatus ? (
                    <ListAvailablePlans
                      plans={plans}
                      categories={
                        content.distribution_mt5_tick_data_plans
                          .availability_cats
                      }
                      content={content}
                    />
                  ) : null}
                </Box>
              </Slide>
            </Box>
          </Stack>

          <Box
            sx={{
              mt: 1,
              mb: 0,
            }}
          >
            <Typography variant="contentTiny">
              <sup>*</sup> Price inclusive taxes.
            </Typography>
          </Box>
        </>
      }
    />
  );
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function ProductPlan({ content, loadingProp, errorProp }) {
  ProductPlan.propTypes = {
    content: PropTypes.any,
    loadingProp: PropTypes.any.isRequired,
    errorProp: PropTypes.any.isRequired,
  };

  const { stateStorage } = useContext(appContext);

  let ProductPlanOptionsOutlet = <MissingContent message="Product & Plan" />;
  if (loadingProp) {
    ProductPlanOptionsOutlet = <LoadingAnimation title="Product & Plan" />;
  } else if (errorProp) {
    ProductPlanOptionsOutlet = <MissingContent message="Product & Plan" />;
  } else if (content) {
    ProductPlanOptionsOutlet = <WidgetMainContentProcessed content={content} />;
  }

  let ProductPlanSelectedOutlet = <MissingContent message="Product & Plan" />;
  if (stateStorage !== null) {
    if (loadingProp) {
      ProductPlanSelectedOutlet = <LoadingAnimation title="Product & Plan" />;
    } else if (errorProp) {
      ProductPlanSelectedOutlet = <MissingContent message="Product & Plan" />;
    } else if (content && stateStorage.distributionTickDataCurrentStep > 0) {
      ProductPlanSelectedOutlet = (
        <CardOutlet
          variant="outlet"
          backgroundProp="#F2F2F2"
          title="Product & Plan"
          subheader="The plan detailed below has been selected and will be activated once the checkout process is complete."
          mainOutlet={
            stateStorage !== null &&
            stateStorage.distributionTickDataPlan !== null ? (
              <>
                <Grid
                  container
                  spacing={0}
                  sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <PlanItem
                      plan={stateStorage.distributionTickDataPlan}
                      selectable={Boolean(false)}
                      categories={
                        content.distribution_mt5_tick_data_plans
                          .availability_cats
                      }
                    />
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    mt: 1,
                    mb: 0,
                  }}
                >
                  <Typography variant="contentTiny">
                    <sup>*</sup> Price inclusive taxes.
                  </Typography>
                </Box>
              </>
            ) : null
          }
        />
      );
    }
  }

  return stateStorage.distributionTickDataCurrentStep === 0
    ? ProductPlanOptionsOutlet
    : ProductPlanSelectedOutlet;
}
