// react and js
import PropTypes from "prop-types";
import * as Yup from "yup";
import { React, useState, useRef, useEffect, useContext } from "react";
import { useFormik, Form, FormikProvider } from "formik";

// mui
import {
  Stack,
  Select,
  NativeSelect,
  Grid,
  FormControl,
  Box,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Autocomplete from "@mui/material/Autocomplete";

// components
import CardOutlet from "../../../components/CardOutlet";
import LoadingAnimation from "../../../components/LoadingAnimation";
import MissingContent from "../../../components/MissingContent";
import RequestMessage from "../../../components/RequestMessage";
import backendRequest from "../../../components/BackendRequest";

// hooks
import appContext from "../../../hooks/appContext";
import { stateStorageInit } from "../../../hooks/stateStorageInit";

// layouts

// pages

// theme
import {
  B1Small,
  AuthenticationButton,
  AuthenticationTextField,
  ModalContentMenuItem,
  ModalContentOutlinedInput,
  AutocompleteOption,
  ModalContentTextField,
} from "../../../theme/styled";

// utils
import { combineReturnMessage } from "../../../utils/common";

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
export default function TradingAccounts({ content, loadingProp, errorProp }) {
  TradingAccounts.propTypes = {
    content: PropTypes.any,
    loadingProp: PropTypes.any.isRequired,
    errorProp: PropTypes.any.isRequired,
  };

  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);
  const myLocalSettingStorage = JSON.parse(
    sessionStorage.getItem("myLocalSettingStorage")
  );

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const wrapperRef = useRef(null);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [warning, setWarning] = useState(false);
  const [returnMessage, setReturnMessage] = useState("");

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setError(false);
        setSuccess(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside, {
      passive: false,
      capture: false,
    });
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef]);

  const selectedTypeInitValue = "live";
  const selectedServerInitValue = "";
  const selectedAccountInitValue = "";

  const KnownAccountServerShown = { known_account: [], known_server: [] };

  const [accountType, setAccountType] = useState(selectedTypeInitValue);

  let LiveAdable = false;
  let DemoAdable = false;

  if (stateStorage.promotionPromotionPartner !== null) {
    if (
      stateStorage.promotionPromotionPartner
        .promotion_live_included_accounts !== "0"
    ) {
      LiveAdable = true;
    }
    if (
      stateStorage.promotionPromotionPartner
        .promotion_demo_included_accounts !== "0"
    ) {
      DemoAdable = true;
    }
  }

  function PaymentSuccess(
    typeParam,
    accountParam,
    serverParam,
    additionalInformationParam,
    setSubmitting
  ) {
    const SubmitRequestContent = {
      request_type: "checkout",
      route_info: "payment_success",
      authentication: {
        dashboard_access_token:
          myLocalSettingStorage.userInformation.answer.dashboard_access_token,
        dashboard_id:
          myLocalSettingStorage.userInformation.account_settings.config_data
            .config_data_client.dashboard_id,
      },
      selection: {
        mode: "promotion",
        promoter_link_id:
          myLocalSettingStorage.promotionPromotionPartner.promoter_link_id,
      },
      payment_info: {
        type: "application",
        payment_method: "sponsored",
      },
      accounts: [
        {
          operation_id: 1,
          type: typeParam,
          account: accountParam,
          server: serverParam,
          additional_information: additionalInformationParam,
        },
      ],
    };

    backendRequest(process.env.REACT_APP_URL_API_CHECKOUT, SubmitRequestContent)
      .then((contentFromBackend) => {
        const keyOnly = Object.keys(contentFromBackend);
        const checkKey = keyOnly.includes("message");
        if (checkKey === true) {
          if (contentFromBackend.message === "success") {
            setError(false);
            // setSuccess(true);
            combineReturnMessage(contentFromBackend, setReturnMessage);

            setStateStorage((previousState) => ({
              ...previousState,
              promotionCurrentStep: 3,
              promotionAccountType: SubmitRequestContent.accounts[0].type,
              promotionAccountNumber: SubmitRequestContent.accounts[0].server,
              promotionTradingServer: SubmitRequestContent.accounts[0].account,
              promotionAdditionalInformation: SubmitRequestContent.accounts[0].additional_information,
            }));

            let newLocalSettingStorage = JSON.parse(
              sessionStorage.getItem("myLocalSettingStorage")
            );
            if (newLocalSettingStorage === null) {
              newLocalSettingStorage = stateStorageInit;
            }

            newLocalSettingStorage.promotionCurrentStep = 3;
            newLocalSettingStorage.promotionAccountType =
              SubmitRequestContent.accounts[0].type;
            newLocalSettingStorage.promotionAccountNumber =
              SubmitRequestContent.accounts[0].server;
            newLocalSettingStorage.promotionTradingServer =
              SubmitRequestContent.accounts[0].account;
            newLocalSettingStorage.promotionAdditionalInformation =
              SubmitRequestContent.accounts[0].additional_information;
            sessionStorage.setItem(
              "myLocalSettingStorage",
              JSON.stringify(newLocalSettingStorage)
            );
          } /* else {
            setError(true);
            combineReturnMessage(contentFromBackend, setReturnMessage);

            setStateStorage((previousState) => ({
              ...previousState,
              promotionCurrentStep: 3,
            }));

            let newLocalSettingStorage = JSON.parse(
              sessionStorage.getItem("myLocalSettingStorage")
            );
            if (newLocalSettingStorage === null) {
              newLocalSettingStorage = stateStorageInit;
            }

            newLocalSettingStorage.promotionCurrentStep = 3;
            sessionStorage.setItem(
              "myLocalSettingStorage",
              JSON.stringify(newLocalSettingStorage)
            );
          }
          */
        } else {
          setReturnMessage("Unknown error!");
          setError(true);

          setStateStorage((previousState) => ({
            ...previousState,
            promotionCurrentStep: 3,
          }));

          let newLocalSettingStorage = JSON.parse(
            sessionStorage.getItem("myLocalSettingStorage")
          );
          if (newLocalSettingStorage === null) {
            newLocalSettingStorage = stateStorageInit;
          }

          newLocalSettingStorage.promotionCurrentStep = 3;
          sessionStorage.setItem(
            "myLocalSettingStorage",
            JSON.stringify(newLocalSettingStorage)
          );
        }

        setSubmitting(false);
      })
      .catch(() => {

        if (navigator.onLine === true) {
          setReturnMessage("Unknown error!");
          setError(true);

          setStateStorage((previousState) => ({
            ...previousState,
            promotionCurrentStep: 3,
          }));

          let newLocalSettingStorage = JSON.parse(
            sessionStorage.getItem("myLocalSettingStorage")
          );
          if (newLocalSettingStorage === null) {
            newLocalSettingStorage = stateStorageInit;
          }

          newLocalSettingStorage.promotionCurrentStep = 3;
          sessionStorage.setItem(
            "myLocalSettingStorage",
            JSON.stringify(newLocalSettingStorage)
          );
        }

        setSubmitting(false);
      });
  }

  function RequestBackendAction(
    setSubmitting,
    setErrorProp,
    setSuccessProp,
    setWarningProp,
    setReturnMessageProp,
    SubmitRequestContent
  ) {
    setErrorProp(false);
    setSuccessProp(false);
    setWarningProp(false);
    setReturnMessage("");

    backendRequest(process.env.REACT_APP_URL_API_CHECKOUT, SubmitRequestContent)
      .then((contentFromBackend) => {
        const keyOnly = Object.keys(contentFromBackend);
        const checkKey = keyOnly.includes("message");

        if (checkKey === true) {
          if (contentFromBackend.message === "success") {
            PaymentSuccess(
              SubmitRequestContent.accounts[0].type,
              SubmitRequestContent.accounts[0].account,
              SubmitRequestContent.accounts[0].server,
              SubmitRequestContent.accounts[0].additional_information,
              setSubmitting
            );
          } else {
            setErrorProp(true);
            combineReturnMessage(contentFromBackend, setReturnMessageProp);
            setSubmitting(false);
          }
        } else {
          setWarningProp(true);
          setReturnMessageProp("Unknown error!");
          setSubmitting(false);
        }
      })
      .catch(() => {
        setReturnMessageProp("Unknown error!");
        setErrorProp(true);
        setSubmitting(false);
      });
  }

  let keys = [];

  if (
    myLocalSettingStorage.promotionPromotionPartner
      .promotion_additional_information !== null &&
    myLocalSettingStorage.promotionPromotionPartner
      .promotion_additional_information !== undefined
  ) {
    keys = Object.keys(
      myLocalSettingStorage.promotionPromotionPartner
        .promotion_additional_information.values
    );
  }

  const myValidationSchemeJson = {
    selected_account: Yup.number()
      .required("Account number is required")
      .positive()
      .integer(),
    selected_server: Yup.string().required("Trading server is required"),
  };

  const initialValuesJson = {
    selected_account: selectedAccountInitValue,
    selected_server: selectedServerInitValue,
  };

  keys.forEach((key) => {
    if (
      myLocalSettingStorage.promotionPromotionPartner
        .promotion_additional_information.values[key].data_type === "int"
    ) {
      myValidationSchemeJson[key] = Yup.number()
        .required(
          `${myLocalSettingStorage.promotionPromotionPartner.promotion_additional_information.values[key].caption} is required`
        )
        .positive()
        .integer();
    } else if (
      myLocalSettingStorage.promotionPromotionPartner
        .promotion_additional_information.values[key].data_type === "string"
    ) {
      myValidationSchemeJson[key] = Yup.string().required(
        `${myLocalSettingStorage.promotionPromotionPartner.promotion_additional_information.values[key].caption} is required`
      );
    }

    initialValuesJson[key] = "";
  });

  const myValidationScheme = Yup.object().shape(myValidationSchemeJson);

  const formik = useFormik({
    initialValues: initialValuesJson,
    validationSchema: myValidationScheme,
    onSubmit: (values, { setSubmitting }) => {
      const AdditionalInformationArray = {};

      keys.forEach((key) => {
        AdditionalInformationArray[key] = values[key];
      });

      const SubmitRequestContent = {
        request_type: "checkout",
        route_info: "verify_accounts",
        authentication: {
          dashboard_access_token:
            myLocalSettingStorage.userInformation.answer.dashboard_access_token,
        },
        selection: {
          mode: "promotion",
          promoter_link_id:
            myLocalSettingStorage.promotionPromotionPartner.promoter_link_id,
        },
        accounts: [
          {
            operation_id: 1,
            type: accountType,
            account: values.selected_account,
            server: values.selected_server,
            additional_information: AdditionalInformationArray,
          },
        ],
      };

      RequestBackendAction(
        setSubmitting,
        setError,
        setSuccess,
        setWarning,
        setReturnMessage,
        SubmitRequestContent
      );
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  let subheaderMessage = (
    <Typography variant="contentMiddle">
      Please add your first MetaTrader
      <Typography variant="contentMiddleBold">{` live or demo `}</Typography>
      trading account below. Remember that only
      <Typography variant="contentMiddleBold">{` newly opened account `}</Typography>
      from the selected promotion partner can be used to proceed! The server name and account number must be the same as the one you use to log in to the MetaTrader trading platform.
    </Typography>
  );

  if (LiveAdable === false && DemoAdable === true) {
    subheaderMessage = (
      <Typography variant="contentMiddle">
        Please add your MetaTrader
        <Typography variant="contentMiddleBold">{` demo `}</Typography>
        trading account below. Remember that only
        <Typography variant="contentMiddleBold">{` newly opened account `}</Typography>
        from the selected promotion broker can be used to proceed! The server name and account number must be the same as the one you use to log in to the MetaTrader trading platform.
      </Typography>
    );
  }
  if (LiveAdable === true && DemoAdable === false) {
    subheaderMessage = (
      <Typography variant="contentMiddle">
        Please add your MetaTrader
        <Typography variant="contentMiddleBold">{` live `}</Typography>
        trading account below. Remember that only
        <Typography variant="contentMiddleBold">{` newly opened account `}</Typography>
        from the selected promotion broker can be used to proceed! The server name and account number must be the same as the one you use to log in to the MetaTrader trading platform.
      </Typography>
    );
  }

  const TradingAccountsOptionsOutlet = (
    <CardOutlet
      variant="outlet"
      backgroundProp="#F2F2F2"
      title="Trading Account"
      subheader={subheaderMessage}
      mainOutlet={
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={1} sx={{ mt: 1 }}>
              {LiveAdable === true && DemoAdable === true ? (
                // eslint-disable-next-line
                <>
                  {isDesktop ? (
                    <Box>
                      <Typography variant="contentSmall">
                        Account type
                      </Typography>
                      <FormControl fullWidth>
                        <Select
                          value={accountType}
                          onChange={(event) => {
                            setAccountType(event.target.value);
                          }}
                          input={<ModalContentOutlinedInput />}
                        >
                          <ModalContentMenuItem value="live">
                            Live
                          </ModalContentMenuItem>
                          <ModalContentMenuItem value="demo">
                            Demo
                          </ModalContentMenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  ) : (
                    <Box>
                      <Typography variant="contentSmall">
                        Account type
                      </Typography>
                      <FormControl fullWidth>
                        <NativeSelect
                          value={accountType}
                          onChange={(event) => {
                            setAccountType(event.target.value);
                          }}
                          input={<ModalContentOutlinedInput />}
                        >
                          <option value="live">Live</option>
                          <option value="demo">Demo</option>
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  )}
                </>
              ) : null}

              <Box>
                <Typography
                  variant="contentSmall"
                  sx={{
                    color:
                      Boolean(
                        touched.selected_account && errors.selected_account
                      ) === true
                        ? "red"
                        : "none",
                  }}
                >
                  {Boolean(
                    touched.selected_account && errors.selected_account
                  ) === true
                    ? "Invalid account number"
                    : "Account number"}
                </Typography>
                <Autocomplete
                  fullWidth
                  freeSolo
                  size="small"
                  onChange={(event, value) => {
                    values.selected_account = value;
                  }}
                  defaultValue={selectedAccountInitValue}
                  id="combo-box-demo"
                  options={KnownAccountServerShown.known_account}
                  renderOption={(props, option) => (
                    <AutocompleteOption {...props} key={option}>
                      {option}
                    </AutocompleteOption>
                  )}
                  renderInput={(params) => (
                    <ModalContentTextField
                      {...params}
                      type="text"
                      {...getFieldProps("selected_account")}
                      error={Boolean(
                        touched.selected_account && errors.selected_account
                      )}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Box>

              <Box>
                <Box>
                  <Typography
                    variant="contentSmall"
                    sx={{
                      color:
                        Boolean(
                          touched.selected_server && errors.selected_server
                        ) === true
                          ? "red"
                          : "none",
                    }}
                  >
                    {Boolean(
                      touched.selected_server && errors.selected_server
                    ) === true
                      ? "Invalid trading server"
                      : "Trading server"}
                  </Typography>
                  <Autocomplete
                    fullWidth
                    freeSolo
                    size="small"
                    onChange={(event, value) => {
                      values.selected_server = value;
                    }}
                    defaultValue={selectedServerInitValue}
                    id="combo-box-demo"
                    options={KnownAccountServerShown.known_server}
                    renderOption={(props, option) => (
                      <AutocompleteOption {...props} key={option}>
                        {option}
                      </AutocompleteOption>
                    )}
                    renderInput={(params) => (
                      <ModalContentTextField
                        {...params}
                        type="text"
                        {...getFieldProps("selected_server")}
                        error={Boolean(
                          touched.selected_server && errors.selected_server
                        )}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Box>
              </Box>

              {myLocalSettingStorage.promotionPromotionPartner
                .promotion_additional_information !== null ? (
                <>
                  <Box sx={{ pt: 3 }}>
                    <Typography
                      variant="h2"
                      sx={{
                        mb: 1,
                        pl: 2,
                        borderLeft: 1,
                        borderWidth: "3px",
                        borderColor: "#FF9600",
                        borderRadius: "0px 0px 0px 0px",
                        boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
                      }}
                    >
                      Additional Information
                    </Typography>

                    <Box dir="ltr">
                      <Typography variant="contentMiddle">
                        To ensure accurate identification, we need some more
                        details from your broker. Could you kindly provide them
                        below?
                        {myLocalSettingStorage.promotionPromotionPartner
                          .promotion_additional_information.help_text !== "" ? (
                          <>
                            <br />
                            <br />
                            {
                              myLocalSettingStorage.promotionPromotionPartner
                                .promotion_additional_information.help_text
                            }
                          </>
                        ) : null}
                      </Typography>
                    </Box>
                  </Box>

                  {keys.map((keySelected, index) => (
                    <Box key={index}>
                      <Typography
                        variant="contentSmall"
                        sx={{
                          color:
                            Boolean(
                              touched[keySelected] && errors[keySelected]
                            ) === true
                              ? "red"
                              : "none",
                        }}
                      >
                        {Boolean(
                          touched[keySelected] && errors[keySelected]
                        ) === true
                          ? `Invalid ${myLocalSettingStorage.promotionPromotionPartner.promotion_additional_information.values[keySelected].caption}`
                          : `${myLocalSettingStorage.promotionPromotionPartner.promotion_additional_information.values[keySelected].caption}`}
                      </Typography>

                      <AuthenticationTextField
                        fullWidth
                        size="small"
                        type="text"
                        {...getFieldProps(keySelected)}
                        sx={{ mt: "4px", mb: 1 }}
                        error={Boolean(
                          touched[keySelected] && errors[keySelected]
                        )}
                      />
                    </Box>
                  ))}
                </>
              ) : null}
              <Box>
                <RequestMessage
                  wrapperRef={wrapperRef}
                  error={error}
                  warning={warning}
                  success={success}
                  returnMessage={returnMessage}
                  marginTopProp={
                    error === true || warning === true || success === true
                      ? 2
                      : 2
                  }
                  marginBottomProp={
                    error === true || warning === true || success === true
                      ? 2
                      : 0
                  }
                  fullWidth
                />
              </Box>
            </Stack>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <B1Small
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    setStateStorage((previousState) => ({
                      ...previousState,
                      authState: "login",
                      promotionCurrentStep: 1,
                    }));

                    let newLocalSettingStorage = JSON.parse(
                      sessionStorage.getItem("myLocalSettingStorage")
                    );
                    if (newLocalSettingStorage === null) {
                      newLocalSettingStorage = stateStorageInit;
                    }

                    newLocalSettingStorage.authState = "login";
                    newLocalSettingStorage.promotionCurrentStep = 1;

                    sessionStorage.setItem(
                      "myLocalSettingStorage",
                      JSON.stringify(newLocalSettingStorage)
                    );
                  }}
                >
                  Back
                </B1Small>
              </Grid>
              <Grid item xs={6} md={6}>
                <AuthenticationButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Continue
                </AuthenticationButton>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      }
    />
  );

  let TradingAccountsSelectedOutlet = (
    <MissingContent message="Trading Accounts" />
  );
  if (stateStorage !== null) {
    if (stateStorage.userInformation !== "") {
      if (loadingProp) {
        TradingAccountsSelectedOutlet = (
          <LoadingAnimation title="Trading Accounts" />
        );
      } else if (errorProp) {
        TradingAccountsSelectedOutlet = (
          <MissingContent message="Trading Accounts" />
        );
      } else if (content && stateStorage.promotionCurrentStep > 2) {
        TradingAccountsSelectedOutlet = (
          <CardOutlet
            variant="outlet"
            backgroundProp="#F2F2F2"
            title="Trading Account"
            subheader="The trading account listed below has been selected."
            mainOutlet={
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    pt: 1,
                    pb: 2,
                    px: 2,
                    mt: 2,
                    mb: 0,
                    background: "white",
                    borderRadius: "0px",
                    boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5);",
                  }}
                >
                  <Stack sx={{ width: "100%" }}>
                    <Box
                      sx={{
                        p: "5px 0px 0px 0px",
                        display: "flex",
                        borderBottom: "1px solid #C8C8C8",
                        borderColor: "#C8C8C8",
                      }}
                    >
                      <Box
                        sx={{
                          width: "40%",
                          pr: 1,
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography variant="contentBig" align="left">
                          Account type
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          width: "60%",
                          pl: 1,
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          variant="contentBig"
                          align="right"
                          sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
                        >
                          {stateStorage.promotionAccountType}
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        p: "5px 0px 0px 0px",
                        display: "flex",
                        borderBottom: "1px solid #C8C8C8",
                        borderColor: "#C8C8C8",
                      }}
                    >
                      <Box
                        sx={{
                          width: "40%",
                          pr: 1,
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography variant="contentBig" align="left">
                          Account number
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          width: "60%",
                          pl: 1,
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          variant="contentBig"
                          align="right"
                          sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
                        >
                          {stateStorage.promotionAccountNumber}
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        p: "5px 0px 0px 0px",
                        display: "flex",
                        borderBottom: "1px solid #C8C8C8",
                        borderColor: "#C8C8C8",
                      }}
                    >
                      <Box
                        sx={{
                          width: "40%",
                          pr: 1,
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography variant="contentBig" align="left">
                          Trading server
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          width: "60%",
                          pl: 1,
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          variant="contentBig"
                          align="right"
                          sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
                        >
                          {stateStorage.promotionTradingServer}
                        </Typography>
                      </Box>
                    </Box>

                    {stateStorage.promotionAdditionalInformation !== null && stateStorage.promotionAdditionalInformation !== undefined ? (
                      <>
                        {keys.map((keySelected, index) => (
                          <Box
                            key={index}
                            sx={{
                              p: "5px 0px 0px 0px",
                              display: "flex",
                              borderBottom: "1px solid #C8C8C8",
                              borderColor: "#C8C8C8",
                            }}
                          >
                            <Box
                              sx={{
                                width: "50%",
                                pr: 1,
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "flex-end",
                              }}
                            >
                              <Typography variant="contentBig" align="left">
                                {
                                  myLocalSettingStorage
                                    .promotionPromotionPartner
                                    .promotion_additional_information.values[
                                    keySelected
                                  ].caption
                                }
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                width: "50%",
                                pl: 1,
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "flex-end",
                              }}
                            >
                              <Typography
                                variant="contentBig"
                                align="right"
                                sx={{
                                  whiteSpace: "normal",
                                  wordBreak: "break-word",
                                }}
                              >
                                {
                                  stateStorage.promotionAdditionalInformation[
                                    keySelected
                                  ]
                                }
                              </Typography>
                            </Box>
                          </Box>
                        ))}
                      </>
                    ) : null}
                  </Stack>
                </Grid>
              </Grid>
            }
          />
        );
      }
    }
  }

  return stateStorage.promotionCurrentStep === 2
    ? TradingAccountsOptionsOutlet
    : TradingAccountsSelectedOutlet;
}
