// react and js
import { React } from "react";

// mui
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import Check from "@mui/icons-material/Check";

// components

// hooks

// layouts

// pages

// theme
import typography from "../theme/typography";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export const CustomStepperConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 15,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#FF9600",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#FF9600",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#969696",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const CustomStepperStepIconRoot = styled("div")(({ ownerState }) => ({
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#FF9600",
  }),
  "& .CustomStepperStepIcon-completedIcon": {
    color: "#FF9600",
    zIndex: 1,
    fontSize: 22,
  },
  "& .CustomStepperStepIcon-circleActive": {
    width: 8,
    height: 8,
    borderRadius: "0%",
    backgroundColor: "#FF9600",
  },
  "& .CustomStepperStepIcon-circleDisabled": {
    width: 8,
    height: 8,
    borderRadius: "0%",
    backgroundColor: "#969696",
  },
  "& .CustomStepperStepIcon-circleError": {
    width: 8,
    height: 8,
    borderRadius: "0%",
    backgroundColor: "#bc2525",
  },
}));

export function CustomStepperStepIcon(props) {
  /* eslint-disable */
  const { active, completed, error, className } = props;
  /* eslint-enable */
  return (
    <CustomStepperStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="CustomStepperStepIcon-completedIcon" />
      ) : (
        // eslint-disable-next-line
        <>
          {active ? (
            // eslint-disable-next-line
            <>
              {error ? (
                <div className="CustomStepperStepIcon-circleError" />
              ) : (
                <div className="CustomStepperStepIcon-circleActive" />
              )}
            </>
          ) : (
            <div className="CustomStepperStepIcon-circleDisabled" />
          )}
        </>
      )}
    </CustomStepperStepIconRoot>
  );
}

export const StepSx = {
  "& .MuiStepLabel-root .Mui-completed": {
    xs: {
      marginTop: "5px",
      color: "#FF9600",
      fontSize: typography.contentTinyBold.fontSize,
      fontWeight: typography.contentTinyBold.fontWeight,
      lineHeight: typography.contentTinyBold.lineHeight,
      letterSpacing: typography.contentTinyBold.letterSpacing,
      wordSpacing: typography.contentTinyBold.wordSpacing,
    },
    md: {
      marginTop: "5px",
      color: "#FF9600",
      fontSize: typography.contentSmallBold.fontSize,
      fontWeight: typography.contentSmallBold.fontWeight,
      lineHeight: typography.contentSmallBold.lineHeight,
      letterSpacing: typography.contentSmallBold.letterSpacing,
      wordSpacing: typography.contentSmallBold.wordSpacing,
    },
  },
  "& .MuiStepLabel-root .Mui-active": {
    xs: {
      marginTop: "5px",
      color: "#FF9600",
      fontSize: typography.contentTinyBold.fontSize,
      fontWeight: typography.contentTinyBold.fontWeight,
      lineHeight: typography.contentTinyBold.lineHeight,
      letterSpacing: typography.contentTinyBold.letterSpacing,
      wordSpacing: typography.contentTinyBold.wordSpacing,
    },
    md: {
      marginTop: "5px",
      color: "#FF9600",
      fontSize: typography.contentSmallBold.fontSize,
      fontWeight: typography.contentSmallBold.fontWeight,
      lineHeight: typography.contentSmallBold.lineHeight,
      letterSpacing: typography.contentSmallBold.letterSpacing,
      wordSpacing: typography.contentSmallBold.wordSpacing,
    },
  },
  "& .MuiStepLabel-root .Mui-disabled": {
    xs: {
      marginTop: "5px",
      color: "#969696",
      fontSize: typography.contentTinyBold.fontSize,
      fontWeight: typography.contentTinyBold.fontWeight,
      lineHeight: typography.contentTinyBold.lineHeight,
      letterSpacing: typography.contentTinyBold.letterSpacing,
      wordSpacing: typography.contentTinyBold.wordSpacing,
    },
    md: {
      marginTop: "5px",
      color: "#969696",
      fontSize: typography.contentSmallBold.fontSize,
      fontWeight: typography.contentSmallBold.fontWeight,
      lineHeight: typography.contentSmallBold.lineHeight,
      letterSpacing: typography.contentSmallBold.letterSpacing,
      wordSpacing: typography.contentSmallBold.wordSpacing,
    },
  },
  "& .MuiStepLabel-root .Mui-error": {
    xs: {
      marginTop: "5px",
      color: "#bc2525",
      fontSize: typography.contentTinyBold.fontSize,
      fontWeight: typography.contentTinyBold.fontWeight,
      lineHeight: typography.contentTinyBold.lineHeight,
      letterSpacing: typography.contentTinyBold.letterSpacing,
      wordSpacing: typography.contentTinyBold.wordSpacing,
    },
    md: {
      marginTop: "5px",
      color: "#bc2525",
      fontSize: typography.contentSmallBold.fontSize,
      fontWeight: typography.contentSmallBold.fontWeight,
      lineHeight: typography.contentSmallBold.lineHeight,
      letterSpacing: typography.contentSmallBold.letterSpacing,
      wordSpacing: typography.contentSmallBold.wordSpacing,
    },
  },
};
