// react and js
import { Navigate, useRoutes } from "react-router-dom";

import { React, useContext, useEffect, useState } from "react";

// mui

// components
import backendRequest from "./components/BackendRequest";

// hooks
import appContext from "./hooks/appContext";
import { stateStorageInit } from "./hooks/stateStorageInit";

// layouts
import Public from "./layouts/Public";

// pages
import Distribution from "./pages/distribution/distribution";
import DistributionTickData from "./pages/distribution_tick_data/distribution_tick_data";
import Promotion from "./pages/promotion/promotion";
import Paddle from "./pages/paddle/paddle";

// theme

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Router() {
  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);
  const [timeCounterRouterUpdate, setTimeCounterRouterUpdate] = useState(0);

  // ######################################################
  // Resetting of last activity
  // ######################################################
  const onMouseMove = () => {
    const TimestampSecond = Math.round(Date.now() / 1000);
    sessionStorage.setItem("lastActivity", JSON.stringify(TimestampSecond));
  };
  window.addEventListener("mousemove", onMouseMove, {
    passive: false,
    capture: false,
  });

  // ######################################################
  // Action on mount
  // ######################################################
  useEffect(() => {
    sessionStorage.setItem("myReauthentication", false);

    const lastActivity = JSON.parse(sessionStorage.getItem("lastActivity"));
    if (lastActivity === null) {
      const TimestampSecond = Math.round(Date.now() / 1000);
      sessionStorage.setItem("lastActivity", JSON.stringify(TimestampSecond));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ######################################################
  // Dashboard logout method
  // ######################################################
  const handleLogout = () => {
    if (
      sessionStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      const myLocalSettingStorage = JSON.parse(
        sessionStorage.getItem("myLocalSettingStorage")
      );

      if (myLocalSettingStorage !== null) {
        const sendData = {
          request_type: "checkout",
          route_info: "authentication",
          client: {
            mode: "logout",
            dashboard_access_token:
              myLocalSettingStorage.userInformation.answer
                .dashboard_access_token,
          },
        };
        backendRequest(process.env.REACT_APP_URL_API_CHECKOUT, sendData).then(
          () => {
            setStateStorage(stateStorageInit);

            sessionStorage.setItem("myLocalSettingStorage", JSON.stringify(""));
            sessionStorage.removeItem("myLocalSettingStorage");
          }
        ).catch(() => {
          setStateStorage(stateStorageInit);

          sessionStorage.setItem("myLocalSettingStorage", JSON.stringify(""));
          sessionStorage.removeItem("myLocalSettingStorage");
        });;
      }
    }
  };

  // ######################################################
  // Dashboard reathenticate method
  // ######################################################
  const handleReathenticate = () => {
    const myLocalSettingStorage = JSON.parse(
      sessionStorage.getItem("myLocalSettingStorage")
    );

    if (
      sessionStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      sessionStorage.setItem("myReauthentication", true);

      if (myLocalSettingStorage !== null) {
        if (
          myLocalSettingStorage.userInformation.answer !== null &&
          myLocalSettingStorage.userInformation.answer !== undefined
        ) {
          if (
            myLocalSettingStorage.userInformation.answer
              .dashboard_access_token !== null &&
            myLocalSettingStorage.userInformation.answer
              .dashboard_access_token !== undefined
          ) {
            const sendData = {
              request_type: "checkout",
              route_info: "authentication",
              client: {
                mode: "reauthenticate",
                dashboard_access_token:
                  myLocalSettingStorage.userInformation.answer
                    .dashboard_access_token,
              },
            };
            backendRequest(process.env.REACT_APP_URL_API_CHECKOUT, sendData)
              .then((contentFromBackend) => {
                const keyOnly = Object.keys(contentFromBackend);
                const checkKey = keyOnly.includes("answer");
                
                if (checkKey === true) {
                  if (contentFromBackend.answer.message === "success") {
                    const newUserInformation =
                      myLocalSettingStorage.userInformation;
                    newUserInformation.answer.dashboard_access_token =
                      contentFromBackend.answer.dashboard_access_token;
                    newUserInformation.answer.dashboard_access_token_expire =
                      contentFromBackend.answer.dashboard_access_token_expire;

                    setStateStorage((previousState) => ({
                      ...previousState,
                      userInformation: newUserInformation,
                    }));

                    const newLocalSettingStorage = myLocalSettingStorage;
                    newLocalSettingStorage.userInformation = newUserInformation;
                    sessionStorage.setItem(
                      "myLocalSettingStorage",
                      JSON.stringify(newLocalSettingStorage)
                    );
                  }
                }

                sessionStorage.setItem("myReauthentication", false);
              })
              .catch(() => {
                if (navigator.onLine === true) {
                  setStateStorage((previousState) => ({
                    ...previousState,
                    userInformation: "",
                  }));

                  sessionStorage.setItem(
                    "myLocalSettingStorage",
                    JSON.stringify("")
                  );
                  sessionStorage.removeItem("myLocalSettingStorage");

                  sessionStorage.setItem("myReauthentication", false);
                }
              });
          }
        }
      }
    }
  };

  // ######################################################
  // For auto reauthenticate and keeping user logged in
  // ######################################################
  useEffect(() => {

    const timerID = setTimeout(() => {
      const myLocalSettingStorage = JSON.parse(
        sessionStorage.getItem("myLocalSettingStorage")
      );
      const lastActivity = JSON.parse(sessionStorage.getItem("lastActivity"));

      if (myLocalSettingStorage !== null && lastActivity !== null) {
        if (
          myLocalSettingStorage.userInformation !== "" &&
          myLocalSettingStorage.userInformation.answer !== null &&
          myLocalSettingStorage.userInformation.answer !== undefined
        ) {
          const DashboardAccessTokenExpire =
            myLocalSettingStorage.userInformation.answer
              .dashboard_access_token_expire;

          const TimestampSecond = Math.round(Date.now() / 1000);
          const ExpiryDiff = DashboardAccessTokenExpire - TimestampSecond;
          const lastActivityDiff = TimestampSecond - lastActivity;

          if (ExpiryDiff > 0) {
            if (ExpiryDiff < 120) {
              if (lastActivityDiff <= 3420) {
                handleReathenticate();
              }
            }

            /*
            if (ExpiryDiff < 3588) {
              if (lastActivityDiff <= 6) {
                handleReathenticate();
              }
            }
            */

            if (ExpiryDiff <= 60) {
              handleLogout();
            }

            /*
            if (ExpiryDiff <= 3582) {
              handleLogout();
            }
            */
          }
        }
      }

      setTimeCounterRouterUpdate(timeCounterRouterUpdate + 1);
    }, 1000);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeCounterRouterUpdate]);

  // ######################################################
  // Router
  // ######################################################
  return useRoutes([
    {
      path: "/",
      element: <Public />,
      children: [
        { path: "/", element: <Distribution /> },
        { path: "/distribution", element: <Distribution /> },
        { path: "/distribution-tick-data", element: <DistributionTickData /> },
        { path: "/promotion", element: <Promotion /> },
        { path: "/paddle", element: <Paddle /> },
      ],
    },
    { path: "*", element: <Navigate to="/" replace /> },
  ]);
}
