// react and js
import { React, useState, useRef, useEffect } from "react";

// mui
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
} from "@mui/material";

// components
import ModalDialog from "../../components/ModalDialog";

// hooks

// layouts

// pages

// theme
import { B1Small } from "../../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function DialoguePromotionUnavailable() {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------

  // eslint-disable-next-line
  const [open, setOpen] = useState(true);

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll] = useState("paper");

  return (
    <ModalDialog
      open={Boolean(true)}
      scroll={scroll}
      outlet={
        <>
          <DialogTitle
            id="dialog-title"
            sx={{
              background: "#F2F2F2",
              textTransform: "none",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              alignContent: "center",
              py: 1,
              pr: 1,
            }}
          >
            <Typography variant="contentBig">
              Promotion offer is not available in your region
            </Typography>
          </DialogTitle>

          <DialogContent
            dividers
            sx={{
              background: "#F2F2F2",
              borderTop: "1px solid #C8C8C8",
              borderBottom: "1px solid #C8C8C8",
            }}
          >
            <Box sx={{ mb: 0 }}>
              <Typography variant="contentMiddle">
                We are sorry to inform you that we are not able to offer our
                promotion option in your region. <br /> <br /> Nevertheless it
                can be our regular subscriptions might be of interest to you!
                Click on the link below to check out the regular subscriptions
                for our products.
              </Typography>
            </Box>
          </DialogContent>

          <DialogActions
            sx={{
              background: "#F2F2F2",
              p: 0,
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <B1Small
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              href={`${process.env.REACT_APP_URL_FRONTEND_CHECKOUT}/distribution`}
            >
              Discover regular subscriptions
            </B1Small>
          </DialogActions>
        </>
      }
    />
  );
}
