// react and js
import PropTypes from "prop-types";
import * as Yup from "yup";
import { React, useContext, useState, useEffect } from "react";
import { useFormik, FormikProvider } from "formik";
import { initializePaddle } from "@paddle/paddle-js";

// mui
import {
  Link,
  Box,
  Typography,
  Grid,
  Stack,
  LinearProgress,
} from "@mui/material";

// components
import CardOutlet from "../../../components/CardOutlet";
import LoadingAnimation from "../../../components/LoadingAnimation";
import MissingContent from "../../../components/MissingContent";
import CountrySelect, { countries } from "../../../components/CountrySelect";
import backendRequest from "../../../components/BackendRequest";
import DialoguePspWindowOpen from "../DialoguePspWindowOpen";

// hooks
import appContext from "../../../hooks/appContext";
import { stateStorageInit } from "../../../hooks/stateStorageInit";

// layouts

// pages

// theme
import {
  B1Small,
  AuthenticationTextField,
  AuthenticationButton,
  ModalContentSwitch,
} from "../../../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function SubscriptionIdNote() {
  // eslint-disable-next-line
  const myLocalSettingStorage = JSON.parse(
    sessionStorage.getItem("myLocalSettingStorage")
  );

  return (
    <Grid
      item
      xs={12}
      sx={{
        pt: 1,
        pb: 2,
        px: 2,
        mt: 2,
        mb: 0,
        background: "white",
        borderRadius: "0px",
        boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5);",
      }}
    >
      <Stack sx={{ width: "100%" }}>
        <Box
          sx={{
            p: "5px 0px 0px 0px",
            borderBottom: "1px solid #C8C8C8",
            borderColor: "#C8C8C8",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Box
            sx={{
              width: "40%",
              pr: 1,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Typography variant="contentBig" align="left">
              Forename
            </Typography>
          </Box>

          {myLocalSettingStorage !== undefined ? (
            <Box
              sx={{
                width: "60%",
                pl: 1,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Typography
                variant="contentBig"
                align="right"
                sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
              >
                {
                  myLocalSettingStorage.userInformation.account_settings
                    .config_data.config_data_client.forename
                }
              </Typography>
            </Box>
          ) : null}
        </Box>

        <Box
          sx={{
            p: "5px 0px 0px 0px",
            borderBottom: "1px solid #C8C8C8",
            borderColor: "#C8C8C8",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Box
            sx={{
              width: "40%",
              pr: 1,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Typography variant="contentBig" align="left">
              Surname
            </Typography>
          </Box>

          {myLocalSettingStorage !== undefined ? (
            <Box
              sx={{
                width: "60%",
                pl: 1,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Typography
                variant="contentBig"
                align="right"
                sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
              >
                {
                  myLocalSettingStorage.userInformation.account_settings
                    .config_data.config_data_client.surname
                }
              </Typography>
            </Box>
          ) : null}
        </Box>

        <Box
          sx={{
            p: "5px 0px 0px 0px",
            borderBottom: "1px solid #C8C8C8",
            borderColor: "#C8C8C8",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Box
            sx={{
              width: "40%",
              pr: 1,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Typography variant="contentBig" align="left">
              Street
            </Typography>
          </Box>

          {myLocalSettingStorage !== undefined ? (
            <Box
              sx={{
                width: "60%",
                pl: 1,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Typography
                variant="contentBig"
                align="right"
                sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
              >
                {
                  myLocalSettingStorage.userInformation.account_settings
                    .config_data.config_data_client.street
                }
              </Typography>
            </Box>
          ) : null}
        </Box>

        <Box
          sx={{
            p: "5px 0px 0px 0px",
            borderBottom: "1px solid #C8C8C8",
            borderColor: "#C8C8C8",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Box
            sx={{
              width: "40%",
              pr: 1,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Typography variant="contentBig" align="left">
              Optional address line
            </Typography>
          </Box>

          {myLocalSettingStorage !== undefined ? (
            <Box
              sx={{
                width: "60%",
                pl: 1,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Typography
                variant="contentBig"
                align="right"
                sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
              >
                {
                  myLocalSettingStorage.userInformation.account_settings
                    .config_data.config_data_client.optional_line
                }
              </Typography>
            </Box>
          ) : null}
        </Box>

        <Box
          sx={{
            p: "5px 0px 0px 0px",
            borderBottom: "1px solid #C8C8C8",
            borderColor: "#C8C8C8",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Box
            sx={{
              width: "40%",
              pr: 1,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Typography variant="contentBig" align="left">
              Postal code
            </Typography>
          </Box>

          {myLocalSettingStorage !== undefined ? (
            <Box
              sx={{
                width: "60%",
                pl: 1,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Typography
                variant="contentBig"
                align="right"
                sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
              >
                {
                  myLocalSettingStorage.userInformation.account_settings
                    .config_data.config_data_client.postal_code
                }
              </Typography>
            </Box>
          ) : null}
        </Box>

        <Box
          sx={{
            p: "5px 0px 0px 0px",
            borderBottom: "1px solid #C8C8C8",
            borderColor: "#C8C8C8",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Box
            sx={{
              width: "40%",
              pr: 1,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Typography variant="contentBig" align="left">
              City
            </Typography>
          </Box>

          {myLocalSettingStorage !== undefined ? (
            <Box
              sx={{
                width: "60%",
                pl: 1,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Typography
                variant="contentBig"
                align="right"
                sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
              >
                {
                  myLocalSettingStorage.userInformation.account_settings
                    .config_data.config_data_client.city
                }
              </Typography>
            </Box>
          ) : null}
        </Box>

        <Box
          sx={{
            p: "5px 0px 0px 0px",
            borderBottom: "1px solid #C8C8C8",
            borderColor: "#C8C8C8",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Box
            sx={{
              width: "40%",
              pr: 1,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Typography variant="contentBig" align="left">
              Province / State
            </Typography>
          </Box>

          {myLocalSettingStorage !== undefined ? (
            <Box
              sx={{
                width: "60%",
                pl: 1,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Typography
                variant="contentBig"
                align="right"
                sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
              >
                {
                  myLocalSettingStorage.userInformation.account_settings
                    .config_data.config_data_client.province
                }
              </Typography>
            </Box>
          ) : null}
        </Box>

        <Box
          sx={{
            p: "5px 0px 0px 0px",
            borderBottom: "1px solid #C8C8C8",
            borderColor: "#C8C8C8",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Box
            sx={{
              width: "40%",
              pr: 1,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Typography variant="contentBig" align="left">
              Country
            </Typography>
          </Box>

          {myLocalSettingStorage !== undefined ? (
            <Box
              sx={{
                width: "60%",
                pl: 1,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Typography
                variant="contentBig"
                align="right"
                sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
              >
                {
                  myLocalSettingStorage.userInformation.account_settings
                    .config_data.config_data_client.country
                }
              </Typography>
            </Box>
          ) : null}
        </Box>
      </Stack>
    </Grid>
  );
}

function PaddleCustomerSync(
  pspPaddleCustomerId,
  pspPaddleAddressId,
  paymentInfoForename,
  paymentInfoSurname,
  paymentInfoStreet,
  paymentInfoOptionalLine,
  paymentInfoPostalCode,
  paymentInfoCity,
  paymentInfoProvince,
  paymentInfoCountry
) {
  const myLocalSettingStorage = JSON.parse(
    sessionStorage.getItem("myLocalSettingStorage")
  );

  const sendData = {
    request_type: "checkout",
    route_info: "paddle_customer_sync",
    authentication: {
      dashboard_access_token:
        myLocalSettingStorage.userInformation.answer.dashboard_access_token,
      dashboard_id:
        myLocalSettingStorage.userInformation.account_settings.config_data
          .config_data_client.dashboard_id,
    },
    payment_info: {
      psp_paddle_customer_id: pspPaddleCustomerId,
      psp_paddle_address_id: pspPaddleAddressId,
      forename: paymentInfoForename,
      surname: paymentInfoSurname,
      street: paymentInfoStreet,
      optional_line: paymentInfoOptionalLine,
      postal_code: paymentInfoPostalCode,
      city: paymentInfoCity,
      province: paymentInfoProvince,
      country: paymentInfoCountry,
    },
  };

  backendRequest(process.env.REACT_APP_URL_API_CHECKOUT, sendData);
}

function PaymentSuccess(
  activePsp,
  pspIdendifier,
  setStateStorage,
  localizationLineSelected
) {
  const myLocalSettingStorage = JSON.parse(
    sessionStorage.getItem("myLocalSettingStorage")
  );

  const localizationLineSelectedUsed = localizationLineSelected;

  const sendData = {
    request_type: "checkout",
    route_info: "payment_success",
    authentication: {
      dashboard_access_token:
        myLocalSettingStorage.userInformation.answer.dashboard_access_token,
      dashboard_id:
        myLocalSettingStorage.userInformation.account_settings.config_data
          .config_data_client.dashboard_id,
    },
    selection: {
      mode: "distribution",
      distributor_id:
        myLocalSettingStorage.retailerInformation.checkout_distributor_id,
      plan_id: myLocalSettingStorage.distributionTickDataPlan.plan_id,
    },
    payment_info: {
      type: "mt5_tick_data",
      payment_method: activePsp,
      billing_aggreement_id: pspIdendifier,
      localization_line_selected: localizationLineSelectedUsed,
    },
    accounts: [
      {
        operation_id: 1,
        type: "mt5_tick_data",
      },
    ],
  };

  backendRequest(process.env.REACT_APP_URL_API_CHECKOUT, sendData)
    .then((contentFromBackend) => {
      const keyOnly = Object.keys(contentFromBackend);
      const checkKey = keyOnly.includes("message");

      if (checkKey === true) {
        if (contentFromBackend.message === "success") {
          setStateStorage((previousState) => ({
            ...previousState,
            distributionTickDataCurrentStep: 3,
            distributionTickDataSubscriptionId: pspIdendifier,
          }));

          let newLocalSettingStorage = JSON.parse(
            sessionStorage.getItem("myLocalSettingStorage")
          );
          if (newLocalSettingStorage === null) {
            newLocalSettingStorage = stateStorageInit;
          }

          newLocalSettingStorage.distributionTickDataCurrentStep = 3;
          newLocalSettingStorage.distributionTickDataSubscriptionId =
            pspIdendifier;
          sessionStorage.setItem(
            "myLocalSettingStorage",
            JSON.stringify(newLocalSettingStorage)
          );
        } else {
          setStateStorage((previousState) => ({
            ...previousState,
            distributionTickDataCurrentStep: 2,
            distributionTickDataErrorStep: 2,
            distributionTickDataErrorStepCode: "payment_linking_error",
            distributionTickDataSubscriptionId: pspIdendifier,
          }));

          let newLocalSettingStorage = JSON.parse(
            sessionStorage.getItem("myLocalSettingStorage")
          );
          if (newLocalSettingStorage === null) {
            newLocalSettingStorage = stateStorageInit;
          }

          newLocalSettingStorage.distributionTickDataCurrentStep = 2;
          newLocalSettingStorage.distributionTickDataErrorStep = 2;
          newLocalSettingStorage.distributionTickDataErrorStepCode =
            "payment_linking_error";
          newLocalSettingStorage.distributionTickDataSubscriptionId =
            pspIdendifier;
          sessionStorage.setItem(
            "myLocalSettingStorage",
            JSON.stringify(newLocalSettingStorage)
          );
        }
      } else {
        setStateStorage((previousState) => ({
          ...previousState,
          distributionTickDataCurrentStep: 2,
          distributionTickDataErrorStep: 2,
          distributionTickDataErrorStepCode: "payment_linking_error",
          distributionTickDataSubscriptionId: pspIdendifier,
        }));

        let newLocalSettingStorage = JSON.parse(
          sessionStorage.getItem("myLocalSettingStorage")
        );
        if (newLocalSettingStorage === null) {
          newLocalSettingStorage = stateStorageInit;
        }

        newLocalSettingStorage.distributionTickDataCurrentStep = 2;
        newLocalSettingStorage.distributionTickDataErrorStep = 2;
        newLocalSettingStorage.distributionTickDataErrorStepCode =
          "payment_linking_error";
        newLocalSettingStorage.distributionTickDataSubscriptionId =
          pspIdendifier;
        sessionStorage.setItem(
          "myLocalSettingStorage",
          JSON.stringify(newLocalSettingStorage)
        );
      }
    })
    .catch(() => {
      if (navigator.onLine === true) {
        setStateStorage((previousState) => ({
          ...previousState,
          distributionTickDataCurrentStep: 2,
          distributionTickDataErrorStep: 2,
          distributionTickDataErrorStepCode: "payment_linking_error",
          distributionTickDataSubscriptionId: pspIdendifier,
        }));

        let newLocalSettingStorage = JSON.parse(
          sessionStorage.getItem("myLocalSettingStorage")
        );
        if (newLocalSettingStorage === null) {
          newLocalSettingStorage = stateStorageInit;
        }

        newLocalSettingStorage.distributionTickDataCurrentStep = 2;
        newLocalSettingStorage.distributionTickDataErrorStep = 2;
        newLocalSettingStorage.distributionTickDataErrorStepCode =
          "payment_linking_error";
        newLocalSettingStorage.distributionTickDataSubscriptionId =
          pspIdendifier;
        sessionStorage.setItem(
          "myLocalSettingStorage",
          JSON.stringify(newLocalSettingStorage)
        );
      }
    });
}

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function SubscriptionPayment({
  content,
  loadingProp,
  errorProp,
}) {
  SubscriptionPayment.propTypes = {
    content: PropTypes.any,
    loadingProp: PropTypes.any.isRequired,
    errorProp: PropTypes.any.isRequired,
  };

  const [countrySelectRefresh, setCountrySelectRefresh] = useState(1);
  const [postalCodeRefresh, setPostalCodeRefresh] = useState(1);

  const { stateStorage, setStateStorage } = useContext(appContext);
  const [formPpTac, setFormPpTac] = useState(stateStorage.alreadyApprovedPpTac);
  const [actualLocalizationLine, setActualLocalizationLine] = useState(
    stateStorage.localizationLine
  );

  const [paymentSuccessAwaitingCount, setPaymentSuccessAwaitingCount] =
    useState(0);

  const [paddle, setPaddle] = useState(null);
  const [paddleClose, setPaddleClose] = useState(false);

  const [dialoguePspWindowIsOpen, setDialoguePspWindowIsOpen] = useState(false);
  const [pspUrl, setPspUrl] = useState(null);
  const [openedWindowState, setOpenedWindowState] = useState(null);

  const [timeCounterLayoutUpdate, setTimeCounterLayoutUpdate] = useState(0);

  if (openedWindowState !== null) {
    if (openedWindowState.window === null) {
      setOpenedWindowState(null);
      if (dialoguePspWindowIsOpen === true) {
        setDialoguePspWindowIsOpen(false);
      }
    }
  }

  const handleClickClose = () => {
    setDialoguePspWindowIsOpen(false);
    openedWindowState.close();
    setOpenedWindowState(null);
  };
  const handlePspUrlReopen = () => {
    let h = 720;
    let w = 800;
    if (actualLocalizationLine.active_psp === "paypal") {
      h = 720;
      w = 800;
    } else if (actualLocalizationLine.active_psp === "stripe") {
      h = 720;
      w = 1000;
    }

    const y = window.top.outerHeight / 2 + window.top.screenY - h / 2;
    const x = window.top.outerWidth / 2 + window.top.screenX - w / 2;
    const openedWindow = window.open(
      pspUrl,
      "checkout",
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, height=${h}, width=${w}, top=${y}, left=${x}`
    );
    if (openedWindow !== null) {
      openedWindow.focus();
      setDialoguePspWindowIsOpen(true);
    }
    setOpenedWindowState(openedWindow);
  };

  const handlePaymentSuccessAwaiting = () => {
    const myLocalSettingStorage = JSON.parse(
      sessionStorage.getItem("myLocalSettingStorage")
    );

    if (myLocalSettingStorage !== undefined) {
      const sendData = {
        request_type: "checkout",
        route_info: "payment_intent_check",
        payment_intent_token:
          myLocalSettingStorage.distributionTickDataPaymentIntentToken,
      };

      backendRequest(process.env.REACT_APP_URL_API_CHECKOUT, sendData).then(
        (contentFromBackend) => {
          const keyOnly = Object.keys(contentFromBackend);
          const checkKey = keyOnly.includes("message");

          if (checkKey === true) {
            if (contentFromBackend.message === "success") {
              if (contentFromBackend.status === "paid") {
                if (
                  myLocalSettingStorage.distributionTickDataPaymentIntentToken !==
                  null
                ) {
                  if (contentFromBackend.active_psp === "paypal") {
                    PaymentSuccess(
                      "paypal",
                      contentFromBackend.psp_idendifier,
                      setStateStorage,
                      myLocalSettingStorage.localizationLineSelected
                    );
                  } else {
                    setStateStorage((previousState) => ({
                      ...previousState,
                      distributionTickDataCurrentStep: 3,
                      distributionTickDataSubscriptionId:
                        contentFromBackend.psp_idendifier,
                    }));

                    let newLocalSettingStorage = JSON.parse(
                      sessionStorage.getItem("myLocalSettingStorage")
                    );
                    if (newLocalSettingStorage === null) {
                      newLocalSettingStorage = stateStorageInit;
                    }

                    newLocalSettingStorage.distributionTickDataCurrentStep = 3;
                    newLocalSettingStorage.distributionTickDataSubscriptionId =
                      contentFromBackend.psp_idendifier;
                    sessionStorage.setItem(
                      "myLocalSettingStorage",
                      JSON.stringify(newLocalSettingStorage)
                    );
                  }

                  setStateStorage((previousState) => ({
                    ...previousState,
                    distributionTickDataPaymentIntentToken: null,
                  }));

                  let newLocalSettingStorage = JSON.parse(
                    sessionStorage.getItem("myLocalSettingStorage")
                  );
                  if (newLocalSettingStorage === null) {
                    newLocalSettingStorage = stateStorageInit;
                  }
                  newLocalSettingStorage.distributionTickDataPaymentIntentToken =
                    null;
                  sessionStorage.setItem(
                    "myLocalSettingStorage",
                    JSON.stringify(newLocalSettingStorage)
                  );
                }
              }
            }
          }
        }
      );
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setPaymentSuccessAwaitingCount(paymentSuccessAwaitingCount + 1);
      const myLocalSettingStorage = JSON.parse(
        sessionStorage.getItem("myLocalSettingStorage")
      );
      if (myLocalSettingStorage !== undefined) {
        if (
          myLocalSettingStorage.distributionTickDataPaymentIntentToken !== null
        ) {
          handlePaymentSuccessAwaiting();
        }
      }
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentSuccessAwaitingCount]);

  let initCountry = null;
  if (stateStorage != null) {
    if (stateStorage.localization != null) {
      if (
        stateStorage.userInformation.account_settings.config_data
          .config_data_client.country === ""
      ) {
        if (
          stateStorage.localization.request_location.client_ip_country_code !=
          null
        ) {
          countries.forEach((country) => {
            if (
              country.code ===
              stateStorage.localization.request_location.client_ip_country_code
            ) {
              initCountry = country;
            }
          });
        }
      } else {
        countries.forEach((country) => {
          if (
            country.label ===
            stateStorage.userInformation.account_settings.config_data
              .config_data_client.country
          ) {
            initCountry = country;
          }
        });
      }
    }
  }

  useEffect(() => {
    let IsInList = false;
    if (
      stateStorage.userInformation.account_settings.config_data
        .config_data_client.paddle_locked === "0"
    ) {
      stateStorage.localization.t_localization.forEach((val) => {
        if (initCountry.code === val.country_code) {
          setActualLocalizationLine(val);
          IsInList = true;

          setStateStorage((previousState) => ({
            ...previousState,
            localizationLineSelected: val,
          }));

          let newLocalSettingStorage = JSON.parse(
            sessionStorage.getItem("myLocalSettingStorage")
          );
          if (newLocalSettingStorage === null) {
            newLocalSettingStorage = stateStorageInit;
          }
          newLocalSettingStorage.localizationLineSelected = val;
          sessionStorage.setItem(
            "myLocalSettingStorage",
            JSON.stringify(newLocalSettingStorage)
          );
        }
      });
    }
    if (IsInList === false) {
      setActualLocalizationLine(stateStorage.localization.t_localization[0]);

      setStateStorage((previousState) => ({
        ...previousState,
        localizationLineSelected: stateStorage.localization.t_localization[0],
      }));

      let newLocalSettingStorage = JSON.parse(
        sessionStorage.getItem("myLocalSettingStorage")
      );
      if (newLocalSettingStorage === null) {
        newLocalSettingStorage = stateStorageInit;
      }
      newLocalSettingStorage.localizationLineSelected =
        stateStorage.localization.t_localization[0];
      sessionStorage.setItem(
        "myLocalSettingStorage",
        JSON.stringify(newLocalSettingStorage)
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const myValidationScheme = Yup.object().shape({
    forename: Yup.string().required("Forename is required"),
    surname: Yup.string().required("Surname is required"),
    street: Yup.string().required("Account number is required"),
    optional_line: Yup.string(),
    postal_code: Yup.string().required("Postal code is required"),
    city: Yup.string().required("City is required"),
    province: Yup.string().required("Province / State is required"),
  });

  let initForename = "";
  let initSurname = "";
  let initStreet = "";
  let initOptionalLine = "";
  let initPostalCode = "";
  let initCity = "";
  let initProvince = "";

  // If user information is know use it
  if (stateStorage.userInformation !== "") {
    initForename =
      stateStorage.userInformation.account_settings.config_data
        .config_data_client.forename;
    initSurname =
      stateStorage.userInformation.account_settings.config_data
        .config_data_client.surname;
    initStreet =
      stateStorage.userInformation.account_settings.config_data
        .config_data_client.street;
    initOptionalLine =
      stateStorage.userInformation.account_settings.config_data
        .config_data_client.optional_line;
    initPostalCode =
      stateStorage.userInformation.account_settings.config_data
        .config_data_client.postal_code;
    initCity =
      stateStorage.userInformation.account_settings.config_data
        .config_data_client.city;
    initProvince =
      stateStorage.userInformation.account_settings.config_data
        .config_data_client.province;
  }

  const formik = useFormik({
    initialValues: {
      forename: initForename,
      surname: initSurname,
      street: initStreet,
      optional_line: initOptionalLine,
      postal_code: initPostalCode,
      city: initCity,
      province: initProvince,
      country: initCountry,
    },
    validationSchema: myValidationScheme,
    onSubmit: (values, { setSubmitting }) => {
      let sendData = null;

      // Configure request data
      if (actualLocalizationLine.active_psp === "paypal") {
        sendData = {
          request_type: "checkout",
          route_info: "paypal_session_request",
          authentication: {
            dashboard_access_token:
              stateStorage.userInformation.answer.dashboard_access_token,
            dashboard_id:
              stateStorage.userInformation.account_settings.config_data
                .config_data_client.dashboard_id,
          },
          customer_data: {
            email:
              stateStorage.userInformation.account_settings.config_data
                .config_data_client.email,
            forename: values.forename,
            surname: values.surname,
            street: values.street,
            optional_line: values.optional_line,
            postal_code: values.postal_code,
            city: values.city,
            province: values.province,
            country: values.country,
          },
          actual_localization_line: actualLocalizationLine,
          selected_plan_id:
            stateStorage.distributionTickDataPlan.paypal_plan_id,
        };
      } else if (actualLocalizationLine.active_psp === "stripe") {
        sendData = {
          request_type: "checkout",
          route_info: "stripe_session_request",
          authentication: {
            dashboard_access_token:
              stateStorage.userInformation.answer.dashboard_access_token,
            dashboard_id:
              stateStorage.userInformation.account_settings.config_data
                .config_data_client.dashboard_id,
          },
          customer_data: {
            email:
              stateStorage.userInformation.account_settings.config_data
                .config_data_client.email,
            forename: values.forename,
            surname: values.surname,
            street: values.street,
            optional_line: values.optional_line,
            postal_code: values.postal_code,
            city: values.city,
            province: values.province,
            country: values.country,
          },
          actual_localization_line: actualLocalizationLine,
          selected_price_id:
            stateStorage.distributionTickDataPlan.stripe_price_id,
          selection: {
            mode: "distribution",
            distributor_id:
              stateStorage.retailerInformation.checkout_distributor_id,
            plan_id: stateStorage.distributionTickDataPlan.plan_id,
          },
          payment_info: {
            type: "mt5_tick_data",
          },
          accounts: [
            {
              operation_id: 1,
              type: "mt5_tick_data",
            },
          ],
        };
      } else if (actualLocalizationLine.active_psp === "paddle") {
        sendData = {
          request_type: "checkout",
          route_info: "get_latest_paddle_ids",
          authentication: {
            dashboard_access_token:
              stateStorage.userInformation.answer.dashboard_access_token,
            dashboard_id:
              stateStorage.userInformation.account_settings.config_data
                .config_data_client.dashboard_id,
          },
        };
      }

      // Double check locked information
      backendRequest(process.env.REACT_APP_URL_API_CHECKOUT, {
        request_type: "checkout",
        route_info: "get_latest_locked_information",
        authentication: {
          dashboard_access_token:
            stateStorage.userInformation.answer.dashboard_access_token,
          dashboard_id:
            stateStorage.userInformation.account_settings.config_data
              .config_data_client.dashboard_id,
        },
      }).then((contentFromBackendInner) => {
        if (contentFromBackendInner.paddle_locked === "1") {
          setActualLocalizationLine(
            stateStorage.localization.t_localization[0]
          );

          setStateStorage((previousState) => ({
            ...previousState,
            localizationLineSelected:
              stateStorage.localization.t_localization[0],
          }));

          let newLocalSettingStorage = JSON.parse(
            sessionStorage.getItem("myLocalSettingStorage")
          );
          if (newLocalSettingStorage === null) {
            newLocalSettingStorage = stateStorageInit;
          }
          newLocalSettingStorage.localizationLineSelected =
            stateStorage.localization.t_localization[0];
          sessionStorage.setItem(
            "myLocalSettingStorage",
            JSON.stringify(newLocalSettingStorage)
          );

          // Update locally stored user information
          const newUserInformation = stateStorage.userInformation;
          newUserInformation.account_settings.config_data.config_data_client.country_locked =
            contentFromBackendInner.country_locked;
          newUserInformation.account_settings.config_data.config_data_client.paddle_locked =
            contentFromBackendInner.paddle_locked;

          setStateStorage((previousState) => ({
            ...previousState,
            userInformation: newUserInformation,
          }));

          let myLocalSettingStorage = JSON.parse(
            sessionStorage.getItem("myLocalSettingStorage")
          );

          if (myLocalSettingStorage === null) {
            myLocalSettingStorage = stateStorageInit;
          }
          myLocalSettingStorage.userInformation.account_settings.config_data.config_data_client.country_locked =
            contentFromBackendInner.country_locked;
          myLocalSettingStorage.userInformation.account_settings.config_data.config_data_client.paddle_locked =
            contentFromBackendInner.paddle_locked;

          sessionStorage.setItem(
            "myLocalSettingStorage",
            JSON.stringify(myLocalSettingStorage)
          );

          sendData = {
            request_type: "checkout",
            route_info: "get_latest_paddle_ids",
            authentication: {
              dashboard_access_token:
                stateStorage.userInformation.answer.dashboard_access_token,
              dashboard_id:
                stateStorage.userInformation.account_settings.config_data
                  .config_data_client.dashboard_id,
            },
          };
        }

        // Process backend request
        if (
          (actualLocalizationLine.active_psp === "paypal" ||
            actualLocalizationLine.active_psp === "stripe") &&
          contentFromBackendInner.paddle_locked === "0"
        ) {
          backendRequest(process.env.REACT_APP_URL_API_CHECKOUT, sendData)
            .then((contentFromBackend) => {
              setSubmitting(false);

              // Update locally stored user information
              const newUserInformation = stateStorage.userInformation;
              newUserInformation.account_settings.config_data.config_data_client.forename =
                values.forename;
              newUserInformation.account_settings.config_data.config_data_client.surname =
                values.surname;
              newUserInformation.account_settings.config_data.config_data_client.street =
                values.street;
              newUserInformation.account_settings.config_data.config_data_client.optional_line =
                values.optional_line;
              newUserInformation.account_settings.config_data.config_data_client.postal_code =
                values.postal_code;
              newUserInformation.account_settings.config_data.config_data_client.city =
                values.city;
              newUserInformation.account_settings.config_data.config_data_client.province =
                values.province;
              newUserInformation.account_settings.config_data.config_data_client.country =
                values.country.label;

              setStateStorage((previousState) => ({
                ...previousState,
                userInformation: newUserInformation,
              }));

              let myLocalSettingStorage = JSON.parse(
                sessionStorage.getItem("myLocalSettingStorage")
              );

              if (myLocalSettingStorage === null) {
                myLocalSettingStorage = stateStorageInit;
              }
              myLocalSettingStorage.userInformation.account_settings.config_data.config_data_client.forename =
                values.forename;
              myLocalSettingStorage.userInformation.account_settings.config_data.config_data_client.surname =
                values.surname;
              myLocalSettingStorage.userInformation.account_settings.config_data.config_data_client.street =
                values.street;
              myLocalSettingStorage.userInformation.account_settings.config_data.config_data_client.optional_line =
                values.optional_line;
              myLocalSettingStorage.userInformation.account_settings.config_data.config_data_client.postal_code =
                values.postal_code;
              myLocalSettingStorage.userInformation.account_settings.config_data.config_data_client.city =
                values.city;
              myLocalSettingStorage.userInformation.account_settings.config_data.config_data_client.province =
                values.province;
              myLocalSettingStorage.userInformation.account_settings.config_data.config_data_client.country =
                values.country.label;
              sessionStorage.setItem(
                "myLocalSettingStorage",
                JSON.stringify(myLocalSettingStorage)
              );

              // Process answer
              const keyOnly = Object.keys(contentFromBackend);
              const checkKey = keyOnly.includes("message");
              if (checkKey === true) {
                if (contentFromBackend.message === "success") {
                  let h = 720;
                  let w = 800;
                  if (actualLocalizationLine.active_psp === "paypal") {
                    h = 720;
                    w = 800;
                  } else if (actualLocalizationLine.active_psp === "stripe") {
                    h = 720;
                    w = 1000;
                  }

                  const y =
                    window.top.outerHeight / 2 + window.top.screenY - h / 2;
                  const x =
                    window.top.outerWidth / 2 + window.top.screenX - w / 2;
                  const openedWindow = window.open(
                    contentFromBackend.url,
                    "checkout",
                    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, height=${h}, width=${w}, top=${y}, left=${x}`
                  );
                  if (openedWindow !== null) {
                    openedWindow.focus();
                    setPspUrl(contentFromBackend.url);
                    setDialoguePspWindowIsOpen(true);
                    setOpenedWindowState(openedWindow);
                  } else {
                    setStateStorage((previousState) => ({
                      ...previousState,
                      distributionTickDataCurrentStep: 2,
                      distributionTickDataErrorStep: 2,
                      distributionTickDataErrorStepCode:
                        "payment_service_error",
                    }));

                    let newLocalSettingStorage = JSON.parse(
                      sessionStorage.getItem("myLocalSettingStorage")
                    );
                    if (newLocalSettingStorage === null) {
                      newLocalSettingStorage = stateStorageInit;
                    }
                    newLocalSettingStorage.distributionTickDataCurrentStep = 2;
                    newLocalSettingStorage.distributionTickDataErrorStep = 2;
                    newLocalSettingStorage.distributionTickDataErrorStepCode =
                      "payment_service_error";
                    sessionStorage.setItem(
                      "myLocalSettingStorage",
                      JSON.stringify(newLocalSettingStorage)
                    );
                  }

                  setStateStorage((previousState) => ({
                    ...previousState,
                    distributionTickDataPaymentIntentToken:
                      contentFromBackend.payment_intent_token,
                  }));

                  let newLocalSettingStorage = JSON.parse(
                    sessionStorage.getItem("myLocalSettingStorage")
                  );
                  if (newLocalSettingStorage === null) {
                    newLocalSettingStorage = stateStorageInit;
                  }
                  newLocalSettingStorage.distributionTickDataPaymentIntentToken =
                    contentFromBackend.payment_intent_token;
                  sessionStorage.setItem(
                    "myLocalSettingStorage",
                    JSON.stringify(newLocalSettingStorage)
                  );
                } else {
                  setStateStorage((previousState) => ({
                    ...previousState,
                    distributionTickDataCurrentStep: 2,
                    distributionTickDataErrorStep: 2,
                    distributionTickDataErrorStepCode: "payment_service_error",
                  }));

                  let newLocalSettingStorage = JSON.parse(
                    sessionStorage.getItem("myLocalSettingStorage")
                  );
                  if (newLocalSettingStorage === null) {
                    newLocalSettingStorage = stateStorageInit;
                  }
                  newLocalSettingStorage.distributionTickDataCurrentStep = 2;
                  newLocalSettingStorage.distributionTickDataErrorStep = 2;
                  newLocalSettingStorage.distributionTickDataErrorStepCode =
                    "payment_service_error";
                  sessionStorage.setItem(
                    "myLocalSettingStorage",
                    JSON.stringify(newLocalSettingStorage)
                  );
                }
              } else {
                setStateStorage((previousState) => ({
                  ...previousState,
                  distributionTickDataCurrentStep: 2,
                  distributionTickDataErrorStep: 2,
                  distributionTickDataErrorStepCode: "payment_service_error",
                }));

                let newLocalSettingStorage = JSON.parse(
                  sessionStorage.getItem("myLocalSettingStorage")
                );
                if (newLocalSettingStorage === null) {
                  newLocalSettingStorage = stateStorageInit;
                }
                newLocalSettingStorage.distributionTickDataCurrentStep = 2;
                newLocalSettingStorage.distributionTickDataErrorStep = 2;
                newLocalSettingStorage.distributionTickDataErrorStepCode =
                  "payment_service_error";
                sessionStorage.setItem(
                  "myLocalSettingStorage",
                  JSON.stringify(newLocalSettingStorage)
                );
              }
            })
            // eslint-disable-next-line no-console
            .catch(() => {
              setSubmitting(false);
              setStateStorage((previousState) => ({
                ...previousState,
                distributionTickDataCurrentStep: 2,
                distributionTickDataErrorStep: 2,
                distributionTickDataErrorStepCode: "payment_service_error",
              }));

              let newLocalSettingStorage = JSON.parse(
                sessionStorage.getItem("myLocalSettingStorage")
              );
              if (newLocalSettingStorage === null) {
                newLocalSettingStorage = stateStorageInit;
              }
              newLocalSettingStorage.distributionTickDataCurrentStep = 2;
              newLocalSettingStorage.distributionTickDataErrorStep = 2;
              newLocalSettingStorage.distributionTickDataErrorStepCode =
                "payment_service_error";
              sessionStorage.setItem(
                "myLocalSettingStorage",
                JSON.stringify(newLocalSettingStorage)
              );
            });
        } else if (
          actualLocalizationLine.active_psp === "paddle" ||
          contentFromBackendInner.paddle_locked === "1"
        ) {
          backendRequest(process.env.REACT_APP_URL_API_CHECKOUT, sendData)
            .then((contentFromBackend) => {
              // Process request
              setSubmitting(false);

              const myLocalSettingStorage = JSON.parse(
                sessionStorage.getItem("myLocalSettingStorage")
              );

              if (paddle !== undefined) {
                if (contentFromBackend.psp_paddle_customer_id === "") {
                  paddle.Checkout.open({
                    items: [
                      {
                        priceId:
                          stateStorage.distributionTickDataPlan.paddle_price_id,
                      },
                    ],
                    settings: {
                      showAddTaxId: false,
                      allowLogout: false,
                    },
                    customer: {
                      email:
                        stateStorage.userInformation.account_settings
                          .config_data.config_data_client.email,
                      address: {
                        countryCode: values.country.code,
                        postalCode: values.postal_code,
                        region: values.province,
                        city: values.city,
                        firstLine: `${values.forename} ${values.surname}, ${
                          values.optional_line !== ""
                            ? values.optional_line
                            : ""
                        }${values.optional_line !== "" ? ", " : ""}${
                          values.street
                        }`,
                      },
                    },
                    customData: {
                      request_type: "checkout",
                      route_info: "payment_success",
                      authentication: {
                        dashboard_access_token:
                          myLocalSettingStorage.userInformation.answer
                            .dashboard_access_token,
                        dashboard_id:
                          myLocalSettingStorage.userInformation.account_settings
                            .config_data.config_data_client.dashboard_id,
                      },
                      selection: {
                        mode: "distribution",
                        distributor_id:
                          myLocalSettingStorage.retailerInformation
                            .checkout_distributor_id,
                        plan_id:
                          myLocalSettingStorage.distributionTickDataPlan
                            .plan_id,
                      },
                      payment_info: {
                        type: "mt5_tick_data",
                        payment_method: "paddle",
                        localization_line_selected:
                          myLocalSettingStorage.localizationLineSelected,
                        forename: values.forename,
                        surname: values.surname,
                        street: values.street,
                        optional_line: values.optional_line,
                        postal_code: values.postal_code,
                        city: values.city,
                        province: values.province,
                        country: values.country.label,
                      },
                      accounts: [
                        {
                          operation_id: 1,
                          type: "mt5_tick_data",
                        },
                      ],
                    },
                  });
                } else {
                  // Check if country and zip code has been changed
                  let addressDataChanges = false;

                  if (
                    values.country.label !==
                    myLocalSettingStorage.userInformation.account_settings
                      .config_data.config_data_client.country
                  ) {
                    addressDataChanges = true;
                  }

                  if (
                    values.postal_code !==
                    myLocalSettingStorage.userInformation.account_settings
                      .config_data.config_data_client.postal_code
                  ) {
                    addressDataChanges = true;
                  }

                  // Select address data
                  let addressData;
                  if (
                    contentFromBackend.psp_paddle_address_id !== "" &&
                    addressDataChanges === false
                  ) {
                    addressData = {
                      id: contentFromBackend.psp_paddle_address_id,
                    };
                  } else {
                    addressData = {
                      countryCode: values.country.code,
                      postalCode: values.postal_code,
                      region: values.province,
                      city: values.city,
                      firstLine: `${values.forename} ${values.surname}, ${
                        values.optional_line !== "" ? values.optional_line : ""
                      }${values.optional_line !== "" ? ", " : ""}${
                        values.street
                      }`,
                    };
                  }

                  paddle.Checkout.open({
                    items: [
                      {
                        priceId:
                          stateStorage.distributionTickDataPlan.paddle_price_id,
                      },
                    ],
                    settings: {
                      showAddTaxId: false,
                      allowLogout: false,
                    },
                    customer: {
                      id: contentFromBackend.psp_paddle_customer_id,
                      address: addressData,
                    },
                    customData: {
                      request_type: "checkout",
                      route_info: "payment_success",
                      authentication: {
                        dashboard_access_token:
                          myLocalSettingStorage.userInformation.answer
                            .dashboard_access_token,
                        dashboard_id:
                          myLocalSettingStorage.userInformation.account_settings
                            .config_data.config_data_client.dashboard_id,
                      },
                      selection: {
                        mode: "distribution",
                        distributor_id:
                          myLocalSettingStorage.retailerInformation
                            .checkout_distributor_id,
                        plan_id:
                          myLocalSettingStorage.distributionTickDataPlan
                            .plan_id,
                      },
                      payment_info: {
                        type: "mt5_tick_data",
                        payment_method: "paddle",
                        localization_line_selected:
                          myLocalSettingStorage.localizationLineSelected,
                        forename: values.forename,
                        surname: values.surname,
                        street: values.street,
                        optional_line: values.optional_line,
                        postal_code: values.postal_code,
                        city: values.city,
                        province: values.province,
                        country: values.country.label,
                      },
                      accounts: [
                        {
                          operation_id: 1,
                          type: "mt5_tick_data",
                        },
                      ],
                    },
                  });
                }
              }
            })
            // eslint-disable-next-line no-console
            .catch(() => {
              setSubmitting(false);
              setStateStorage((previousState) => ({
                ...previousState,
                distributionCurrentStep: 3,
                distributionErrorStep: 3,
                distributionErrorStepCode: "payment_service_error",
              }));

              let newLocalSettingStorage = JSON.parse(
                sessionStorage.getItem("myLocalSettingStorage")
              );
              if (newLocalSettingStorage === null) {
                newLocalSettingStorage = stateStorageInit;
              }
              newLocalSettingStorage.distributionCurrentStep = 3;
              newLocalSettingStorage.distributionErrorStep = 3;
              newLocalSettingStorage.distributionErrorStepCode =
                "payment_service_error";
              sessionStorage.setItem(
                "myLocalSettingStorage",
                JSON.stringify(newLocalSettingStorage)
              );
            });
        }
      });
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  // Event callback for paddle checkout
  const paddleEventCallback = (EventData) => {
    // checkout.customer.created OR checkout.customer.updated -> Update paddle data if address id is not null
    if (
      EventData.name === "checkout.customer.created" ||
      EventData.name === "checkout.customer.updated"
    ) {
      // Sync paddle customer
      if (EventData.data.customer.address !== null) {
        // Read and convert country and postal from paddle data chunk and sync with checkout
        let countryLabel = null;
        let countryEntry = null;
        countries.forEach((countryIterate) => {
          if (
            countryIterate.code === EventData.data.customer.address.country_code
          ) {
            countryLabel = countryIterate.label;
            countryEntry = countryIterate;
          }
        });

        // Sync remote data
        PaddleCustomerSync(
          EventData.data.customer.id,
          EventData.data.customer.address.id,
          EventData.data.custom_data.payment_info.forename,
          EventData.data.custom_data.payment_info.surname,
          EventData.data.custom_data.payment_info.street,
          EventData.data.custom_data.payment_info.optional_line,
          EventData.data.customer.address.postal_code,
          EventData.data.custom_data.payment_info.city,
          EventData.data.custom_data.payment_info.province,
          countryLabel
        );

        // Update locally stored paddle customer id and address id
        const newUserInformation = stateStorage.userInformation;
        newUserInformation.account_settings.config_data.config_data_client.forename =
          EventData.data.custom_data.payment_info.forename;
        newUserInformation.account_settings.config_data.config_data_client.surname =
          EventData.data.custom_data.payment_info.surname;
        newUserInformation.account_settings.config_data.config_data_client.street =
          EventData.data.custom_data.payment_info.street;
        newUserInformation.account_settings.config_data.config_data_client.optional_line =
          EventData.data.custom_data.payment_info.optional_line;
        newUserInformation.account_settings.config_data.config_data_client.postal_code =
          EventData.data.customer.address.postal_code;
        newUserInformation.account_settings.config_data.config_data_client.city =
          EventData.data.custom_data.payment_info.city;
        newUserInformation.account_settings.config_data.config_data_client.province =
          EventData.data.custom_data.payment_info.province;
        newUserInformation.account_settings.config_data.config_data_client.country =
          countryLabel;

        setStateStorage((previousState) => ({
          ...previousState,
          userInformation: newUserInformation,
        }));

        let myLocalSettingStorage = JSON.parse(
          sessionStorage.getItem("myLocalSettingStorage")
        );

        if (myLocalSettingStorage === null) {
          myLocalSettingStorage = stateStorageInit;
        }
        myLocalSettingStorage.userInformation.account_settings.config_data.config_data_client.forename =
          EventData.data.custom_data.payment_info.forename;
        myLocalSettingStorage.userInformation.account_settings.config_data.config_data_client.surname =
          EventData.data.custom_data.payment_info.surname;
        myLocalSettingStorage.userInformation.account_settings.config_data.config_data_client.street =
          EventData.data.custom_data.payment_info.street;
        myLocalSettingStorage.userInformation.account_settings.config_data.config_data_client.optional_line =
          EventData.data.custom_data.payment_info.optional_line;
        myLocalSettingStorage.userInformation.account_settings.config_data.config_data_client.postal_code =
          EventData.data.customer.address.postal_code;
        myLocalSettingStorage.userInformation.account_settings.config_data.config_data_client.city =
          EventData.data.custom_data.payment_info.city;
        myLocalSettingStorage.userInformation.account_settings.config_data.config_data_client.province =
          EventData.data.custom_data.payment_info.province;
        myLocalSettingStorage.userInformation.account_settings.config_data.config_data_client.country =
          countryLabel;
        sessionStorage.setItem(
          "myLocalSettingStorage",
          JSON.stringify(myLocalSettingStorage)
        );

        // Force update of input elements
        setFieldValue(
          "postal_code",
          EventData.data.customer.address.postal_code,
          false
        );
        setFieldValue("country", countryEntry, false);

        setCountrySelectRefresh(Math.random());
        setPostalCodeRefresh(Math.random());
      }
    }

    if (EventData.name === "checkout.completed") {
      // Switch to getting started
      setStateStorage((previousState) => ({
        ...previousState,
        distributionTickDataCurrentStep: 3,
        distributionTickDataSubscriptionId: "paddle",
      }));

      let newLocalSettingStorage = JSON.parse(
        sessionStorage.getItem("myLocalSettingStorage")
      );
      if (newLocalSettingStorage === null) {
        newLocalSettingStorage = stateStorageInit;
      }

      newLocalSettingStorage.distributionTickDataCurrentStep = 3;
      newLocalSettingStorage.distributionTickDataSubscriptionId = "paddle";
      sessionStorage.setItem(
        "myLocalSettingStorage",
        JSON.stringify(newLocalSettingStorage)
      );

      // Close paddle checkout dialogue
      setPaddleClose(true);
    } else if (EventData.name === "checkout.error") {
      setStateStorage((previousState) => ({
        ...previousState,
        distributionTickDataCurrentStep: 2,
        distributionTickDataErrorStep: 2,
        distributionTickDataErrorStepCode: "payment_service_error",
      }));

      let newLocalSettingStorage = JSON.parse(
        sessionStorage.getItem("myLocalSettingStorage")
      );
      if (newLocalSettingStorage === null) {
        newLocalSettingStorage = stateStorageInit;
      }
      newLocalSettingStorage.distributionTickDataCurrentStep = 2;
      newLocalSettingStorage.distributionTickDataErrorStep = 2;
      newLocalSettingStorage.distributionTickDataErrorStepCode =
        "payment_service_error";
      sessionStorage.setItem(
        "myLocalSettingStorage",
        JSON.stringify(newLocalSettingStorage)
      );
    }
  };

  // Download and initialize Paddle instance from CDN
  if (stateStorage.retailerInformation !== "" && paddle === null) {
    if (stateStorage.retailerInformation.psp_mode === "sandbox") {
      initializePaddle({
        environment: "sandbox",
        token: stateStorage.retailerInformation.paddle_client_sided_token,
        eventCallback: paddleEventCallback,
      }).then((paddleInstance) => {
        if (paddleInstance) {
          setPaddle(paddleInstance);
        }
      });
    }

    if (stateStorage.retailerInformation.psp_mode === "live") {
      initializePaddle({
        environment: "production",
        token: stateStorage.retailerInformation.paddle_client_sided_token,
        eventCallback: paddleEventCallback,
      }).then((paddleInstance) => {
        if (paddleInstance) {
          setPaddle(paddleInstance);
        }
      });
    }
  }

  // Method to close paddle checkout
  if (paddleClose === true) {
    paddle.Checkout.close();
  }

  // Periodic call of update method
  useEffect(() => {
    const timerID = setTimeout(() => {
      setTimeCounterLayoutUpdate(timeCounterLayoutUpdate + 1);
    }, 5000);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeCounterLayoutUpdate]);

  useEffect(() => {
    // Double check locked information
    backendRequest(process.env.REACT_APP_URL_API_CHECKOUT, {
      request_type: "checkout",
      route_info: "get_latest_locked_information",
      authentication: {
        dashboard_access_token:
          stateStorage.userInformation.answer.dashboard_access_token,
        dashboard_id:
          stateStorage.userInformation.account_settings.config_data
            .config_data_client.dashboard_id,
      },
    }).then((contentFromBackendInner) => {
      if (contentFromBackendInner.country_locked === "1") {
        // Update locally stored user information
        const newUserInformation = stateStorage.userInformation;
        newUserInformation.account_settings.config_data.config_data_client.country =
          contentFromBackendInner.country;
        newUserInformation.account_settings.config_data.config_data_client.country_locked =
          contentFromBackendInner.country_locked;

        setStateStorage((previousState) => ({
          ...previousState,
          userInformation: newUserInformation,
        }));

        let myLocalSettingStorage = JSON.parse(
          sessionStorage.getItem("myLocalSettingStorage")
        );

        if (myLocalSettingStorage === null) {
          myLocalSettingStorage = stateStorageInit;
        }
        myLocalSettingStorage.userInformation.account_settings.config_data.config_data_client.country =
          contentFromBackendInner.country;
        myLocalSettingStorage.userInformation.account_settings.config_data.config_data_client.country_locked =
          contentFromBackendInner.country_locked;

        sessionStorage.setItem(
          "myLocalSettingStorage",
          JSON.stringify(myLocalSettingStorage)
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeCounterLayoutUpdate]);

  let SubscriptionPaymentOptionsOutlet = null;
  if (stateStorage !== null) {
    // ######################################################
    // Options step
    // ######################################################
    if (
      stateStorage.distributionTickDataCurrentStep !==
      stateStorage.distributionTickDataErrorStep
    ) {
      SubscriptionPaymentOptionsOutlet = (
        <CardOutlet
          variant="outlet"
          backgroundProp="#F2F2F2"
          title="Billing & Payment"
          subheader="Please review your subscription configuration above. If everything meets your requirements, finalize the process using the payment button below."
          mainOutlet={
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 0,
                mb: 0,
              }}
            >
              {dialoguePspWindowIsOpen === true ? (
                <DialoguePspWindowOpen
                  handleClickClose={handleClickClose}
                  handlePspUrlReopen={handlePspUrlReopen}
                />
              ) : null}

              <FormikProvider value={formik}>
                <Grid item xs={6} md={6} lg={6}>
                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(touched.forename && errors.forename) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(touched.forename && errors.forename) === true
                        ? "Forename is required"
                        : "Forename"}
                    </Typography>
                    <AuthenticationTextField
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("forename")}
                      error={Boolean(touched.forename && errors.forename)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={6} md={6} lg={6}>
                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(touched.surname && errors.surname) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(touched.surname && errors.surname) === true
                        ? "Surname is required"
                        : "Surname"}
                    </Typography>
                    <AuthenticationTextField
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("surname")}
                      error={Boolean(touched.surname && errors.surname)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={6} md={6} lg={6}>
                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(touched.street && errors.street) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(touched.street && errors.street) === true
                        ? "Street is required"
                        : "Street"}
                    </Typography>
                    <AuthenticationTextField
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("street")}
                      error={Boolean(touched.street && errors.street)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={6} md={6} lg={6}>
                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(
                            touched.optional_line && errors.optional_line
                          ) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(
                        touched.optional_line && errors.optional_line
                      ) === true
                        ? "Invalid optional address line"
                        : "Optional address line"}
                    </Typography>
                    <AuthenticationTextField
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("optional_line")}
                      error={Boolean(
                        touched.optional_line && errors.optional_line
                      )}
                    />
                  </Box>
                </Grid>

                <Grid item xs={6} md={6} lg={6}>
                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(touched.postal_code && errors.postal_code) ===
                          true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(touched.postal_code && errors.postal_code) ===
                      true
                        ? "Postal code is required"
                        : "Postal code"}
                    </Typography>
                    <AuthenticationTextField
                      key={postalCodeRefresh}
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("postal_code")}
                      error={Boolean(touched.postal_code && errors.postal_code)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={6} md={6} lg={6}>
                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(touched.city && errors.city) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(touched.city && errors.city) === true
                        ? "City is required"
                        : "City"}
                    </Typography>
                    <AuthenticationTextField
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("city")}
                      error={Boolean(touched.city && errors.city)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={6} md={6} lg={6}>
                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(touched.province && errors.province) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(touched.province && errors.province) === true
                        ? "Province / State is required"
                        : "Province / State"}
                    </Typography>
                    <AuthenticationTextField
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("province")}
                      error={Boolean(touched.province && errors.province)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={6} md={6} lg={6}>
                  <Box>
                    <Box sx={{ mb: "-4px" }}>
                      <Typography variant="contentSmall">Country</Typography>
                    </Box>
                    {stateStorage.userInformation.account_settings.config_data
                      .config_data_client.country_locked === "0" ? (
                      <CountrySelect
                        key={countrySelectRefresh}
                        error={Boolean(touched.country && errors.country)}
                        initCountry={initCountry}
                        localization={stateStorage.localization}
                        setFormCountryEntry={(newValue) => {
                          if (newValue !== null) {
                            values.country = newValue;
                            let IsInList = false;

                            if (
                              stateStorage.userInformation.account_settings
                                .config_data.config_data_client
                                .paddle_locked === "0"
                            ) {
                              stateStorage.localization.t_localization.forEach(
                                (val) => {
                                  if (newValue.code === val.country_code) {
                                    setActualLocalizationLine(val);
                                    IsInList = true;

                                    setStateStorage((previousState) => ({
                                      ...previousState,
                                      localizationLineSelected: val,
                                    }));

                                    let newLocalSettingStorage = JSON.parse(
                                      sessionStorage.getItem(
                                        "myLocalSettingStorage"
                                      )
                                    );
                                    if (newLocalSettingStorage === null) {
                                      newLocalSettingStorage = stateStorageInit;
                                    }
                                    newLocalSettingStorage.localizationLineSelected =
                                      val;
                                    sessionStorage.setItem(
                                      "myLocalSettingStorage",
                                      JSON.stringify(newLocalSettingStorage)
                                    );
                                  }
                                }
                              );
                            }
                            //
                            if (IsInList === false) {
                              setActualLocalizationLine(
                                stateStorage.localization.t_localization[0]
                              );

                              setStateStorage((previousState) => ({
                                ...previousState,
                                localizationLineSelected:
                                  stateStorage.localization.t_localization[0],
                              }));

                              let newLocalSettingStorage = JSON.parse(
                                sessionStorage.getItem("myLocalSettingStorage")
                              );
                              if (newLocalSettingStorage === null) {
                                newLocalSettingStorage = stateStorageInit;
                              }
                              newLocalSettingStorage.localizationLineSelected =
                                stateStorage.localization.t_localization[0];
                              sessionStorage.setItem(
                                "myLocalSettingStorage",
                                JSON.stringify(newLocalSettingStorage)
                              );
                            }
                          } else {
                            setActualLocalizationLine(
                              stateStorage.localizationLine
                            );

                            setStateStorage((previousState) => ({
                              ...previousState,
                              localizationLineSelected:
                                stateStorage.localizationLine,
                            }));

                            let newLocalSettingStorage = JSON.parse(
                              sessionStorage.getItem("myLocalSettingStorage")
                            );
                            if (newLocalSettingStorage === null) {
                              newLocalSettingStorage = stateStorageInit;
                            }
                            newLocalSettingStorage.localizationLineSelected =
                              stateStorage.localizationLine;
                            sessionStorage.setItem(
                              "myLocalSettingStorage",
                              JSON.stringify(newLocalSettingStorage)
                            );
                          }
                        }}
                      />
                    ) : (
                      <Box sx={{ my: 1, ml: "14px" }}>
                        <Typography variant="contentMiddleBold">
                          {initCountry.label}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>

                {stateStorage.alreadyApprovedPpTac === false ? (
                  <Grid item xs={12} md={12} lg={12}>
                    <Box
                      sx={{
                        display: "flex",
                        mb: -3,
                      }}
                    >
                      <Box
                        sx={{
                          width: "80%",
                          pr: 1,
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-end",
                          mt: 1,
                        }}
                      >
                        <Typography variant="contentSmall">
                          I confirm that I have read and agree with the
                          <Link
                            href={`${process.env.REACT_APP_URL_FRONTEND_PRIVACY_POLICY}`}
                            target="_blank"
                            underline="none"
                            variant="contentSmallLink"
                            color="inherit"
                          >
                            {` privacy policy `}
                          </Link>
                          and
                          <Link
                            href={`${process.env.REACT_APP_URL_FRONTEND_TERMS_AND_CONDITIONS}`}
                            target="_blank"
                            underline="none"
                            variant="contentSmallLink"
                            color="inherit"
                          >
                            {` terms and condition`}
                          </Link>
                          .
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          width: "20%",
                          pl: 1,
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        <ModalContentSwitch
                          checked={formPpTac}
                          onChange={(event) => {
                            setFormPpTac(event.target.checked);
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                ) : null}

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Stack sx={{ width: "100%", mt: 3, mb: 0 }}>
                    <Box
                      sx={{
                        mb: "13px",
                      }}
                    >
                      <B1Small
                        fullWidth
                        variant="contained"
                        onClick={() => {
                          setStateStorage((previousState) => ({
                            ...previousState,
                            authState: "login",
                            distributionTickDataCurrentStep: 1,
                          }));

                          let newLocalSettingStorage = JSON.parse(
                            sessionStorage.getItem("myLocalSettingStorage")
                          );
                          if (newLocalSettingStorage === null) {
                            newLocalSettingStorage = stateStorageInit;
                          }

                          newLocalSettingStorage.authState = "login";
                          newLocalSettingStorage.distributionTickDataCurrentStep = 1;

                          sessionStorage.setItem(
                            "myLocalSettingStorage",
                            JSON.stringify(newLocalSettingStorage)
                          );
                        }}
                      >
                        Back
                      </B1Small>
                    </Box>

                    <Box>
                      {actualLocalizationLine !== null ? (
                        // eslint-disable-next-line
                        <>
                          {formPpTac === false ||
                          values.forename === "" ||
                          values.surname === "" ||
                          values.street === "" ||
                          values.postal_code === "" ||
                          values.city === "" ||
                          values.province === "" ? (
                            // eslint-disable-next-line
                            <>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Stack sx={{ width: "100%" }} spacing={2}>
                                  <Grid
                                    container
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      variant="contentBig"
                                      align="center"
                                    >
                                      Please enter your billing address and
                                      accept the privacy policy and terms and
                                      conditions!
                                    </Typography>
                                  </Grid>
                                  <LinearProgress color="inherit" />
                                </Stack>
                              </Box>
                            </>
                          ) : (
                            <>
                              {actualLocalizationLine.active_psp ===
                              "paypal" ? (
                                <>
                                  <AuthenticationButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    onClick={handleSubmit}
                                    loading={isSubmitting}
                                    sx={{
                                      fontSize: "25px",
                                      height: "50px",
                                      color: "white",
                                      backgroundColor: "#2F6EB4",
                                      borderRadius: "4px 4px 4px 4px",
                                      boxShadow:
                                        "0px 0px 0px 0px rgba(0,0,0,0.5)",
                                      "&:hover": {
                                        backgroundColor: "#2D68AB",
                                        boxShadow:
                                          "0px 0px 0px 0px rgba(0,0,0,0.5)",
                                      },
                                    }}
                                  >
                                    Subscribe
                                  </AuthenticationButton>

                                  <Box
                                    sx={{
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "center",
                                      verticalAlign: "middle",
                                      mt: "5px",
                                      mb: -2,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          // Standard
                                          fontSize: "11px",
                                          fontWeight: 400,
                                          lineHeight: 1.5,
                                          letterSpacing: "-0.3px",
                                          wordSpacing: "0px",
                                          // Extended
                                          color: "#7C8387",
                                          fontStyle: "italic",
                                        }}
                                      >
                                        Powered by
                                      </Typography>
                                    </Box>

                                    <Box
                                      component="img"
                                      src="/illustrations/misc/logo-paypal.png"
                                      sx={{
                                        height: "20px",
                                        ml: "2px",
                                      }}
                                    />
                                  </Box>
                                </>
                              ) : null}

                              {actualLocalizationLine.active_psp ===
                              "stripe" ? (
                                <>
                                  <AuthenticationButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    onClick={handleSubmit}
                                    loading={isSubmitting}
                                    sx={{
                                      fontSize: "25px",
                                      height: "50px",
                                      color: "white",
                                      backgroundColor: "#11243E",
                                      borderRadius: "6px 6px 6px 6px",
                                      boxShadow:
                                        "0px 0px 3px 0px rgba(0,0,0,0.5)",
                                      "&:hover": {
                                        backgroundColor: "#625BF6",
                                        boxShadow:
                                          "0px 0px 3px 0px rgba(0,0,0,0.5)",
                                      },
                                    }}
                                  >
                                    Subscribe
                                  </AuthenticationButton>

                                  <Box
                                    sx={{
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "center",
                                      verticalAlign: "middle",
                                      mt: "5px",
                                      mb: -2,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          // Standard
                                          fontSize: "11px",
                                          fontWeight: 400,
                                          lineHeight: 1.5,
                                          letterSpacing: "-0.3px",
                                          wordSpacing: "0px",
                                          // Extended
                                          color: "#7C8387",
                                          fontStyle: "italic",
                                        }}
                                      >
                                        Powered by
                                      </Typography>
                                    </Box>

                                    <Box
                                      component="img"
                                      src="/illustrations/misc/logo-stripe.png"
                                      sx={{
                                        height: "20px",
                                        ml: "2px",
                                      }}
                                    />
                                  </Box>
                                </>
                              ) : null}

                              {actualLocalizationLine.active_psp ===
                              "paddle" ? (
                                <>
                                  <AuthenticationButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    onClick={handleSubmit}
                                    loading={isSubmitting}
                                    sx={{
                                      fontSize: "25px",
                                      height: "50px",
                                      color: "white",
                                      backgroundColor: "#4294F7",
                                      borderRadius: "4px 4px 4px 4px",
                                      boxShadow:
                                        "0px 0px 0px 0px rgba(0,0,0,0.5)",
                                      "&:hover": {
                                        backgroundColor: "#316EB3",
                                        boxShadow:
                                          "0px 0px 0px 0px rgba(0,0,0,0.5)",
                                      },
                                    }}
                                  >
                                    Subscribe
                                  </AuthenticationButton>

                                  <Box
                                    sx={{
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "center",
                                      verticalAlign: "middle",
                                      mt: "5px",
                                      mb: -2,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          // Standard
                                          fontSize: "11px",
                                          fontWeight: 400,
                                          lineHeight: 1.5,
                                          letterSpacing: "-0.3px",
                                          wordSpacing: "0px",
                                          // Extended
                                          color: "#7C8387",
                                          fontStyle: "italic",
                                        }}
                                      >
                                        Powered by
                                      </Typography>
                                    </Box>

                                    <Box
                                      component="img"
                                      src="/illustrations/misc/logo-paddle.png"
                                      sx={{
                                        height: "20px",
                                        ml: "2px",
                                      }}
                                    />
                                  </Box>
                                </>
                              ) : null}
                            </>
                          )}
                        </>
                      ) : null}
                    </Box>
                  </Stack>
                </Grid>
              </FormikProvider>
            </Grid>
          }
        />
      );
    }

    // ######################################################
    // Error step
    // ######################################################
    if (
      stateStorage.distributionTickDataCurrentStep ===
      stateStorage.distributionTickDataErrorStep
    ) {
      let errorTitle = "";
      let errorSubheader = "";
      let errorMainOutlet = "";

      if (
        stateStorage.distributionTickDataErrorStepCode ===
        "payment_linking_error"
      ) {
        errorTitle = "Billing & Payment Error!";
        errorSubheader = `Unfortunately, we could not complete the payment due to an unknown error. Please get in touch with our support at ${process.env.REACT_APP_EMAIL_SUPPORT} so we can look into the issue. When reaching out, please provide the subscription ID for expedited support.`;
        errorMainOutlet = (
          <>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SubscriptionIdNote />
            </Grid>

            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 3,
              }}
            >
              <Grid item xs={12}>
                <Stack sx={{ width: "100%" }}>
                  <Box>
                    <B1Small
                      fullWidth
                      variant="contained"
                      onClick={() => {
                        setStateStorage((previousState) => ({
                          ...previousState,
                          authState: "login",
                          distributionTickDataCurrentStep: 0,
                        }));

                        let newLocalSettingStorage = JSON.parse(
                          sessionStorage.getItem("myLocalSettingStorage")
                        );
                        if (newLocalSettingStorage === null) {
                          newLocalSettingStorage = stateStorageInit;
                        }

                        newLocalSettingStorage.authState = "login";
                        newLocalSettingStorage.distributionTickDataCurrentStep = 0;

                        sessionStorage.setItem(
                          "myLocalSettingStorage",
                          JSON.stringify(newLocalSettingStorage)
                        );
                      }}
                    >
                      Back
                    </B1Small>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </>
        );
      }

      if (
        stateStorage.distributionTickDataErrorStepCode ===
        "payment_service_error"
      ) {
        errorTitle = "Payment Service Error!";
        errorSubheader =
          "Unfortunately, we could not complete the payment due to an unknown error. Please refresh this page and try again.";
        errorMainOutlet = (
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 3,
            }}
          >
            <Grid item xs={12}>
              <Stack sx={{ width: "100%" }}>
                <Box>
                  <B1Small
                    fullWidth
                    variant="contained"
                    onClick={() => {
                      setStateStorage(stateStorageInit);

                      sessionStorage.setItem(
                        "myLocalSettingStorage",
                        JSON.stringify(stateStorageInit)
                      );

                      window.location.reload(true);
                    }}
                  >
                    Reload page and try again
                  </B1Small>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        );
      }

      SubscriptionPaymentOptionsOutlet = (
        <CardOutlet
          variant="outlet"
          backgroundProp="#F2F2F2"
          title={errorTitle}
          subheader={errorSubheader}
          mainOutlet={errorMainOutlet}
        />
      );
    }
  }

  let SubscriptionPaymentSelectedOutlet = (
    <MissingContent message="Billing & Payment" />
  );
  if (loadingProp) {
    SubscriptionPaymentSelectedOutlet = (
      <LoadingAnimation title="Billing & Payment" />
    );
  } else if (errorProp) {
    SubscriptionPaymentSelectedOutlet = (
      <MissingContent message="Billing & Payment" />
    );
  } else if (content && stateStorage.distributionTickDataCurrentStep > 2) {
    SubscriptionPaymentSelectedOutlet = (
      <CardOutlet
        variant="outlet"
        backgroundProp="#F2F2F2"
        title="Billing & Payment"
        subheader="The subscription has been successfully started with the billing address shown below. We have emailed you details of your order."
        mainOutlet={
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SubscriptionIdNote />
          </Grid>
        }
      />
    );
  }

  return stateStorage.distributionTickDataCurrentStep === 2
    ? SubscriptionPaymentOptionsOutlet
    : SubscriptionPaymentSelectedOutlet;
}
