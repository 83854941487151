// react and js
import { React, useContext } from "react";

// mui
import { Box, Grid, Stack, Typography } from "@mui/material";

// components
import CardOutlet from "../../../components/CardOutlet";

// hooks
import appContext from "../../../hooks/appContext";

// layouts

// pages

// theme
import { B1Small } from "../../../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function GettingStarted() {
  // eslint-disable-next-line
  const { stateStorage } = useContext(appContext);

  let promotionApprovalTime = "";
  if (
    Number(stateStorage.promotionPromotionPartner.promotion_approval_time) /
      60 <=
    60
  ) {
    promotionApprovalTime = `${Math.round(
      Number(stateStorage.promotionPromotionPartner.promotion_approval_time) /
        60
    )} minutes`;
  } else if (
    Number(stateStorage.promotionPromotionPartner.promotion_approval_time) /
      60 <=
    1440
  ) {
    promotionApprovalTime = `${Math.round(
      Number(stateStorage.promotionPromotionPartner.promotion_approval_time) /
        3600
    )} hours`;
  } else {
    promotionApprovalTime = `${Math.round(
      Number(stateStorage.promotionPromotionPartner.promotion_approval_time) /
        86400
    )} days`;
  }

  return (
    <CardOutlet
      variant="outlet"
      backgroundProp="#F2F2F2"
      title="Getting Started"
      subheader={
        <>
          We greatly appreciate your decision to open a trading account with one of our partners. We will verify the provided trading account as fast as we can. Please note that this process may take up to{' '}
          <Typography variant="contentMiddleBold">
            {promotionApprovalTime}
          </Typography>
          . You will receive a confirmation email once your trading account has been approved.
          <br />
          <br />
          While you wait, you can utilize the links below to familiarize yourself with our applications.
        </>
      }
      mainOutlet={
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 3,
          }}
        >
          <Grid item xs={12}>
            <Stack sx={{ width: "100%" }}>
              <Box
              >
                <B1Small
                  sx={{ mb: 2 }}
                  fullWidth
                  variant="contained"
                  href={`${process.env.REACT_APP_URL_FRONTEND_DOCUMENTATION}/category/getting-started`}
                  target="_blank"
                >
                  Getting started
                </B1Small>
              </Box>

              <Box
              >
                <B1Small
                  sx={{ mb: 2 }}
                  fullWidth
                  variant="contained"
                  href={`${process.env.REACT_APP_URL_FRONTEND_DOCUMENTATION}/category/user-manuals`}
                  target="_blank"
                >
                  User manuals
                </B1Small>
              </Box>

              <Box
              >
                <B1Small
                  sx={{ mb: 2 }}
                  fullWidth
                  variant="contained"
                  href={`${process.env.REACT_APP_URL_FRONTEND_DOCUMENTATION}/category/tutorials-and-examples`}
                  target="_blank"
                >
                  Tutorials
                </B1Small>
              </Box>

              <Box
              >
                <B1Small
                  sx={{ mb: 2 }}
                  fullWidth
                  variant="contained"
                  href={`${process.env.REACT_APP_URL_FRONTEND_DASHBOARD}/promotion`}
                  target="_blank"
                >
                  Manage promotion
                </B1Small>
              </Box>

              <Box
              >
                <B1Small
                  fullWidth
                  variant="contained"
                  href={`${process.env.REACT_APP_URL_FRONTEND_DASHBOARD}/downloads`}
                  target="_blank"
                >
                  Download portal
                </B1Small>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      }
    />
  );
}
