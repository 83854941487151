// react and js
import React from "react";
import PropTypes from "prop-types";

// mui
import { Grid, Stack, Box } from "@mui/material";

// components
import CardOutlet from "./CardOutlet";

// hooks

// layouts

// pages

// theme

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
function MissingContent({ message }) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  MissingContent.propTypes = {
    message: PropTypes.string.isRequired,
  };

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Box>
        <Stack spacing={2}>
          <Box
            sx={{
              boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
            }}
          >
            <CardOutlet
              variant="outlet"
              backgroundProp="#F2F2F2"
              title={`${message} Error ...`}
              error={Boolean(true)}
              returnMessage={`A connection to the app server could not be established! `}
            />
          </Box>
        </Stack>
      </Box>
    </Grid>
  );
}

export default MissingContent;
