// react and js
import { React } from "react";
import { Outlet } from "react-router-dom";

// mui
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Box, Stack } from "@mui/material";

// components

// hooks
import defines from "../hooks/defines";

// layouts
import PublicHeader from "./PublicHeader";
import PublicFooter from "./PublicFooter";

// pages

// theme

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Public() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <>
      <Box
        component="img"
        src="/illustrations/background/checkout-public-background.jpg"
        sx={{
          zIndex: -1000,
          position: "absolute",
          top: isDesktop
            ? defines.headerHeightDesktop
            : defines.headerHeightMobile,
          left: 0,
          width: "100%",
          height: isDesktop
            ? `calc(100vh - ${defines.headerHeightDesktop}px)`
            : `calc(100vh - ${defines.headerHeightMobile}px)`,
          objectFit: "cover",
          overflowY: "scroll",
        }}
      />

      <Box
        id="auto-scroll-container"
        style={{
          marginTop: isDesktop
            ? defines.headerHeightDesktop
            : defines.headerHeightMobile,
          maxHeight: isDesktop
            ? `calc(100vh - ${defines.headerHeightDesktop}px)`
            : `calc(100vh - ${defines.headerHeightMobile}px)`,
          overflow: "auto",
        }}
      >
        <PublicHeader />
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
          sx={{
            minHeight: isDesktop
              ? `calc(100vh - ${defines.headerHeightDesktop}px)`
              : `calc(100vh - ${defines.headerHeightMobile}px)`,
          }}
        >
          <Outlet />
          <PublicFooter />
        </Stack>
      </Box>
    </>
  );
}
