// react and js
import PropTypes from "prop-types";
import { React } from "react";

// mui
import { CssBaseline } from "@mui/material";
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material/styles";

// components

// hooks

// layouts

// pages

// theme
import typographyStruc from "./typography";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function ThemeProvider({ children }) {
  ThemeProvider.propTypes = {
    children: PropTypes.node,
  };

  const theme = createTheme({
    typography: typographyStruc,
    shape: { borderRadius: 0 },
    palette: {
      primary: {
        main: "#000000", // Important for autocomplete preselct
      },
    },
  });

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
