// react and js

// mui

// components

// hooks

// layouts

// pages

// theme

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
const typography = {
  // Font
  fontFamily: "Open Sans", // https://gwfh.mranftl.com/fonts/open-sans?subsets=latin

  // Custom - Headings
  topbar: {
    // Standard
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: 1.0,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    transition: "color 0.3s",
    color: "#474A4C",
    "&:hover": {
      color: "#FF9600",
    },
  },
  h1: {
    // Standard
    fontSize: "60px",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    color: "#474A4C",
  },
  h2: {
    // Standard
    fontSize: "27px",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    color: "#474A4C",
  },
  h3: {
    // Standard
    fontSize: "23px",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    color: "#474A4C",
  },
  h4: {
    // Standard
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    color: "#474A4C",
  },
  h5: {
    // Standard
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    color: "#474A4C",
    textTransform: "uppercase",
  },
  h6: {
    // Standard
    fontSize: "13px",
    fontWeight: 600,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    color: "#474A4C",
    textTransform: "uppercase",
  },

  // Custom - Content
  contentHuge: {
    // Standard
    fontSize: "25px",
    fontWeight: 300,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    color: "#474A4C",
  },
  contentHugeBold: {
    // Standard
    fontSize: "25px",
    fontWeight: 600,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    color: "#474A4C",
  },
  contentHugeLink: {
    // Standard
    fontSize: "25px",
    fontWeight: 700,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    transition: "color 0.3s",
    color: "inherit",
    "&:hover": {
      color: "#FF9600",
    },
  },
  contentBig: {
    // Standard
    fontSize: "20px",
    fontWeight: 300,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    color: "#474A4C",
  },
  contentBigBold: {
    // Standard
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    color: "#474A4C",
  },
  contentBigLink: {
    // Standard
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    transition: "color 0.3s",
    color: "inherit",
    "&:hover": {
      color: "#FF9600",
    },
  },
  contentMiddle: {
    // Standard
    fontSize: "17px",
    fontWeight: 300,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    color: "#474A4C",
  },
  contentMiddleBold: {
    // Standard
    fontSize: "17px",
    fontWeight: 600,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    color: "#474A4C",
  },
  contentMiddleLink: {
    // Standard
    fontSize: "17px",
    fontWeight: 700,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    transition: "color 0.3s",
    color: "inherit",
    "&:hover": {
      color: "#FF9600",
    },
  },
  contentSmall: {
    // Standard
    fontSize: "15px",
    fontWeight: 300,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    color: "#474A4C",
  },
  contentSmallBold: {
    // Standard
    fontSize: "15px",
    fontWeight: 600,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    color: "#474A4C",
  },
  contentSmallLink: {
    // Standard
    fontSize: "15px",
    fontWeight: 700,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    transition: "color 0.3s",
    color: "inherit",
    "&:hover": {
      color: "#FF9600",
    },
  },
  contentTiny: {
    // Standard
    fontSize: "13px",
    fontWeight: 300,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    color: "#474A4C",
  },
  contentTinyBold: {
    // Standard
    fontSize: "13px",
    fontWeight: 600,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    color: "#474A4C",
  },
  contentTinyLink: {
    // Standard
    fontSize: "13px",
    fontWeight: 700,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    transition: "color 0.3s",
    color: "inherit",
    "&:hover": {
      color: "#FF9600",
    },
  },

  // Custom - B1
  b1Huge: {
    // Standard
    fontSize: "42px",
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    color: "#000000",
  },
  b1Big: {
    // Standard
    fontSize: "31px",
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    color: "#000000",
  },
  b1Middle: {
    // Standard
    fontSize: "24px",
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    color: "#000000",
  },
  b1Small: {
    // Standard
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    color: "#000000",
  },
  b1Tiny: {
    // Standard
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    color: "#000000",
  },

  // Custom - B2
  b2Huge: {
    // Standard
    fontSize: "42px",
    fontWeight: 300,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    color: "#FFFFFF",
  },
  b2Big: {
    // Standard
    fontSize: "31px",
    fontWeight: 300,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    color: "#FFFFFF",
  },
  b2Middle: {
    // Standard
    fontSize: "24px",
    fontWeight: 300,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    color: "#FFFFFF",
  },
  b2Small: {
    // Standard
    fontSize: "16px",
    fontWeight: 300,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    color: "#FFFFFF",
  },
  b2Tiny: {
    // Standard
    fontSize: "13px",
    fontWeight: 300,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    color: "#FFFFFF",
  },

  // Custom - B3
  b3Huge: {
    // Standard
    fontSize: "42px",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    color: "#6D6E70",
  },
  b3Big: {
    // Standard
    fontSize: "31px",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    color: "#6D6E70",
  },
  b3Middle: {
    // Standard
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    color: "#6D6E70",
  },
  b3Small: {
    // Standard
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    color: "#6D6E70",
  },
  b3Tiny: {
    // Standard
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    color: "#6D6E70",
  },

  // Custom - Imprint
  imprint: {
    // Standard
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: "23px",
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    color: "#6D6E70",
  },

  // Custom - Form
  formLabel: {
    // Standard
    fontSize: "15px",
    fontWeight: 500,
    lineHeight: 2,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    color: "#6D6E70",
  },
  formFieldElement: {
    // Standard
    fontSize: "15px",
    fontWeight: 500,
    lineHeight: 1,
    letterSpacing: "-0.3px",
    wordSpacing: "0px",
    // Extended
    color: "#6D6E70",
  },

  // Custom - Headline
  headline: {
    // Standard
    fontWeight: 400,
    lineHeight: 18 / 18,
    fontSize: "18px",
    // Extended
    letterSpacing: 0.5,
  },

  // Custom - sidebarMobile
  sidebarMobile: {
    // Standard
    fontWeight: 400,
    lineHeight: 1,
    fontSize: "18px",
    // Extended
    textTransform: "capitalize",
  },

  // Custom - sidebarDesktop
  sidebarDesktop: {
    // Standard
    fontWeight: 400,
    lineHeight: 1,
    fontSize: "14px",
    // Extended
    textTransform: "capitalize",
  },

  // Custom - Footer
  footerPrivate: {
    // Standard
    fontWeight: 300,
    lineHeight: "23px",
    fontSize: "12px",
    // Extended
    color: "#474A4C",
  },
  // Custom - Footer
  footerPrivateLink: {
    // Standard
    fontWeight: 300,
    lineHeight: "23px",
    fontSize: "12px",
    // Extended
    transition: "color 0.3s",
    color: "inherit",
    "&:hover": {
      color: "#FF9600",
    },
  },

  // Custom - Footer
  footerPublic: {
    // Standard
    fontWeight: 300,
    lineHeight: "23px",
    fontSize: "12px",
    // Extended
    color: "#F2F2F2",
  },
  // Custom - Footer
  footerPublicLink: {
    // Standard
    fontWeight: 300,
    lineHeight: "23px",
    fontSize: "12px",
    // Extended
    transition: "color 0.3s",
    color: "inherit",
    "&:hover": {
      color: "#FF9600",
    },
  },
};

export default typography;
