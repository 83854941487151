// react and js
import PropTypes from "prop-types";
import React from "react";

// mui
import { Grid, Stack, Box, LinearProgress } from "@mui/material";

// components
import CardOutlet from "./CardOutlet";

// hooks

// layouts

// pages

// theme

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
function LoadingAnimation({ title }) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  LoadingAnimation.propTypes = {
    title: PropTypes.any.isRequired,
  };

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Box>
        <Stack spacing={2}>
          <Box
            sx={{
              boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
            }}
          >
            <CardOutlet
              variant="outlet"
              backgroundProp="#F2F2F2"
              title={`Loading ${title} ... `}
              mainOutlet={
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <LinearProgress color="inherit" />
                  </Stack>
                </Box>
              }
            />
          </Box>
        </Stack>
      </Box>
    </Grid>
  );
}

export default LoadingAnimation;
