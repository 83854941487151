// react and js
import PropTypes from "prop-types";
import { React, useContext } from "react";

// mui
import { Box, Typography, Link, Stack, Grid } from "@mui/material";

// components
import CardOutlet from "../../../components/CardOutlet";
import LoadingAnimation from "../../../components/LoadingAnimation";
import MissingContent from "../../../components/MissingContent";
//
import {
  Login,
  CreateAccount,
  ApprovalAwaiting,
  PasswordForgot,
} from "../../../components/Authentication";

// hooks
import appContext from "../../../hooks/appContext";
import { stateStorageInit } from "../../../hooks/stateStorageInit";

// layouts

// pages

// theme
import { B1Small } from "../../../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function UsedAccountNote() {
  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);

  return (
    <Grid
      item
      xs={12}
      sx={{
        pt: 1,
        pb: 2,
        px: 2,
        mt: 2,
        mb: 0,
        background: "white",
        borderRadius: "0px",
        boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5);",
      }}
    >
      <Stack sx={{ width: "100%" }}>
        <Box
          sx={{
            p: "5px 0px 0px 0px",
            display: "flex",
            borderBottom: "1px solid #C8C8C8",
            borderColor: "#C8C8C8",
          }}
        >
          <Box
            sx={{
              width: "30%",
              pr: 1,
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-end",
            }}
          >
            <Typography variant="contentBig" align="left">
              User name
            </Typography>
          </Box>

          <Box
            sx={{
              width: "70%",
              pl: 1,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <Typography
              variant="contentBig"
              align="right"
              sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
            >
              {stateStorage.userInformation.account_settings.config_data.config_data_client.email
                
                }
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            p: "5px 0px 0px 0px",
            display: "flex",
            borderBottom: "1px solid #C8C8C8",
            borderColor: "#C8C8C8",
          }}
        >
          <Box
            sx={{
              width: "40%",
              pr: 1,
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-end",
            }}
          >
            <Typography variant="contentBig" align="left">
              User ID (UID)
            </Typography>
          </Box>

          <Box
            sx={{
              width: "60%",
              pl: 1,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <Typography
              variant="contentBig"
              align="right"
              sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
            >
              {stateStorage.userInformation.account_settings.config_data.config_data_client.dashboard_id
                
                }
            </Typography>
          </Box>
        </Box>
      </Stack>
    </Grid>
  );
}

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function ClientAccount({
  content,
  loadingProp,
  errorProp,
  RecaptchaOutlet,
}) {
  ClientAccount.propTypes = {
    content: PropTypes.any,
    loadingProp: PropTypes.any.isRequired,
    errorProp: PropTypes.any.isRequired,
    RecaptchaOutlet: PropTypes.any,
  };

  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);

  let ClientAccountOptionsOutlet = null;
  if (stateStorage !== null) {
    // ######################################################
    // Options step
    // ######################################################
    if (stateStorage.promotionCurrentStep !== stateStorage.promotionErrorStep) {
      if (stateStorage.authState === "login") {
        ClientAccountOptionsOutlet = <Login caller="promotion" RecaptchaOutlet={RecaptchaOutlet} />;
      } else if (stateStorage.authState === "create") {
        ClientAccountOptionsOutlet = (
          <CreateAccount
            modeContent="create_promotion"
            caller="promotion"
            RecaptchaOutlet={RecaptchaOutlet}
          />
        );
      } else if (stateStorage.authState === "approval_awaiting") {
        ClientAccountOptionsOutlet = <ApprovalAwaiting caller="promotion" />;
      } else if (stateStorage.authState === "password_forgot") {
        ClientAccountOptionsOutlet = (
          <PasswordForgot
            caller="promotion"
            RecaptchaOutlet={RecaptchaOutlet}
          />
        );
      }
    }

    // ######################################################
    // Error step
    // ######################################################
    if (stateStorage.promotionCurrentStep === stateStorage.promotionErrorStep) {
      let errorTitle = "";
      let errorSubheader = "";
      let errorMainOutlet = "";

      if (stateStorage.promotionErrorStepCode === "promotion_period_over") {
        errorTitle = "Promotion Period Over!";
        errorSubheader =
          "This message appears after starting a regular subscription or when the previous promotion subscription expired. Please subscribe to one of the regular plans to continue using the applications.";
        errorMainOutlet = (
          <>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <UsedAccountNote />
            </Grid>

            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 3,
              }}
            >
              <Grid item xs={12}>
                <Stack sx={{ width: "100%" }}>
                  <Box
                  >
                    <B1Small
                      fullWidth
                      variant="contained"
                      sx={{ mb: 2 }}
                      onClick={() => {
                        setStateStorage((previousState) => ({
                          ...previousState,
                          authState: "login",
                          promotionCurrentStep: 0,
                        }));

                        let newLocalSettingStorage = JSON.parse(
                          sessionStorage.getItem("myLocalSettingStorage")
                        );
                        if (newLocalSettingStorage === null) {
                          newLocalSettingStorage = stateStorageInit;
                        }

                        newLocalSettingStorage.authState = "login";
                        newLocalSettingStorage.promotionCurrentStep = 0;

                        sessionStorage.setItem(
                          "myLocalSettingStorage",
                          JSON.stringify(newLocalSettingStorage)
                        );
                      }}
                    >
                      Back
                    </B1Small>
                  </Box>

                  <Box
                  >
                    <B1Small
                      fullWidth
                      variant="contained"
                      href={`${process.env.REACT_APP_URL_FRONTEND_CHECKOUT}/distribution`}
                      target="_blank"
                    >
                      Start subscription
                    </B1Small>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </>
        );
      } else if (
        stateStorage.promotionErrorStepCode === "promoter_already_in_use"
      ) {
        errorTitle = "Promotion Offer With Selected Partner Already Used!";
        errorSubheader =
          "A promotion offer can be used only once per partner. If you wish to add more trading accounts to your offer, please manage the promotion with your dashboard. If your promotion period has expired, please consider starting a regular subscription.";
        errorMainOutlet = (
          <>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <UsedAccountNote />
            </Grid>

            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 3,
              }}
            >
              <Grid item xs={12}>
                <Stack sx={{ width: "100%" }}>
                  <Box
                  >
                    <B1Small
                      fullWidth
                      variant="contained"
                      sx={{ mb: 2 }}
                      onClick={() => {
                        setStateStorage((previousState) => ({
                          ...previousState,
                          authState: "login",
                          promotionCurrentStep: 0,
                        }));

                        let newLocalSettingStorage = JSON.parse(
                          sessionStorage.getItem("myLocalSettingStorage")
                        );
                        if (newLocalSettingStorage === null) {
                          newLocalSettingStorage = stateStorageInit;
                        }

                        newLocalSettingStorage.authState = "login";
                        newLocalSettingStorage.promotionCurrentStep = 0;

                        sessionStorage.setItem(
                          "myLocalSettingStorage",
                          JSON.stringify(newLocalSettingStorage)
                        );
                      }}
                    >
                      Back
                    </B1Small>
                  </Box>

                  <Box
                  >
                    <B1Small
                      fullWidth
                      variant="contained"
                      sx={{ mb: 2 }}
                      href={`${process.env.REACT_APP_URL_FRONTEND_DASHBOARD}/promotion`}
                      target="_blank"
                    >
                      Manage promotion
                    </B1Small>
                  </Box>

                  <Box
                  >
                    <B1Small
                      fullWidth
                      variant="contained"
                      href={`${process.env.REACT_APP_URL_FRONTEND_CHECKOUT}/distribution`}
                      target="_blank"
                    >
                      Start subscription
                    </B1Small>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </>
        );
      }

      ClientAccountOptionsOutlet = (
        <CardOutlet
          variant="outlet"
          backgroundProp="#F2F2F2"
          title={errorTitle}
          subheader={errorSubheader}
          mainOutlet={errorMainOutlet}
        />
      );
    }
  }

  // ######################################################
  // Selected step
  // ######################################################
  let ClientAccountSelectedOutlet = <MissingContent message="Client Account" />;
  if (stateStorage !== null) {
    if (stateStorage.userInformation !== "") {
      if (loadingProp) {
        ClientAccountSelectedOutlet = (
          <LoadingAnimation title="Client Account" />
        );
      } else if (errorProp) {
        ClientAccountSelectedOutlet = (
          <MissingContent message="Client Account" />
        );
      } else if (content && stateStorage.promotionCurrentStep > 1) {
        ClientAccountSelectedOutlet = (
          <CardOutlet
            variant="outlet"
            backgroundProp="#F2F2F2"
            title="Client Account"
            subheader={
              <>
                You are logged in with following email address. With this email
                you can login into your
                {/* eslint-disable */}
                <Link
                  href={process.env.REACT_APP_URL_FRONTEND_DASHBOARD}
                  underline="none"
                  variant="contentMiddleLink"
                  color="inherit"
                  target="_blank"
                >
                  {` client dashboard `}
                </Link>
                {/* eslint-enable */}
                and manage your subscriptions.
              </>
            }
            mainOutlet={
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <UsedAccountNote />
              </Grid>
            }
          />
        );
      }
    }
  }

  return stateStorage.promotionCurrentStep === 1
    ? ClientAccountOptionsOutlet
    : ClientAccountSelectedOutlet;
}
