// react and js

// mui

// components

// hooks

// layouts

// pages

// theme

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
const promotionPartnersHook = [
  {
    broker_id: "6491",
    description:
      "IC Markets is a renowned Forex CFD provider, offering cutting-edge trading platforms, low latency connectivity, and superior liquidity. It caters to active day traders, scalpers, and newcomers to the forex market.",
    imageSrc: "/illustrations/partners/partners-logo-ic-markets.png",
    // features: ["Feature 1 text", "Feature 2 text", "Feature 3 text"],
    features: [],
  },
  {
    broker_id: "3697",
    description:
      "IC Trading is a renowned Forex CFD provider, offering cutting-edge trading platforms, low latency connectivity, and superior liquidity. It caters to active day traders, scalpers, and newcomers to the forex market.",
    imageSrc: "/illustrations/partners/partners-logo-ic-trading.png",
    // features: ["Feature 1 text", "Feature 2 text", "Feature 3 text"],
    features: [],
  },
  {
    broker_id: "1477",
    description:
      "Admirals, formerly known as Admiral Markets, is a global forex and CFD broker based in Tallinn, Estonia. Established in 2001, it offers trading platforms for forex and CFDs across various financial markets.",
    imageSrc:
      "/illustrations/partners/partners-logo-admiral-markets.png",
    // features: ["B Feature 1 text", "Feature 2 text", "Feature 3 text"],
    features: [],
  },
  {
    broker_id: "2067",
    description:
      "Vantage is a regulated, global, multi-asset broker offering trading on Forex, Commodities, Indices, Shares, and Cryptocurrencies. It provides a robust platform and has over a decade of market expertise.",
    imageSrc: "/illustrations/partners/partners-logo-vantage.png",
    // features: ["C Feature 1 text", "Feature 2 text", "Feature 3 text"],
    features: [],
  },
  {
    broker_id: "2207",
    description:
      "BlackBull Markets, a regulated CFD broker, offers a multicultural team with extensive banking and financial market experience. They provide a fair, transparent, and direct communication approach.",
    imageSrc:
      "/illustrations/partners/partners-logo-blackbull-markets.png",
    // features: ["D Feature 1 text", "Feature 2 text", "Feature 3 text"],
    features: [],
  },
  {
    broker_id: "3159",
    description:
      "Moneta Markets is a regulated broker, founded in 2019, offering over 950 tradable instruments. It features low trading costs, diverse account types, and extensive research content.",
    imageSrc:
      "/illustrations/partners/partners-logo-moneta-markets.png",
    // features: ["E Feature 1 text", "Feature 2 text", "Feature 3 text"],
    features: [],
  },
  {
    broker_id: "4852",
    description:
      "RoboForex is a rapidly expanding financial brokerage company, offering trading services in over 12,000 instruments across 9 asset types. The FSC regulates it and provides top-tier trading conditions.",
    imageSrc:
      "/illustrations/partners/partners-logo-robo-forex.png",
    // features: ["E Feature 1 text", "Feature 2 text", "Feature 3 text"],
    features: [],
  },
  {
    broker_id: "9469",
    description:
      "PuPrime is a leading CFD and forex broker, offering access to multiple markets including ETFs, forex, shares, and commodities. It provides top-tier execution and world-class infrastructure.",
    imageSrc:
      "/illustrations/partners/partners-logo-pu-prime.png",
    // features: ["E Feature 1 text", "Feature 2 text", "Feature 3 text"],
    features: [],
  },
];

export default promotionPartnersHook;
