// react and js

// mui

// components

// hooks

// layouts

// pages

// theme

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
const defines = {
  headerHeightMobile: 77,
  sidebarWidthMobile: 350,
  sidebarIconWidthHeightMobile: "22px",

  headerHeightDesktop: 64,
  sidebarWidthDesktop: 275,
  sidebarIconWidthHeightDesktop: "18px",
};

export default defines;
