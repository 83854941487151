// react and js
import { createContext } from "react";

// mui

// components

// hooks

// layouts

// pages

// theme

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
const appContext = createContext({
  stateStorage: false,
  setStateStorage: () => {},
});

export default appContext;
