// react and js
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { Box } from "@mui/material";
import { React } from "react";

// mui

// components

// hooks

// layouts

// pages

// theme

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Iconify({ icon, sx, ...other }) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  Iconify.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    sx: PropTypes.object,
  };

  return <Box component={Icon} icon={icon} sx={{ ...sx }} {...other} />;
}
