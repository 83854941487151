// react and js
import PropTypes from "prop-types";
import { React, useState, useContext, useEffect, useRef } from "react";

// mui
import {
  Link,
  Grid,
  FormControl,
  Box,
  Typography,
  Select,
  NativeSelect,
  Stack,
  Slide,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// components
import ApplicationLimitations from "./ApplicationLimitations";
import RequestMessage from "../../../components/RequestMessage";
import Iconify from "../../../components/Iconify";
import CardOutlet from "../../../components/CardOutlet";
import LoadingAnimation from "../../../components/LoadingAnimation";
import MissingContent from "../../../components/MissingContent";

// hooks
import appContext from "../../../hooks/appContext";
import { stateStorageInit } from "../../../hooks/stateStorageInit";

// layouts

// pages

// theme
import {
  B1Small,
  CardOutlinedInput,
  CardMenuItem,
} from "../../../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function numberWithCommas(x) {
  return parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function PlanItemFeature({ feature }) {
  PlanItemFeature.propTypes = {
    feature: PropTypes.any.isRequired,
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "start",
        justifyContent: "start",
      }}
    >
      <Iconify
        color="#61CE70"
        icon="eva:checkmark-outline"
        minWidth={25}
        minHeight={25}
        sx={{ mr: 1, mt: "1px" }}
      />
      {feature}
    </Box>
  );
}

function PlanItem({ row, content, selectable }) {
  PlanItem.propTypes = {
    row: PropTypes.any.isRequired,
    content: PropTypes.any.isRequired,
    selectable: PropTypes.any.isRequired,
  };

  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);

  const priceBeforeDot = row.plan_fixed_price_value.substring(
    0,
    row.plan_fixed_price_value.length - 3
  );
  const priceAfterDot = row.plan_fixed_price_value.substring(
    row.plan_fixed_price_value.length - 2,
    row.plan_fixed_price_value.length - 0
  );

  let paymentTenure = "";
  if (row.plan_interval_unit === "DAY") {
    paymentTenure = "Daily";
  } else if (row.plan_interval_unit === "WEEK") {
    paymentTenure = "Weekly";
  } else if (row.plan_interval_unit === "MONTH") {
    paymentTenure = "Monthly";
  } else if (row.plan_interval_unit === "YEAR") {
    paymentTenure = "Yearly";
  }

  let paymentCurrency = row.plan_fixed_price_currency_code;
  if (row.plan_fixed_price_currency_code === "EUR") {
    paymentCurrency = "€";
  }

  let tradingCurrency = content.retailer.reference_trading_currency;
  if (content.retailer.reference_trading_currency === "EUR") {
    tradingCurrency = "€";
  }

  let liveLimitsValue = row.plan_fixed_price_currency_code;
  if (row.distribution_live_limits === "server") {
    liveLimitsValue = (
      <Typography variant="contentMiddle">
        <Typography variant="contentMiddleBold">{`Remote `}</Typography>
        SL and TP with all live accounts usable
      </Typography>
    );
  } else if (row.distribution_live_limits === "local") {
    liveLimitsValue = (
      <Typography variant="contentMiddle">
        <Typography variant="contentMiddleBold">{`Local `}</Typography>
        SL and TP with all live accounts usable
      </Typography>
    );
  } else if (row.distribution_live_limits === "local_server") {
    liveLimitsValue = (
      <Typography variant="contentMiddle">
        <Typography variant="contentMiddleBold">
          {`Local and remote `}
        </Typography>
        SL and TP with all live accounts usable
      </Typography>
    );
  }

  let liveIncludedAccounts = "";
  if (row.distribution_live_included_accounts !== "-1") {
    if (row.distribution_live_included_accounts === "1") {
      liveIncludedAccounts = (
        <Typography variant="contentMiddle">
          <Typography variant="contentMiddleBold">
            {`${row.distribution_live_included_accounts} live `}
          </Typography>
          trading account included
        </Typography>
      );
    } else {
      liveIncludedAccounts = (
        <Typography variant="contentMiddle">
          <Typography variant="contentMiddleBold">
            {`${row.distribution_live_included_accounts} live `}
          </Typography>
          trading accounts included
        </Typography>
      );
    }
  } else {
    liveIncludedAccounts = (
      <Typography variant="contentMiddle">
        <Typography variant="contentMiddleBold">{`Unlimited `}</Typography>
        number of live trading accounts included
      </Typography>
    );
  }

  let liveIncludedAccountChanges = "";
  if (row.distribution_demo_included_account_changes !== "-1") {
    liveIncludedAccountChanges = (
      <Typography variant="contentMiddle">
        <Typography variant="contentMiddleBold">
          {`${row.distribution_demo_included_account_changes} `}
        </Typography>
        included live trading account changes
      </Typography>
    );
  }

  let demoLimitsValue = row.plan_fixed_price_currency_code;
  if (row.distribution_demo_limits === "server") {
    demoLimitsValue = (
      <Typography variant="contentMiddle">
        <Typography variant="contentMiddleBold">{`Remote `}</Typography>
        SL and TP with all demo accounts usable
      </Typography>
    );
  } else if (row.distribution_demo_limits === "local") {
    demoLimitsValue = (
      <Typography variant="contentMiddle">
        <Typography variant="contentMiddleBold">{`Local `}</Typography>
        SL and TP with all demo accounts usable
      </Typography>
    );
  } else if (row.distribution_demo_limits === "local_server") {
    demoLimitsValue = (
      <Typography variant="contentMiddle">
        <Typography variant="contentMiddleBold">
          {`Local and remote `}
        </Typography>
        SL and TP with all demo accounts usable
      </Typography>
    );
  }

  let demoIncludedAccounts = "";
  if (row.distribution_demo_included_accounts !== "-1") {
    if (row.distribution_demo_included_accounts === "1") {
      demoIncludedAccounts = (
        <Typography variant="contentMiddle">
          <Typography variant="contentMiddleBold">
            {`${row.distribution_demo_included_accounts} demo `}
          </Typography>
          trading account included
        </Typography>
      );
    } else {
      demoIncludedAccounts = (
        <Typography variant="contentMiddle">
          <Typography variant="contentMiddleBold">
            {`${row.distribution_demo_included_accounts} demo `}
          </Typography>
          trading accounts included
        </Typography>
      );
    }
  } else {
    demoIncludedAccounts = (
      <Typography variant="contentMiddle">
        <Typography variant="contentMiddleBold">{`Unlimited `}</Typography>
        number of demo trading accounts included
      </Typography>
    );
  }

  let demoIncludedAccountChanges = "";
  if (row.distribution_demo_included_account_changes !== "-1") {
    demoIncludedAccountChanges = (
      <Typography variant="contentMiddle">
        <Typography variant="contentMiddleBold">
          {`${row.distribution_demo_included_account_changes} `}
        </Typography>
        included demo trading account changes
      </Typography>
    );
  }

  const minApplicationEquity = (
    <Typography variant="contentMiddle">
      <Typography variant="contentMiddleBold">
        {`Min. ${numberWithCommas(
          Number(row.distribution_min_application_equity)
        )} ${tradingCurrency} equity`}
        <sup>**</sup>{" "}
      </Typography>
      per live and demo trading account
    </Typography>
  );

  const maxApplicationEquity = (
    <Typography variant="contentMiddle">
      <Typography variant="contentMiddleBold">
        {`Max. ${numberWithCommas(
          Number(row.distribution_max_application_equity)
        )} ${tradingCurrency} equity`}
        <sup>**</sup>{" "}
      </Typography>
      per live and demo trading account
    </Typography>
  );

  const minApplicationLeverage = (
    <Typography variant="contentMiddle">
      <Typography variant="contentMiddleBold">
        {`Min. ${numberWithCommas(
          Number(row.distribution_min_application_leverage)
        )} leverage `}
      </Typography>
      per live and demo trading account
    </Typography>
  );

  const maxApplicationLeverage = (
    <Typography variant="contentMiddle">
      <Typography variant="contentMiddleBold">
        {`Max. ${numberWithCommas(
          Number(row.distribution_max_application_leverage)
        )} leverage `}
      </Typography>
      per live and demo trading account
    </Typography>
  );

  return (
    <Stack>
      <Box
        sx={{
          borderRadius: "0px",
          boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5);",
        }}
      >
        <Box
          sx={{ pb: "0px", px: 2, mb: 1, backgroundColor: "#F2F2F2" }}
          display="block"
          align="center"
        >
          <Typography
            variant="h5"
            display="block"
            align="center"
            sx={{ mt: "14px" }}
          >
            <Link
              variant="h5"
              underline="none"
              color="inherit"
              target="_blank"
              href={row.home_url}
            >
              {row.product_name}
            </Link>
          </Typography>

          <Typography variant="contentHuge">{row.plan_description}</Typography>
        </Box>

        <Box sx={{ px: 2, pb: 2, backgroundColor: "#FFFFFF" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              pt: 3,
            }}
          >
            <Typography
              variant="h3"
              sx={{
                lineHeight: 1,
                display: "flex",
                alignItems: "end",
                mb: "4px",
              }}
            >
              {paymentCurrency}
            </Typography>

            <Typography variant="h1" sx={{ lineHeight: 1 }}>
              {priceBeforeDot}
            </Typography>

            <Typography variant="h3" sx={{ lineHeight: 1, mt: "7px" }}>
              {priceAfterDot}
              <sup>*</sup>{" "}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              pb: 3,
            }}
          >
            <Typography variant="contentMiddle" sx={{ lineHeight: 1 }}>
              {paymentTenure}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box>
              {row.distribution_min_application_equity !== null ? (
                <PlanItemFeature feature={minApplicationEquity} />
              ) : null}
              {row.distribution_max_application_equity !== null ? (
                <PlanItemFeature feature={maxApplicationEquity} />
              ) : null}
              {row.distribution_min_application_leverage !== null ? (
                <PlanItemFeature feature={minApplicationLeverage} />
              ) : null}
              {row.distribution_max_application_leverage !== null ? (
                <PlanItemFeature feature={maxApplicationLeverage} />
              ) : null}

              {row.distribution_min_application_equity !== null ||
              row.distribution_max_application_equity !== null ||
              row.distribution_min_application_leverage !== null ||
              row.distribution_max_application_leverage !== null ? (
                <Box sx={{ mt: 2 }} />
              ) : null}

              {liveIncludedAccounts !== "" ? (
                <PlanItemFeature feature={liveIncludedAccounts} />
              ) : null}
              {liveIncludedAccountChanges !== "" ? (
                <PlanItemFeature feature={liveIncludedAccountChanges} />
              ) : null}
              {liveLimitsValue !== "" ? (
                <PlanItemFeature feature={liveLimitsValue} />
              ) : null}

              {liveIncludedAccounts !== "" ||
              liveIncludedAccountChanges !== "" ||
              liveLimitsValue !== "" ? (
                <Box sx={{ mt: 2 }} />
              ) : null}

              {demoIncludedAccounts !== "" ? (
                <PlanItemFeature feature={demoIncludedAccounts} />
              ) : null}
              {demoIncludedAccountChanges !== "" ? (
                <PlanItemFeature feature={demoIncludedAccountChanges} />
              ) : null}
              {demoLimitsValue !== "" ? (
                <PlanItemFeature feature={demoLimitsValue} />
              ) : null}

              {demoIncludedAccounts !== "" ||
              demoIncludedAccountChanges !== "" ||
              demoLimitsValue !== "" ? (
                <Box sx={{ mt: 2 }} />
              ) : null}
            </Box>
          </Box>

          {selectable ? (
            <B1Small
              sx={{ mt: 1 }}
              fullWidth
              variant="contained"
              onClick={() => {
                setStateStorage((previousState) => ({
                  ...previousState,
                  authState: "login",
                  distributionCurrentStep: 1,
                  distributionPlan: row,
                }));

                let newLocalSettingStorage = JSON.parse(
                  sessionStorage.getItem("myLocalSettingStorage")
                );
                if (newLocalSettingStorage === null) {
                  newLocalSettingStorage = stateStorageInit;
                }

                newLocalSettingStorage.authState = "login";
                newLocalSettingStorage.distributionCurrentStep = 1;
                newLocalSettingStorage.distributionPlan = row;
                sessionStorage.setItem(
                  "myLocalSettingStorage",
                  JSON.stringify(newLocalSettingStorage)
                );
              }}
            >
              Select
            </B1Small>
          ) : null}
        </Box>
      </Box>
    </Stack>
  );
}

function ListAvailablePlans({ plans, content }) {
  ListAvailablePlans.propTypes = {
    plans: PropTypes.any.isRequired,
    content: PropTypes.any.isRequired,
  };

  let isPrintable = false;

  if (plans !== undefined) {
    if (plans !== null) {
      if (Object.keys(plans).length > 0) {
        isPrintable = true;
      }
    }
  }

  let widthXs = 12;
  let widthSm = 6;
  let widthMd = 4;

  let visiblePlans = 0;
  plans.forEach((plan) => {
    if (plan.checkout_visibility === "1") {
      visiblePlans += 1;
    }
  });

  if (visiblePlans % 3 === 1) {
    widthXs = 12;
    widthSm = 12;
    widthMd = 12;
  } else if (visiblePlans % 3 === 2) {
    widthXs = 12;
    widthSm = 6;
    widthMd = 6;
  } else if (visiblePlans % 3 === 3) {
    widthXs = 12;
    widthSm = 6;
    widthMd = 4;
  }

  return isPrintable ? (
    <Grid container spacing={2}>
      {plans.map((row, index) =>
        row.checkout_visibility === "1" ? (
          <Grid key={index} item xs={widthXs} sm={widthSm} md={widthMd}>
            <PlanItem row={row} content={content} selectable={Boolean(true)} />
          </Grid>
        ) : null
      )}
    </Grid>
  ) : null;
}

function WidgetMainContentProcessed({ content }) {
  WidgetMainContentProcessed.propTypes = {
    content: PropTypes.any.isRequired,
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);
  const myLocalSettingStorage = JSON.parse(
    sessionStorage.getItem("myLocalSettingStorage")
  );
  const wrapperRef = useRef(null);

  let keys = Object.keys(content.distribution_plans);
  const applications = [];

  for (let index = 0; index < keys.length; index += 1) {
    if (content.product_visibility[keys[index]] === "1") {
      applications.push(keys[index]);
    }
  }

  let applicationApplicationInitValue = applications[0];
  if (myLocalSettingStorage !== null) {
    if (myLocalSettingStorage.distributionProduct !== null) {
      if (applications.includes(myLocalSettingStorage.distributionProduct)) {
        applicationApplicationInitValue =
          myLocalSettingStorage.distributionProduct;
      }
    }
  }

  const [applicationApplication, setApplicationApplication] = useState(
    applicationApplicationInitValue
  );

  let plans = content.distribution_plans[applicationApplication];
  if (content.product_visibility[applicationApplication] === "1") {
    plans = content.distribution_plans[applicationApplication];
  } else {
    plans = content.distribution_plans[applications[0]];
  }

  keys = Object.keys(content.application_limitations);
  const applicationVersion = keys[keys.length - 1];

  const [timeCounterSlider, setTimeCounterSlider] = useState(0);
  const [sliderStatus, setSliderStatus] = useState(false);

  useEffect(() => {
    const timerID = setTimeout(() => {
      setSliderStatus(true);
    }, 80);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeCounterSlider]);

  let showLimitationsNote = false;

  if (content !== null && content !== undefined) {
    if (
      content.application_limitations[applicationVersion][
        applicationApplication
      ].limitations.data.length > 1
    ) {
      showLimitationsNote = true;
    }
  }

  return (
    <CardOutlet
      variant="outlet"
      backgroundProp="#F2F2F2"
      title="Product & Plan"
      subheader="Please choose a product and plan that suits your requirements."
      mainOutlet={
        <>
          <Stack spacing={3} sx={{ mt: 1 }}>
            {isDesktop ? (
              <Box>
                <Typography variant="contentSmall">Application</Typography>
                <FormControl fullWidth>
                  <Select
                    value={applicationApplication}
                    onChange={(event) => {
                      setSliderStatus(false);
                      setTimeCounterSlider(timeCounterSlider + 1);

                      setApplicationApplication(event.target.value);

                      setStateStorage((previousState) => ({
                        ...previousState,
                        distributionProduct: event.target.value,
                      }));

                      let newLocalSettingStorage = JSON.parse(
                        sessionStorage.getItem("myLocalSettingStorage")
                      );
                      if (newLocalSettingStorage === null) {
                        newLocalSettingStorage = stateStorageInit;
                      }

                      newLocalSettingStorage.distributionProduct =
                        event.target.value;
                      sessionStorage.setItem(
                        "myLocalSettingStorage",
                        JSON.stringify(newLocalSettingStorage)
                      );
                    }}
                    input={<CardOutlinedInput />}
                  >
                    {applications.map((currentApplication, index) => (
                      <CardMenuItem key={index} value={currentApplication}>
                        {currentApplication}
                      </CardMenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            ) : (
              <Box>
                <Typography variant="contentSmall">Application</Typography>
                <FormControl fullWidth>
                  <NativeSelect
                    value={applicationApplication}
                    onChange={(event) => {
                      setSliderStatus(false);
                      setTimeCounterSlider(timeCounterSlider + 1);

                      setApplicationApplication(event.target.value);

                      setStateStorage((previousState) => ({
                        ...previousState,
                        distributionProduct: event.target.value,
                      }));

                      let newLocalSettingStorage = JSON.parse(
                        sessionStorage.getItem("myLocalSettingStorage")
                      );
                      if (newLocalSettingStorage === null) {
                        newLocalSettingStorage = stateStorageInit;
                      }

                      newLocalSettingStorage.distributionProduct =
                        event.target.value;
                      sessionStorage.setItem(
                        "myLocalSettingStorage",
                        JSON.stringify(newLocalSettingStorage)
                      );
                    }}
                    input={<CardOutlinedInput />}
                  >
                    {applications.map((currentApplication, index) => (
                      <option key={index} value={currentApplication}>
                        {currentApplication}
                      </option>
                    ))}
                  </NativeSelect>
                </FormControl>
              </Box>
            )}

            {showLimitationsNote &&
            content.application_limitations !== undefined ? (
              <Box>
                <RequestMessage
                  wrapperRef={wrapperRef}
                  error={Boolean(false)}
                  warning={Boolean(true)}
                  success={Boolean(false)}
                  returnMessage={
                    <Typography variant="contentMiddle">
                      For proper risk and money management some of our
                      applications come with application equity, leverage or
                      broker limitations. Click{" "}
                      <ApplicationLimitations
                        application={applicationApplication}
                        limitations={
                          content.application_limitations[applicationVersion][
                            applicationApplication
                          ].limitations
                        }
                      />{" "}
                      to see limitations for this application.
                    </Typography>
                  }
                  marginTopProp={0}
                  marginBottomProp={0}
                  fullWidth
                />
              </Box>
            ) : null}

            <Box sx={{ height: "min-content", minHeight: 250 }}>
              <Slide direction="up" in={sliderStatus}>
                <Box>
                  {sliderStatus ? (
                    <ListAvailablePlans plans={plans} content={content} />
                  ) : null}
                </Box>
              </Slide>
            </Box>
          </Stack>

          <Box
            sx={{
              mt: 1,
              mb: 0,
            }}
          >
            <Typography variant="contentTiny">
              <sup>*</sup> Price inclusive taxes.
              <br />
              <sup>**</sup> If the trading currency differs, the equivalent
              amount in your trading currency will be used instead.
            </Typography>
          </Box>
        </>
      }
    />
  );
}

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function ProductPlan({ content, loadingProp, errorProp }) {
  ProductPlan.propTypes = {
    content: PropTypes.any,
    loadingProp: PropTypes.any.isRequired,
    errorProp: PropTypes.any.isRequired,
  };

  const { stateStorage } = useContext(appContext);

  let keys = [];
  let applicationVersion = null;
  let applicationApplication = null;
  let showLimitationsNote = false;

  const wrapperRef = useRef(null);

  if (loadingProp === false && errorProp === false) {
    if (stateStorage !== null) {
      if (stateStorage.distributionPlan !== null) {
        keys = Object.keys(content.application_limitations);
        applicationVersion = keys[keys.length - 1];
        applicationApplication = stateStorage.distributionPlan.product_name;

        if (
          content.application_limitations[applicationVersion][
            applicationApplication
          ].limitations.data.length > 1
        ) {
          showLimitationsNote = true;
        }
      }
    }
  }

  let ProductPlanOptionsOutlet = <MissingContent message="Product & Plan" />;
  if (loadingProp) {
    ProductPlanOptionsOutlet = <LoadingAnimation title="Product & Plan" />;
  } else if (errorProp) {
    ProductPlanOptionsOutlet = <MissingContent message="Product & Plan" />;
  } else if (content) {
    ProductPlanOptionsOutlet = <WidgetMainContentProcessed content={content} />;
  }

  let ProductPlanSelectedOutlet = <MissingContent message="Product & Plan" />;
  if (stateStorage !== null) {
    if (loadingProp) {
      ProductPlanSelectedOutlet = <LoadingAnimation title="Product & Plan" />;
    } else if (errorProp) {
      ProductPlanSelectedOutlet = <MissingContent message="Product & Plan" />;
    } else if (content && stateStorage.distributionCurrentStep > 0) {
      ProductPlanSelectedOutlet = (
        <CardOutlet
          variant="outlet"
          backgroundProp="#F2F2F2"
          title="Product & Plan"
          subheader="The plan detailed below has been selected and will be activated once the checkout process is complete."
          mainOutlet={
            <>
              {showLimitationsNote ? (
                <Box sx={{ mt: 2 }}>
                  <RequestMessage
                    wrapperRef={wrapperRef}
                    error={Boolean(false)}
                    warning={Boolean(true)}
                    success={Boolean(false)}
                    returnMessage={
                      <Typography variant="contentMiddle">
                        For proper risk and money management some of our
                        applications come with application equity, leverage or
                        broker limitations. Click{" "}
                        <ApplicationLimitations
                          application={applicationApplication}
                          limitations={
                            content.application_limitations[applicationVersion][
                              applicationApplication
                            ].limitations
                          }
                        />{" "}
                        to view the limitations of this application
                      </Typography>
                    }
                    marginTopProp={0}
                    marginBottomProp={0}
                    fullWidth
                  />
                </Box>
              ) : null}

              {stateStorage !== null &&
              stateStorage.distributionPlan !== null ? (
                <Grid
                  container
                  spacing={0}
                  sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <PlanItem
                      row={stateStorage.distributionPlan}
                      content={content}
                      selectable={Boolean(false)}
                    />
                  </Grid>
                </Grid>
              ) : null}

              <Box
                sx={{
                  mt: 1,
                  mb: 0,
                }}
              >
                <Typography variant="contentTiny">
                  <sup>*</sup> Price inclusive taxes.
                  <br />
                  <sup>**</sup> If the trading currency differs, the equivalent
                  amount in your trading currency will be used instead.
                </Typography>
              </Box>
            </>
          }
        />
      );
    }
  }

  return stateStorage.distributionCurrentStep === 0
    ? ProductPlanOptionsOutlet
    : ProductPlanSelectedOutlet;
}
