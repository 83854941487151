// react and js
import PropTypes from "prop-types";
import { React, useContext, useEffect, useState } from "react";

// mui
import {
  Grid,
  FormControl,
  Box,
  Typography,
  Select,
  NativeSelect,
  Stack,
  Slide,
  Link,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// components
import ApplicationLimitations from "./ApplicationLimitations";
import PromotionPartnerLimitations from "./PromotionPartnerLimitations";
import Iconify from "../../../components/Iconify";
import CardOutlet from "../../../components/CardOutlet";
import LoadingAnimation from "../../../components/LoadingAnimation";
import MissingContent from "../../../components/MissingContent";

// hooks
import appContext from "../../../hooks/appContext";
import promotionPartnersHook from "../../../hooks/promotionPartnersHook";
import { stateStorageInit } from "../../../hooks/stateStorageInit";

// layouts

// pages

// theme
import {
  CardOutlinedInput,
  CardMenuItem,
  B1Small,
} from "../../../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function PromotionPartnerItem({
  promotionPartner,
  selectable,
  onContinueWithPartner,
}) {
  PromotionPartnerItem.propTypes = {
    promotionPartner: PropTypes.any.isRequired,
    selectable: PropTypes.any.isRequired,
    onContinueWithPartner: PropTypes.any,
  };

  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);

  let promotionPartnersHookIndex = 0;
  promotionPartnersHook.forEach((promotionPartnerHook, index) => {
    if (promotionPartnerHook.broker_id === promotionPartner.broker_id) {
      promotionPartnersHookIndex = index;
    }
  });

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Box
        sx={{
          borderRadius: "0px",
          boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)",
        }}
      >
        <Stack>
          <Box
            sx={{ py: 2, backgroundColor: "#F2F2F2" }}
            display="block"
            align="center"
          >
            <Typography
              variant="h3"
              display="block"
              align="center"
              sx={{ lineHeight: 1 }}
            >
              {promotionPartner.broker_name}
            </Typography>
          </Box>

          <Grid container spacing={0} sx={{ p: 3, background: "white" }}>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              xs={12}
              md={4}
              lg={4}
            >
              <a
                href={promotionPartner.link_rotator}
                rel="noreferrer"
                target="_blank"
              >
                <Box
                  component="img"
                  src={
                    promotionPartnersHook[promotionPartnersHookIndex].imageSrc
                  }
                  sx={{
                    p: 3,
                    width: "100%",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                />
              </a>
            </Grid>

            <Grid item xs={12} md={8} lg={8}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ p: 3, height: "100%" }}
              >
                <Typography
                  variant="contentMiddle"
                  display="block"
                  align="center"
                >
                  {
                    promotionPartnersHook[promotionPartnersHookIndex]
                      .description
                  }
                </Typography>

                {promotionPartnersHook[promotionPartnersHookIndex].features
                  .length !== 0 ? (
                  <Typography variant="contentMiddle">
                    <Stack spacing={1} sx={{ mt: 2 }}>
                      {promotionPartnersHook[
                        promotionPartnersHookIndex
                      ].features.map((feature, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Iconify
                            color="#61CE70"
                            icon="eva:checkmark-outline"
                            minWidth={25}
                            minHeight={25}
                            sx={{ mr: 1 }}
                          />
                          {feature}
                        </Box>
                      ))}
                    </Stack>
                  </Typography>
                ) : null}
              </Box>
            </Grid>

            <Grid item xs={12} md={12} lg={12} sx={{ mt: 0 }}>
              <B1Small
                fullWidth
                variant="contained"
                href={promotionPartner.link_rotator}
                target="_blank"
              >
                Open new live account
              </B1Small>

              {selectable === true ? (
                <B1Small
                  sx={{ mt: 2 }}
                  fullWidth
                  onClick={() => {
                    setStateStorage((previousState) => ({
                      ...previousState,
                      authState: "login",
                      promotionCurrentStep: 1,
                    }));

                    let newLocalSettingStorage = JSON.parse(
                      sessionStorage.getItem("myLocalSettingStorage")
                    );
                    if (newLocalSettingStorage === null) {
                      newLocalSettingStorage = stateStorageInit;
                    }

                    newLocalSettingStorage.authState = "login";
                    newLocalSettingStorage.promotionCurrentStep = 1;
                    sessionStorage.setItem(
                      "myLocalSettingStorage",
                      JSON.stringify(newLocalSettingStorage)
                    );
                    onContinueWithPartner();
                  }}
                  variant="contained"
                  target="_blank"
                >
                  Continue with newly opened live account
                </B1Small>
              ) : null}
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </Grid>
  );
}

function WidgetMainContentProcessed({ content }) {
  WidgetMainContentProcessed.propTypes = {
    content: PropTypes.any.isRequired,
  };

  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);
  const myLocalSettingStorage = JSON.parse(
    sessionStorage.getItem("myLocalSettingStorage")
  );

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const keys = Object.keys(content.promotion_partners);
  const promotionPartners = [];

  for (let index = 0; index < keys.length; index += 1) {
    promotionPartners.push(keys[index]);
  }

  let setSelectedPromotionPartnerInitValue = keys[0];
  if (myLocalSettingStorage !== null) {
    if (myLocalSettingStorage.promotionPromotionPartner !== null) {
      setSelectedPromotionPartnerInitValue =
        myLocalSettingStorage.promotionPromotionPartner.broker_name;
    }
  }

  const [selectedPromotionPartner, setSelectedPromotionPartner] = useState(
    setSelectedPromotionPartnerInitValue
  );

  const onContinueWithPartner = () => {
    setStateStorage((previousState) => ({
      ...previousState,
      promotionPromotionPartner:
        content.promotion_partners[selectedPromotionPartner],
    }));

    let newLocalSettingStorage = JSON.parse(
      sessionStorage.getItem("myLocalSettingStorage")
    );
    if (newLocalSettingStorage === null) {
      newLocalSettingStorage = stateStorageInit;
    }

    newLocalSettingStorage.promotionPromotionPartner =
      content.promotion_partners[selectedPromotionPartner];
    sessionStorage.setItem(
      "myLocalSettingStorage",
      JSON.stringify(newLocalSettingStorage)
    );
  };

  const [timeCounterSlider, setTimeCounterSlider] = useState(0);
  const [sliderStatus, setSliderStatus] = useState(false);

  useEffect(() => {
    const timerID = setTimeout(() => {
      setSliderStatus(true);
    }, 80);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeCounterSlider]);

  let promotionMonths = ` ${content.promotion_partners[selectedPromotionPartner].promotion_months} months `;
  if (
    content.promotion_partners[selectedPromotionPartner].promotion_months ===
    "1"
  ) {
    promotionMonths = ` ${content.promotion_partners[selectedPromotionPartner].promotion_months} month `;
  }

  return (
    <CardOutlet
      variant="outlet"
      backgroundProp="#F2F2F2"
      title="Promotion Partner"
      subheader={
        <Box sx={{ mb: 3 }}>
          We partner with some brokers to provide
          {promotionMonths} of free promotion subscriptions. This offer includes
          the permission to use the following applications:
          <br />
          <ul>
            {content.promotion_partners[selectedPromotionPartner].data.map(
              (promotionProduct, index) => (
                  <li key={index}>
                    <Link
                      href={
                        promotionProduct[7]
                      }
                      underline="none"
                      variant="contentMiddleLink"
                      color="inherit"
                      target="_blank"
                    >
                      {promotionProduct[0]}
                    </Link>
                  </li>
              )
            )}
          </ul>
          To start with a promotion offer, two steps are required from your
          side:
          <br />
          <br />
          1.
          <Link
            href={
              content.promotion_partners[selectedPromotionPartner].link_rotator
            }
            underline="none"
            variant="contentMiddleLink"
            color="inherit"
            target="_blank"
          >
            {` Open a new live trading account `}
          </Link>
          using the button below. This will forward you to the promotion
          partner. {content.promotion_partners[selectedPromotionPartner].promotion_account_open_instruction != null ? content.promotion_partners[selectedPromotionPartner].promotion_account_open_instruction : null}
          <br />
          <br />
          2. Proceed on this page and provide the details of your new live
          trading account.
          <br />
          <br />
          We cannot accept existing live or demo trading accounts. Suppose you
          already use one of the brokers below and wish to start a promotion
          subscription. In that case, we recommend opening a new trading account
          with another partner broker. Keep in mind that our partners require a
          minimum trading account equity to quality for this offer.
          <br />
          <br />
          If you prefer to start a
          <Link
            href={`${process.env.REACT_APP_URL_FRONTEND_CHECKOUT}/distribution`}
            underline="none"
            variant="contentMiddleLink"
            color="inherit"
          >
            {` regular subscription `}
          </Link>
          instead, you can start  one for a small monthly fee on the checkout
          website. <br />
          <br />
          
          Our technology partners usually require a{" "}
          <PromotionPartnerLimitations
            promotionPartner={
              content.promotion_partners[selectedPromotionPartner]
            }
          />
          . You must have the required equity on your live trading account to
          use the application! {" "}
          <ApplicationLimitations
            limitations={content.application_limitations}
          />
        </Box>
      }
      mainOutlet={
        <Stack spacing={3} sx={{ mt: 1 }}>
          <Box>
            {isDesktop ? (
              <Box>
                <Typography variant="contentSmall">
                  Promotion partner
                </Typography>
                <FormControl fullWidth>
                  <Select
                    value={selectedPromotionPartner}
                    onChange={(event) => {
                      setSliderStatus(false);
                      setTimeCounterSlider(timeCounterSlider + 1);

                      setSelectedPromotionPartner(event.target.value);

                      setStateStorage((previousState) => ({
                        ...previousState,
                        promotionPromotionPartner:
                          content.promotion_partners[event.target.value],
                      }));

                      let newLocalSettingStorage = JSON.parse(
                        sessionStorage.getItem("myLocalSettingStorage")
                      );
                      if (newLocalSettingStorage === null) {
                        newLocalSettingStorage = stateStorageInit;
                      }

                      newLocalSettingStorage.promotionPromotionPartner =
                        content.promotion_partners[event.target.value];
                      sessionStorage.setItem(
                        "myLocalSettingStorage",
                        JSON.stringify(newLocalSettingStorage)
                      );
                    }}
                    input={<CardOutlinedInput />}
                  >
                    {promotionPartners.map((promotionPartner, index) => (
                      <CardMenuItem
                        key={index}
                        value={
                          content.promotion_partners[promotionPartner]
                            .broker_name
                        }
                      >
                        {
                          content.promotion_partners[promotionPartner]
                            .broker_name
                        }
                      </CardMenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            ) : (
              <Box>
                <Typography variant="contentSmall">
                  Promotion partner
                </Typography>
                <FormControl fullWidth>
                  <NativeSelect
                    value={selectedPromotionPartner}
                    onChange={(event) => {
                      setSliderStatus(false);
                      setTimeCounterSlider(timeCounterSlider + 1);

                      setSelectedPromotionPartner(event.target.value);

                      setStateStorage((previousState) => ({
                        ...previousState,
                        promotionPromotionPartner:
                          content.promotion_partners[event.target.value],
                      }));

                      let newLocalSettingStorage = JSON.parse(
                        sessionStorage.getItem("myLocalSettingStorage")
                      );
                      if (newLocalSettingStorage === null) {
                        newLocalSettingStorage = stateStorageInit;
                      }

                      newLocalSettingStorage.promotionPromotionPartner =
                        content.promotion_partners[event.target.value];
                      sessionStorage.setItem(
                        "myLocalSettingStorage",
                        JSON.stringify(newLocalSettingStorage)
                      );
                    }}
                    input={<CardOutlinedInput />}
                  >
                    {promotionPartners.map((promotionPartner, index) => (
                      <option
                        key={index}
                        value={
                          content.promotion_partners[promotionPartner]
                            .broker_name
                        }
                      >
                        {
                          content.promotion_partners[promotionPartner]
                            .broker_name
                        }
                      </option>
                    ))}
                  </NativeSelect>
                </FormControl>
              </Box>
            )}
          </Box>

          <Box sx={{ height: "min-content", minHeight: 375 }}>
            <Slide direction="up" in={sliderStatus}>
              <Box>
                {sliderStatus ? (
                  <PromotionPartnerItem
                    promotionPartner={
                      content.promotion_partners[selectedPromotionPartner]
                    }
                    selectable={Boolean(true)}
                    onContinueWithPartner={onContinueWithPartner}
                  />
                ) : null}
              </Box>
            </Slide>
          </Box>
        </Stack>
      }
    />
  );
}

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function PromotionPartner({ content, loadingProp, errorProp }) {
  PromotionPartner.propTypes = {
    content: PropTypes.any,
    loadingProp: PropTypes.any.isRequired,
    errorProp: PropTypes.any.isRequired,
  };

  const { stateStorage } = useContext(appContext);

  let PromotionPartnerOptionsOutlet = (
    <MissingContent message="Promotion Partner" />
  );
  if (loadingProp) {
    PromotionPartnerOptionsOutlet = (
      <LoadingAnimation title="Promotion Partner" />
    );
  } else if (errorProp) {
    PromotionPartnerOptionsOutlet = (
      <MissingContent message="Promotion Partner" />
    );
  } else if (content) {
    PromotionPartnerOptionsOutlet = (
      <WidgetMainContentProcessed content={content} />
    );
  }

  let PromotionPartnerSelectedOutlet = (
    <MissingContent message="Promotion Partner" />
  );
  if (stateStorage !== null) {
    if (loadingProp) {
      PromotionPartnerSelectedOutlet = (
        <LoadingAnimation title="Promotion Partner" />
      );
    } else if (errorProp) {
      PromotionPartnerSelectedOutlet = (
        <MissingContent message="Promotion Partner" />
      );
    } else if (content && stateStorage.promotionCurrentStep > 0) {
      PromotionPartnerSelectedOutlet = (
        <CardOutlet
          variant="outlet"
          backgroundProp="#F2F2F2"
          title="Promotion Partner"
          subheader="The following promotion partner has been chosen. You can utilize the applications as soon as the promotion offer is approved."
          mainOutlet={
            stateStorage !== null &&
            stateStorage.promotionPromotionPartner !== null ? (
              <Grid
                container
                spacing={0}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
                  <PromotionPartnerItem
                    promotionPartner={stateStorage.promotionPromotionPartner}
                    selectable={Boolean(false)}
                  />
                </Grid>
              </Grid>
            ) : null
          }
        />
      );
    }
  }

  return stateStorage.promotionCurrentStep === 0
    ? PromotionPartnerOptionsOutlet
    : PromotionPartnerSelectedOutlet;
}
