// react and js
import fetch from "isomorphic-fetch";

// mui

// components

// hooks

// layouts

// pages

// theme

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
async function backendRequest(backendUrl, sendData) {
  if (!navigator.onLine) return false;

  try {
    return fetch(backendUrl, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(sendData),
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`Status: ${res.status}: ${res.statusText}`);
      }
      return res.json();
    });
  } catch {
    return false;
  }
}

export default backendRequest;
