// react and js
import { React, useContext } from "react";

// mui
import { Box, Grid, Stack } from "@mui/material";

// components
import CardOutlet from "../../../components/CardOutlet";

// hooks
import appContext from "../../../hooks/appContext";

// layouts

// pages

// theme
import { B1Small } from "../../../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function GettingStarted() {
  // eslint-disable-next-line
  const { stateStorage } = useContext(appContext);

  return (
    <CardOutlet
      variant="outlet"
      backgroundProp="#F2F2F2"
      title="Getting Started"
      subheader="Thank you for subscribing to our product. Below, you can find some useful links to help you get started smoothly."
      mainOutlet={
        stateStorage.distributionPlan !== null ? (
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 3,
            }}
          >
            <Grid item xs={12}>
              <Stack sx={{ width: "100%" }}>
                <Box
                >
                  {stateStorage.distributionPlan.getting_started_url !==
                  null ? (
                    <B1Small
                      sx={{ mb: 2 }}
                      fullWidth
                      variant="contained"
                      href={stateStorage.distributionPlan.getting_started_url}
                      target="_blank"
                    >
                      Getting started
                    </B1Small>
                  ) : null}
                </Box>

                <Box
                >
                  {stateStorage.distributionPlan.user_manual_url !== null ? (
                    <B1Small
                      sx={{ mb: 2 }}
                      fullWidth
                      variant="contained"
                      href={stateStorage.distributionPlan.user_manual_url}
                      target="_blank"
                    >
                      User manual
                    </B1Small>
                  ) : null}
                </Box>

                <Box
                >
                  {stateStorage.distributionPlan.tutorial_url !== null ? (
                    <B1Small
                      sx={{ mb: 2 }}
                      fullWidth
                      variant="contained"
                      href={stateStorage.distributionPlan.tutorial_url}
                      target="_blank"
                    >
                      Tutorials
                    </B1Small>
                  ) : null}
                </Box>

                <Box
                >
                  <B1Small
                    sx={{ mb: 2 }}
                    fullWidth
                    variant="contained"
                    href={`${process.env.REACT_APP_URL_FRONTEND_DASHBOARD}/subscriptions`}
                    target="_blank"
                  >
                    Manage subscription
                  </B1Small>
                </Box>

                <Box
                >
                  <B1Small
                    fullWidth
                    variant="contained"
                    href={`${process.env.REACT_APP_URL_FRONTEND_DASHBOARD}/downloads`}
                    target="_blank"
                  >
                    Download portal
                  </B1Small>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        ) : null
      }
    />
  );
}
