// react and js
import { React } from "react";

// mui
import { styled } from "@mui/material/styles";
import {
  Button,
  TextField,
  Pagination,
  Slider,
  Switch,
  OutlinedInput,
  MenuItem,
  LinearProgress,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

// components

// hooks

// layouts

// pages

// theme
import typography from "./typography";

// utils

// widgets

// ----------------------------------------------------------------------
// Code - B1Huge
// ----------------------------------------------------------------------
const B1Huge = styled(Button)(({ theme }) => ({
  fontSize: theme.typography.b1Huge.fontSize,
  fontWeight: theme.typography.b1Huge.fontWeight,
  lineHeight: theme.typography.b1Huge.lineHeight,
  letterSpacing: theme.typography.b1Huge.letterSpacing,
  wordSpacing: theme.typography.b1Huge.wordSpacing,
  color: theme.typography.b1Huge.color,
  //
  padding: "4px 12px",
  textTransform: "none",
  backgroundColor: "#FFC000",
  borderRadius: "0px 0px 0px 0px",
  boxShadow: "0px 0px 1px 0px rgba(0,0,0,0.5)",
  "&:hover": {
    backgroundColor: "#FF9600",
    boxShadow: "0px 0px 1px 0px rgba(0,0,0,0.5)",
  },
}));

// ----------------------------------------------------------------------
// Code - B1Big
// ----------------------------------------------------------------------
const B1Big = styled(Button)(({ theme }) => ({
  fontSize: theme.typography.b1Big.fontSize,
  fontWeight: theme.typography.b1Big.fontWeight,
  lineHeight: theme.typography.b1Big.lineHeight,
  letterSpacing: theme.typography.b1Big.letterSpacing,
  wordSpacing: theme.typography.b1Big.wordSpacing,
  color: theme.typography.b1Big.color,
  //
  padding: "4px 12px",
  textTransform: "none",
  backgroundColor: "#FFC000",
  borderRadius: "0px 0px 0px 0px",
  boxShadow: "0px 0px 1px 0px rgba(0,0,0,0.5)",
  "&:hover": {
    backgroundColor: "#FF9600",
    boxShadow: "0px 0px 1px 0px rgba(0,0,0,0.5)",
  },
}));

// ----------------------------------------------------------------------
// Code - B1Middle
// ----------------------------------------------------------------------
const B1Middle = styled(Button)(({ theme }) => ({
  fontSize: theme.typography.b1Middle.fontSize,
  fontWeight: theme.typography.b1Middle.fontWeight,
  lineHeight: theme.typography.b1Middle.lineHeight,
  letterSpacing: theme.typography.b1Middle.letterSpacing,
  wordSpacing: theme.typography.b1Middle.wordSpacing,
  color: theme.typography.b1Middle.color,
  //
  padding: "4px 12px",
  textTransform: "none",
  backgroundColor: "#FFC000",
  borderRadius: "0px 0px 0px 0px",
  boxShadow: "0px 0px 1px 0px rgba(0,0,0,0.5)",
  "&:hover": {
    backgroundColor: "#FF9600",
    boxShadow: "0px 0px 1px 0px rgba(0,0,0,0.5)",
  },
}));

// ----------------------------------------------------------------------
// Code - B1Small
// ----------------------------------------------------------------------
const B1Small = styled(Button)(({ theme }) => ({
  fontSize: theme.typography.b1Small.fontSize,
  fontWeight: theme.typography.b1Small.fontWeight,
  lineHeight: theme.typography.b1Small.lineHeight,
  letterSpacing: theme.typography.b1Small.letterSpacing,
  wordSpacing: theme.typography.b1Small.wordSpacing,
  color: theme.typography.b1Small.color,
  //
  padding: "4px 12px",
  textTransform: "none",
  backgroundColor: "#FFC000",
  borderRadius: "0px 0px 0px 0px",
  boxShadow: "0px 0px 1px 0px rgba(0,0,0,0.5)",
  "&:hover": {
    backgroundColor: "#FF9600",
    boxShadow: "0px 0px 1px 0px rgba(0,0,0,0.5)",
  },
}));

// ----------------------------------------------------------------------
// Code - B1Tiny
// ----------------------------------------------------------------------
const B1Tiny = styled(Button)(({ theme }) => ({
  fontSize: theme.typography.b1Tiny.fontSize,
  fontWeight: theme.typography.b1Tiny.fontWeight,
  lineHeight: theme.typography.b1Tiny.lineHeight,
  letterSpacing: theme.typography.b1Tiny.letterSpacing,
  wordSpacing: theme.typography.b1Tiny.wordSpacing,
  color: theme.typography.b1Tiny.color,
  //
  padding: "4px 12px",
  textTransform: "none",
  backgroundColor: "#FFC000",
  borderRadius: "0px 0px 0px 0px",
  boxShadow: "0px 0px 1px 0px rgba(0,0,0,0.5)",
  "&:hover": {
    backgroundColor: "#FF9600",
    boxShadow: "0px 0px 1px 0px rgba(0,0,0,0.5)",
  },
}));

// ----------------------------------------------------------------------
// Code - B2Huge
// ----------------------------------------------------------------------
const B2Huge = styled(Button)(({ theme }) => ({
  fontSize: theme.typography.b2Huge.fontSize,
  fontWeight: theme.typography.b2Huge.fontWeight,
  lineHeight: theme.typography.b2Huge.lineHeight,
  letterSpacing: theme.typography.b2Huge.letterSpacing,
  wordSpacing: theme.typography.b2Huge.wordSpacing,
  color: theme.typography.b2Huge.color,
  //
  padding: "4px 12px",
  textTransform: "none",
  backgroundColor: "#6D6E70",
  borderRadius: "0px 0px 0px 0px",
  boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
  "&:hover": {
    backgroundColor: "#777777",
    boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
  },
}));

// ----------------------------------------------------------------------
// Code - B2Big
// ----------------------------------------------------------------------
const B2Big = styled(Button)(({ theme }) => ({
  fontSize: theme.typography.b2Big.fontSize,
  fontWeight: theme.typography.b2Big.fontWeight,
  lineHeight: theme.typography.b2Big.lineHeight,
  letterSpacing: theme.typography.b2Big.letterSpacing,
  wordSpacing: theme.typography.b2Big.wordSpacing,
  color: theme.typography.b2Big.color,
  //
  padding: "4px 12px",
  textTransform: "none",
  backgroundColor: "#6D6E70",
  borderRadius: "0px 0px 0px 0px",
  boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
  "&:hover": {
    backgroundColor: "#777777",
    boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
  },
}));

// ----------------------------------------------------------------------
// Code - B2Middle
// ----------------------------------------------------------------------
const B2Middle = styled(Button)(({ theme }) => ({
  fontSize: theme.typography.b2Middle.fontSize,
  fontWeight: theme.typography.b2Middle.fontWeight,
  lineHeight: theme.typography.b2Middle.lineHeight,
  letterSpacing: theme.typography.b2Middle.letterSpacing,
  wordSpacing: theme.typography.b2Middle.wordSpacing,
  color: theme.typography.b2Middle.color,
  //
  padding: "4px 12px",
  textTransform: "none",
  backgroundColor: "#6D6E70",
  borderRadius: "0px 0px 0px 0px",
  boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
  "&:hover": {
    backgroundColor: "#777777",
    boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
  },
}));

// ----------------------------------------------------------------------
// Code - B2Small
// ----------------------------------------------------------------------
const B2Small = styled(Button)(({ theme }) => ({
  fontSize: theme.typography.b2Small.fontSize,
  fontWeight: theme.typography.b2Small.fontWeight,
  lineHeight: theme.typography.b2Small.lineHeight,
  letterSpacing: theme.typography.b2Small.letterSpacing,
  wordSpacing: theme.typography.b2Small.wordSpacing,
  color: theme.typography.b2Small.color,
  //
  padding: "4px 12px",
  textTransform: "none",
  backgroundColor: "#6D6E70",
  borderRadius: "0px 0px 0px 0px",
  boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
  "&:hover": {
    backgroundColor: "#777777",
    boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
  },
}));

// ----------------------------------------------------------------------
// Code - B2Tiny
// ----------------------------------------------------------------------
const B2Tiny = styled(Button)(({ theme }) => ({
  fontSize: theme.typography.b2Tiny.fontSize,
  fontWeight: theme.typography.b2Tiny.fontWeight,
  lineHeight: theme.typography.b2Tiny.lineHeight,
  letterSpacing: theme.typography.b2Tiny.letterSpacing,
  wordSpacing: theme.typography.b2Tiny.wordSpacing,
  color: theme.typography.b2Tiny.color,
  //
  padding: "4px 12px",
  textTransform: "none",
  backgroundColor: "#6D6E70",
  borderRadius: "0px 0px 0px 0px",
  boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
  "&:hover": {
    backgroundColor: "#777777",
    boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
  },
}));

// ----------------------------------------------------------------------
// Code - B3Huge
// ----------------------------------------------------------------------
const B3Huge = styled(Button)(({ theme }) => ({
  fontSize: theme.typography.b3Huge.fontSize,
  fontWeight: theme.typography.b3Huge.fontWeight,
  lineHeight: theme.typography.b3Huge.lineHeight,
  letterSpacing: theme.typography.b3Huge.letterSpacing,
  wordSpacing: theme.typography.b3Huge.wordSpacing,
  color: theme.typography.b3Huge.color,
  //
  padding: "4px 12px",
  textTransform: "none",
  backgroundColor: "#FFFFFF00",
  borderWidth: "1px 1px 1px 1px",
  borderColor: theme.typography.b3Huge.color,
  borderRadius: "0px 0px 0px 0px",
  boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
  "&:hover": {
    padding: "4px 12px",
    textTransform: "none",
    backgroundColor: "#FFFFFF00",
    borderWidth: "1px 1px 1px 1px",
    borderColor: "#FF9600",
    borderRadius: "0px 0px 0px 0px",
    boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
    color: "#FF9600",
  },
}));

// ----------------------------------------------------------------------
// Code - B3Big
// ----------------------------------------------------------------------
const B3Big = styled(Button)(({ theme }) => ({
  fontSize: theme.typography.b3Big.fontSize,
  fontWeight: theme.typography.b3Big.fontWeight,
  lineHeight: theme.typography.b3Big.lineHeight,
  letterSpacing: theme.typography.b3Big.letterSpacing,
  wordSpacing: theme.typography.b3Big.wordSpacing,
  color: theme.typography.b3Big.color,
  //
  padding: "4px 12px",
  textTransform: "none",
  backgroundColor: "#FFFFFF00",
  borderWidth: "1px 1px 1px 1px",
  borderColor: theme.typography.b3Big.color,
  borderRadius: "0px 0px 0px 0px",
  boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
  "&:hover": {
    padding: "4px 12px",
    textTransform: "none",
    backgroundColor: "#FFFFFF00",
    borderWidth: "1px 1px 1px 1px",
    borderColor: "#FF9600",
    borderRadius: "0px 0px 0px 0px",
    boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
    color: "#FF9600",
  },
}));

// ----------------------------------------------------------------------
// Code - B3Middle
// ----------------------------------------------------------------------
const B3Middle = styled(Button)(({ theme }) => ({
  fontSize: theme.typography.b3Middle.fontSize,
  fontWeight: theme.typography.b3Middle.fontWeight,
  lineHeight: theme.typography.b3Middle.lineHeight,
  letterSpacing: theme.typography.b3Middle.letterSpacing,
  wordSpacing: theme.typography.b3Middle.wordSpacing,
  color: theme.typography.b3Middle.color,
  //
  padding: "4px 12px",
  textTransform: "none",
  backgroundColor: "#FFFFFF00",
  borderWidth: "1px 1px 1px 1px",
  borderColor: theme.typography.b3Middle.color,
  borderRadius: "0px 0px 0px 0px",
  boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
  "&:hover": {
    padding: "4px 12px",
    textTransform: "none",
    backgroundColor: "#FFFFFF00",
    borderWidth: "1px 1px 1px 1px",
    borderColor: "#FF9600",
    borderRadius: "0px 0px 0px 0px",
    boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
    color: "#FF9600",
  },
}));

// ----------------------------------------------------------------------
// Code - B3Small
// ----------------------------------------------------------------------
const B3Small = styled(Button)(({ theme }) => ({
  fontSize: theme.typography.b3Small.fontSize,
  fontWeight: theme.typography.b3Small.fontWeight,
  lineHeight: theme.typography.b3Small.lineHeight,
  letterSpacing: theme.typography.b3Small.letterSpacing,
  wordSpacing: theme.typography.b3Small.wordSpacing,
  color: theme.typography.b3Small.color,
  //
  padding: "4px 12px",
  textTransform: "none",
  backgroundColor: "#FFFFFF00",
  borderWidth: "1px 1px 1px 1px",
  borderColor: theme.typography.b3Small.color,
  borderRadius: "0px 0px 0px 0px",
  boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
  "&:hover": {
    padding: "4px 12px",
    textTransform: "none",
    backgroundColor: "#FFFFFF00",
    borderWidth: "1px 1px 1px 1px",
    borderColor: "#FF9600",
    borderRadius: "0px 0px 0px 0px",
    boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
    color: "#FF9600",
  },
}));

// ----------------------------------------------------------------------
// Code - B3Tiny
// ----------------------------------------------------------------------
const B3Tiny = styled(Button)(({ theme }) => ({
  fontSize: theme.typography.b3Tiny.fontSize,
  fontWeight: theme.typography.b3Tiny.fontWeight,
  lineHeight: theme.typography.b3Tiny.lineHeight,
  letterSpacing: theme.typography.b3Tiny.letterSpacing,
  wordSpacing: theme.typography.b3Tiny.wordSpacing,
  color: theme.typography.b3Tiny.color,
  //
  padding: "4px 12px",
  textTransform: "none",
  backgroundColor: "#FFFFFF00",
  borderWidth: "1px 1px 1px 1px",
  borderColor: theme.typography.b3Tiny.color,
  borderRadius: "0px 0px 0px 0px",
  boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
  "&:hover": {
    padding: "4px 12px",
    textTransform: "none",
    backgroundColor: "#FFFFFF00",
    borderWidth: "1px 1px 1px 1px",
    borderColor: "#FF9600",
    borderRadius: "0px 0px 0px 0px",
    boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
    color: "#FF9600",
  },
}));

// ----------------------------------------------------------------------
// Code - AuthenticationButton
// ----------------------------------------------------------------------
const AuthenticationButton = styled(LoadingButton)(({ theme }) => ({
  fontSize: theme.typography.b1Small.fontSize,
  fontWeight: theme.typography.b1Small.fontWeight,
  lineHeight: theme.typography.b1Small.lineHeight,
  letterSpacing: theme.typography.b1Small.letterSpacing,
  wordSpacing: theme.typography.b1Small.wordSpacing,
  color: theme.typography.b1Small.color,
  //
  padding: "4px 12px",
  textTransform: "none",
  backgroundColor: "#FFC000",
  borderRadius: "0px 0px 0px 0px",
  boxShadow: "0px 0px 1px 0px rgba(0,0,0,0.5)",
  "&:hover": {
    backgroundColor: "#FF9600",
    boxShadow: "0px 0px 1px 0px rgba(0,0,0,0.5)",
  },
}));

// ----------------------------------------------------------------------
// Code - AuthenticationTextField
// ----------------------------------------------------------------------
const AuthenticationTextField = styled((props) => <TextField {...props} />)(
  () => ({
    "& .MuiOutlinedInput-root": {
      border: "2px solid",
      borderColor: "white",
      backgroundColor: "white",
      boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.5)",
      transition: "border-color 0.3s",
      //

      fontSize: typography.contentSmall.fontSize,
      fontWeight: typography.contentSmall.fontWeight,
      lineHeight: typography.contentSmall.lineHeight,
      letterSpacing: typography.contentSmall.letterSpacing,
      wordSpacing: typography.contentSmall.wordSpacing,
      color: typography.contentSmall.color,
      //
      "&:hover": {
        backgroundColor: "white",
      },
      "&.Mui-focused": {
        borderColor: "#FF9600",
        backgroundColor: "white",
      },
      "&.Mui-error": {
        borderColor: "red",
        backgroundColor: "white",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },

    "& .MuiButtonBase-root": {
      borderRadius: "0px",
    },
  })
);

// ----------------------------------------------------------------------
// Code - AuthenticationAlertWarning
// ----------------------------------------------------------------------
const AuthenticationAlertWarning = styled(Alert)(({ theme }) => ({
  fontSize: theme.typography.contentMiddle.fontSize,
  fontWeight: theme.typography.contentMiddle.fontWeight,
  lineHeight: 1.2,
  letterSpacing: theme.typography.contentMiddle.letterSpacing,
  wordSpacing: theme.typography.contentMiddle.wordSpacing,
  color: theme.typography.contentMiddle.color,
  //
  background: "#ffe79a",
}));

// ----------------------------------------------------------------------
// Code - AuthenticationAlertError
// ----------------------------------------------------------------------
const AuthenticationAlertError = styled(Alert)(({ theme }) => ({
  fontSize: theme.typography.contentMiddle.fontSize,
  fontWeight: theme.typography.contentMiddle.fontWeight,
  lineHeight: 1.2,
  letterSpacing: theme.typography.contentMiddle.letterSpacing,
  wordSpacing: theme.typography.contentMiddle.wordSpacing,
  color: theme.typography.contentMiddle.color,
  //
  background: "#ffd3b6",
}));

// ----------------------------------------------------------------------
// Code - AuthenticationAlertSuccess
// ----------------------------------------------------------------------
const AuthenticationAlertSuccess = styled(Alert)(({ theme }) => ({
  fontSize: theme.typography.contentMiddle.fontSize,
  fontWeight: theme.typography.contentMiddle.fontWeight,
  lineHeight: 1.2,
  letterSpacing: theme.typography.contentMiddle.letterSpacing,
  wordSpacing: theme.typography.contentMiddle.wordSpacing,
  color: theme.typography.contentMiddle.color,
  //
  background: "#a8e6cf",
}));

// ----------------------------------------------------------------------
// Code - FetchTablePagination
// ----------------------------------------------------------------------
const FetchTablePagination = styled(Pagination)(({ theme }) => ({
  "& .MuiButtonBase-root": {
    fontSize: theme.typography.b3Tiny.fontSize,
    fontWeight: theme.typography.b3Tiny.fontWeight,
    lineHeight: theme.typography.b3Tiny.lineHeight,
    letterSpacing: theme.typography.b3Tiny.letterSpacing,
    wordSpacing: theme.typography.b3Tiny.wordSpacing,
    color: theme.typography.b3Tiny.color,
    //
    backgroundColor: "#F2F2F2",
    borderWidth: "1px",
    borderColor: theme.typography.b3Tiny.color,
    borderRadius: "0px 0px 0px 0px",
    boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
    transition: "color 0.3s, border-color 0.3s",
    //
    "&:hover": {
      color: "#FFC000",
      //
      backgroundColor: "#F2F2F2",
      borderColor: "#FFC000",
      transition: "color 0.3s, border-color 0.3s",
    },
    "&.Mui-selected": {
      color: "#FF9600",
      //
      backgroundColor: "#F2F2F2",
      borderColor: "#FF9600",
      transition: "color 0.3s, border-color 0.3s",
    },
    "&.Mui-selected:hover": {
      color: "#FF9600",
      //
      backgroundColor: "#F2F2F2",
      borderColor: "#FF9600",
      transition: "color 0.3s, border-color 0.3s",
    },
    "&.Mui-disabled": {
      color: theme.typography.b3Tiny.color,
      //
      backgroundColor: "#F2F2F2",
      borderColor: theme.typography.b3Tiny.color,
      transition: "color 0.3s, border-color 0.3s",
    },
  },
}));

// ----------------------------------------------------------------------
// Code - FetchTableTextField
// ----------------------------------------------------------------------
const FetchTableTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    fontSize: theme.typography.contentSmall.fontSize,
    fontWeight: theme.typography.contentSmall.fontWeight,
    lineHeight: theme.typography.contentSmall.lineHeight,
    letterSpacing: theme.typography.contentSmall.letterSpacing,
    wordSpacing: theme.typography.contentSmall.wordSpacing,
    color: theme.typography.contentSmall.color,
    //
    marginTop: "4px",
    border: "2px solid",
    borderColor: "white",
    backgroundColor: "white",
    boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.5)",
    transition: "border-color 0.3s",
    "&:hover": {
      backgroundColor: "white",
    },
    "&.Mui-focused": {
      borderColor: "#FF9600",
      backgroundColor: "white",
    },
    "&.Mui-error": {
      borderColor: "red",
      backgroundColor: "white",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
}));

// ----------------------------------------------------------------------
// Code - FetchTableSlider
// ----------------------------------------------------------------------
const FetchTableSlider = styled(Slider)(({ theme }) => ({
  color: "#FF9600",
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    backgroundColor: "#FFC000",
    boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.5)",
    "&:focus, &:hover, &.Mui-active": {
      backgroundColor: "#FF9600",
      boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.5)",
    },
  },
  "& .MuiSlider-valueLabel": {
    fontSize: theme.typography.contentTiny.fontSize,
    fontWeight: theme.typography.contentTiny.fontWeight,
    lineHeight: theme.typography.contentTiny.lineHeight,
    letterSpacing: theme.typography.contentTiny.letterSpacing,
    wordSpacing: theme.typography.contentTiny.wordSpacing,
    color: theme.typography.contentTiny.color,
    //
    top: +14,
    left: 14,
    backgroundColor: "unset",
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    backgroundColor: "#bfbfbf",
  },
}));

// ----------------------------------------------------------------------
// Code - FetchTableLinearProgress
// ----------------------------------------------------------------------
const FetchTableLinearProgress = styled(LinearProgress)(() => ({
  // ... if needed
}));

// ----------------------------------------------------------------------
// Code - CardSwitch
// ----------------------------------------------------------------------
const CardSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 52,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(26px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#FF9600",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#6D6E70",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 300,
    }),
  },
}));

// ----------------------------------------------------------------------
// Code - CardOutlinedInput
// ----------------------------------------------------------------------
const CardOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  marginTop: "4px",
  "& .MuiInputBase-input": {
    fontSize: theme.typography.contentSmall.fontSize,
    fontWeight: theme.typography.contentSmall.fontWeight,
    lineHeight: theme.typography.contentSmall.lineHeight,
    letterSpacing: theme.typography.contentSmall.letterSpacing,
    wordSpacing: theme.typography.contentSmall.wordSpacing,
    color: theme.typography.contentSmall.color,
    //
    backgroundColor: "white",
    boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.5)",
    padding: "10px 12px 10px 12px",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    border: "2px solid",
    borderColor: "white",
  },

  "&:hover > .MuiOutlinedInput-notchedOutline": {
    border: "2px solid",
    borderColor: "white",
  },
  "&.Mui-focused > .MuiOutlinedInput-notchedOutline": {
    border: "2px solid",
    borderColor: "#FF9600",
    transition: "border-color 0.3s",
  },
}));

// ----------------------------------------------------------------------
// Code - CardMenuItem
// ----------------------------------------------------------------------
const CardMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: theme.typography.contentSmall.fontSize,
  fontWeight: theme.typography.contentSmall.fontWeight,
  lineHeight: theme.typography.contentSmall.lineHeight,
  letterSpacing: theme.typography.contentSmall.letterSpacing,
  wordSpacing: theme.typography.contentSmall.wordSpacing,
  color: theme.typography.contentSmall.color,
  //
  "&:hover": {
    background: "#F2F2F2",
  },
  "&.Mui-selected:hover": {
    background: "#E6E6E6",
  },
  "&:active": {
    background: "#E6E6E6",
  },
  background: "#FFFFFF",
  "&.Mui-selected": {
    background: "#E6E6E6",
    "&.Mui-focusVisible": {
      background: "#E6E6E6",
    },
  },
}));

// ----------------------------------------------------------------------
// Code - CardLinearProgress
// ----------------------------------------------------------------------
const CardLinearProgress = styled(LinearProgress)(() => ({
  // ... if needed
}));

// ----------------------------------------------------------------------
// Code - ItemSwitch
// ----------------------------------------------------------------------
const ItemSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 52,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(26px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#FF9600",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#6D6E70",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 300,
    }),
  },
}));

// ----------------------------------------------------------------------
// Code - ItemOutlinedInput
// ----------------------------------------------------------------------
const ItemOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  marginTop: "4px",
  "& .MuiInputBase-input": {
    fontSize: theme.typography.contentTiny.fontSize,
    fontWeight: theme.typography.contentTiny.fontWeight,
    lineHeight: theme.typography.contentTiny.lineHeight,
    letterSpacing: theme.typography.contentTiny.letterSpacing,
    wordSpacing: theme.typography.contentTiny.wordSpacing,
    color: theme.typography.contentTiny.color,
    //
    backgroundColor: "white",
    boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)",
    padding: "5px 12px 5px 12px",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    border: "2px solid",
    borderColor: "white",
  },

  "&:hover > .MuiOutlinedInput-notchedOutline": {
    border: "2px solid",
    borderColor: "white",
  },
  "&.Mui-focused > .MuiOutlinedInput-notchedOutline": {
    border: "2px solid",
    borderColor: "#FF9600",
    transition: "border-color 0.3s",
  },
}));

// ----------------------------------------------------------------------
// Code - ItemMenuItem
// ----------------------------------------------------------------------
const ItemMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: theme.typography.contentTiny.fontSize,
  fontWeight: theme.typography.contentTiny.fontWeight,
  lineHeight: theme.typography.contentTiny.lineHeight,
  letterSpacing: theme.typography.contentTiny.letterSpacing,
  wordSpacing: theme.typography.contentTiny.wordSpacing,
  color: theme.typography.contentTiny.color,
  //
  "&:hover": {
    background: "#F2F2F2",
  },
  "&.Mui-selected:hover": {
    background: "#E6E6E6",
  },
  "&:active": {
    background: "#E6E6E6",
  },
  background: "#FFFFFF",
  "&.Mui-selected": {
    background: "#E6E6E6",
    "&.Mui-focusVisible": {
      background: "#E6E6E6",
    },
  },
}));

// ----------------------------------------------------------------------
// Code - CardAlertWarning
// ----------------------------------------------------------------------
const CardAlertWarning = styled(Alert)(({ theme }) => ({
  fontSize: theme.typography.contentMiddle.fontSize,
  fontWeight: theme.typography.contentMiddle.fontWeight,
  lineHeight: 1.2,
  letterSpacing: theme.typography.contentMiddle.letterSpacing,
  wordSpacing: theme.typography.contentMiddle.wordSpacing,
  color: theme.typography.contentMiddle.color,
  //
  background: "#ffe79a",
}));

// ----------------------------------------------------------------------
// Code - CardAlertError
// ----------------------------------------------------------------------
const CardAlertError = styled(Alert)(({ theme }) => ({
  fontSize: theme.typography.contentMiddle.fontSize,
  fontWeight: theme.typography.contentMiddle.fontWeight,
  lineHeight: 1.2,
  letterSpacing: theme.typography.contentMiddle.letterSpacing,
  wordSpacing: theme.typography.contentMiddle.wordSpacing,
  color: theme.typography.contentMiddle.color,
  //
  background: "#ffd3b6",
}));

// ----------------------------------------------------------------------
// Code - CardAlertSuccess
// ----------------------------------------------------------------------
const CardAlertSuccess = styled(Alert)(({ theme }) => ({
  fontSize: theme.typography.contentMiddle.fontSize,
  fontWeight: theme.typography.contentMiddle.fontWeight,
  lineHeight: 1.2,
  letterSpacing: theme.typography.contentMiddle.letterSpacing,
  wordSpacing: theme.typography.contentMiddle.wordSpacing,
  color: theme.typography.contentMiddle.color,
  //
  background: "#a8e6cf",
}));

// ----------------------------------------------------------------------
// Code - ModalAlertWarning
// ----------------------------------------------------------------------
const ModalAlertWarning = styled(Alert)(({ theme }) => ({
  fontSize: theme.typography.contentMiddle.fontSize,
  fontWeight: theme.typography.contentMiddle.fontWeight,
  lineHeight: 1.2,
  letterSpacing: theme.typography.contentMiddle.letterSpacing,
  wordSpacing: theme.typography.contentMiddle.wordSpacing,
  color: theme.typography.contentMiddle.color,
  //
  background: "#ffe79a",
}));

// ----------------------------------------------------------------------
// Code - ModalAlertError
// ----------------------------------------------------------------------
const ModalAlertError = styled(Alert)(({ theme }) => ({
  fontSize: theme.typography.contentMiddle.fontSize,
  fontWeight: theme.typography.contentMiddle.fontWeight,
  lineHeight: 1.2,
  letterSpacing: theme.typography.contentMiddle.letterSpacing,
  wordSpacing: theme.typography.contentMiddle.wordSpacing,
  color: theme.typography.contentMiddle.color,
  //
  background: "#ffd3b6",
}));

// ----------------------------------------------------------------------
// Code - ModalAlertSuccess
// ----------------------------------------------------------------------
const ModalAlertSuccess = styled(Alert)(({ theme }) => ({
  fontSize: theme.typography.contentMiddle.fontSize,
  fontWeight: theme.typography.contentMiddle.fontWeight,
  lineHeight: 1.2,
  letterSpacing: theme.typography.contentMiddle.letterSpacing,
  wordSpacing: theme.typography.contentMiddle.wordSpacing,
  color: theme.typography.contentMiddle.color,
  //
  background: "#a8e6cf",
}));

// ----------------------------------------------------------------------
// Code - ModalContentSwitch
// ----------------------------------------------------------------------
const ModalContentSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 52,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(26px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#FF9600",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#6D6E70",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 300,
    }),
  },
}));

// ----------------------------------------------------------------------
// Code - ModalContentOutlinedInput
// ----------------------------------------------------------------------
const ModalContentOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  marginTop: "4px",
  "& .MuiInputBase-input": {
    fontSize: theme.typography.contentSmall.fontSize,
    fontWeight: theme.typography.contentSmall.fontWeight,
    lineHeight: theme.typography.contentSmall.lineHeight,
    letterSpacing: theme.typography.contentSmall.letterSpacing,
    wordSpacing: theme.typography.contentSmall.wordSpacing,
    color: theme.typography.contentSmall.color,
    //
    backgroundColor: "white",
    boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.5)",
    padding: "10px 26px 10px 12px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "2px solid",
    borderColor: "white",
  },
  "&:hover > .MuiOutlinedInput-notchedOutline": {
    border: "2px solid",
    borderColor: "white",
  },
  "&.Mui-focused > .MuiOutlinedInput-notchedOutline": {
    border: "2px solid",
    borderColor: "#FF9600",
    transition: "border-color 0.3s",
  },
}));

// ----------------------------------------------------------------------
// Code - ModalContentMenuItem
// ----------------------------------------------------------------------
const ModalContentMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: theme.typography.contentSmall.fontSize,
  fontWeight: theme.typography.contentSmall.fontWeight,
  lineHeight: theme.typography.contentSmall.lineHeight,
  letterSpacing: theme.typography.contentSmall.letterSpacing,
  wordSpacing: theme.typography.contentSmall.wordSpacing,
  color: theme.typography.contentSmall.color,
  //
  "&:hover": {
    background: "#F2F2F2",
  },
  "&.Mui-selected:hover": {
    background: "#E6E6E6",
  },
  "&:active": {
    background: "#E6E6E6",
  },
  background: "#FFFFFF",
  "&.Mui-selected": {
    background: "#E6E6E6",
    "&.Mui-focusVisible": {
      background: "#E6E6E6",
    },
  },
}));

// ----------------------------------------------------------------------
// Code - ModalContentTextField
// ----------------------------------------------------------------------
const ModalContentTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    fontSize: theme.typography.contentSmall.fontSize,
    fontWeight: theme.typography.contentSmall.fontWeight,
    lineHeight: theme.typography.contentSmall.lineHeight,
    letterSpacing: theme.typography.contentSmall.letterSpacing,
    wordSpacing: theme.typography.contentSmall.wordSpacing,
    color: theme.typography.contentSmall.color,
    //
    marginTop: "4px",
    border: "2px solid",
    borderColor: "white",
    backgroundColor: "white",
    boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.5)",
    transition: "border-color 0.3s",
    "&:hover": {
      backgroundColor: "white",
    },
    "&.Mui-focused": {
      borderColor: "#FF9600",
      backgroundColor: "white",
    },
    "&.Mui-error": {
      borderColor: "red",
      backgroundColor: "white",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  "& .MuiButtonBase-root": {
    borderRadius: "0px",
  },
}));

// ----------------------------------------------------------------------
// Code - ModalActionsLoadingButton
// ----------------------------------------------------------------------
const ModalActionsLoadingButton = styled(LoadingButton)(({ theme }) => ({
  fontSize: theme.typography.b1Small.fontSize,
  fontWeight: theme.typography.b1Small.fontWeight,
  lineHeight: theme.typography.b1Small.lineHeight,
  letterSpacing: theme.typography.b1Small.letterSpacing,
  wordSpacing: theme.typography.b1Small.wordSpacing,
  color: theme.typography.b1Small.color,
  //
  padding: "8px 12px",
  textTransform: "none",
  backgroundColor: "#FFC000",
  borderRadius: "0px 0px 0px 0px",
  boxShadow: "0px 0px 1px 0px rgba(0,0,0,0.5)",
  "&:hover": {
    backgroundColor: "#FF9600",
    boxShadow: "0px 0px 1px 0px rgba(0,0,0,0.5)",
  },
}));

// ----------------------------------------------------------------------
// Code - ModalDatePicker
// ----------------------------------------------------------------------
const ModalDatePicker = styled(DesktopDatePicker)(({ theme }) => ({
  marginTop: "4px",
  width: "100%",
  background: "white",

  "& .MuiInputBase-root": {
    boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.5)",
  },

  "& .MuiInputBase-input": {
    fontSize: theme.typography.contentSmall.fontSize,
    fontWeight: theme.typography.contentSmall.fontWeight,
    lineHeight: theme.typography.contentSmall.lineHeight,
    letterSpacing: theme.typography.contentSmall.letterSpacing,
    wordSpacing: theme.typography.contentSmall.wordSpacing,
    color: theme.typography.contentSmall.color,
  },

  "& .MuiOutlinedInput-notchedOutline": {
    border: "2px solid",
    borderColor: "white",
  },

  "&:hover fieldset.MuiOutlinedInput-notchedOutline": {
    border: "2px solid",
    borderColor: "white",
  },

  "& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline": {
    border: "2px solid",
    borderColor: "#FF9600",
  },

  "& .Mui-error fieldset.MuiOutlinedInput-notchedOutline": {
    border: "2px solid",
    borderColor: "white",
  },

  "& .MuiIconButton-root": {
    borderRadius: "0px",
  },
}));

const ModalDatePickerSlotProps = {
  textField: { size: "small" },
  actionBar: {
    actions: ["today", "clear"],
  },
  popper: {
    sx: {
      "& .MuiDateCalendar-root": {
        background: "white",
      },
      "& .MuiPickersYear-yearButton": {
        fontSize: typography.contentSmall.fontSize,
        fontWeight: typography.contentSmall.fontWeight,
        lineHeight: typography.contentSmall.lineHeight,
        letterSpacing: typography.contentSmall.letterSpacing,
        wordSpacing: typography.contentSmall.wordSpacing,
        color: typography.contentSmall.color,
        //
        background: "white",
        borderRadius: "0px",
        "&.Mui-selected": {
          background: "#FFC000",
          borderRadius: "0px",
          //
          fontSize: typography.contentSmall.fontSize,
          fontWeight: typography.contentSmall.fontWeight,
          lineHeight: typography.contentSmall.lineHeight,
          letterSpacing: typography.contentSmall.letterSpacing,
          wordSpacing: typography.contentSmall.wordSpacing,
          color: typography.contentSmall.color,
        },
        "&.Mui-selected:hover": {
          background: "#FFC000",
          borderRadius: "0px",
          //
          fontSize: typography.contentSmall.fontSize,
          fontWeight: typography.contentSmall.fontWeight,
          lineHeight: typography.contentSmall.lineHeight,
          letterSpacing: typography.contentSmall.letterSpacing,
          wordSpacing: typography.contentSmall.wordSpacing,
          color: typography.contentSmall.color,
        },
        "&:hover": {
          background: "#FFC000",
          borderRadius: "0px",
          //
          fontSize: typography.contentSmall.fontSize,
          fontWeight: typography.contentSmall.fontWeight,
          lineHeight: typography.contentSmall.lineHeight,
          letterSpacing: typography.contentSmall.letterSpacing,
          wordSpacing: typography.contentSmall.wordSpacing,
          color: typography.contentSmall.color,
        },
        "&:focus:not(.focus-visible)": {
          background: "#FFC000",
          borderRadius: "0px",
          //
          fontSize: typography.contentSmall.fontSize,
          fontWeight: typography.contentSmall.fontWeight,
          lineHeight: typography.contentSmall.lineHeight,
          letterSpacing: typography.contentSmall.letterSpacing,
          wordSpacing: typography.contentSmall.wordSpacing,
          color: typography.contentSmall.color,
        },
      },
      "& .MuiButtonBase-root": {
        fontSize: typography.contentSmall.fontSize,
        fontWeight: typography.contentSmall.fontWeight,
        lineHeight: typography.contentSmall.lineHeight,
        letterSpacing: typography.contentSmall.letterSpacing,
        wordSpacing: typography.contentSmall.wordSpacing,
        color: typography.contentSmall.color,
        //
        background: "#F2F2F2",
        borderRadius: "0px",
        "&.Mui-selected": {
          background: "#FFC000",
          borderRadius: "0px",
          //
          fontSize: typography.contentSmall.fontSize,
          fontWeight: typography.contentSmall.fontWeight,
          lineHeight: typography.contentSmall.lineHeight,
          letterSpacing: typography.contentSmall.letterSpacing,
          wordSpacing: typography.contentSmall.wordSpacing,
          color: typography.contentSmall.color,
        },
        "&.Mui-selected:hover": {
          background: "#FFC000",
          borderRadius: "0px",
          //
          fontSize: typography.contentSmall.fontSize,
          fontWeight: typography.contentSmall.fontWeight,
          lineHeight: typography.contentSmall.lineHeight,
          letterSpacing: typography.contentSmall.letterSpacing,
          wordSpacing: typography.contentSmall.wordSpacing,
          color: typography.contentSmall.color,
        },
        "&:hover": {
          background: "#FFC000",
          borderRadius: "0px",
          //
          fontSize: typography.contentSmall.fontSize,
          fontWeight: typography.contentSmall.fontWeight,
          lineHeight: typography.contentSmall.lineHeight,
          letterSpacing: typography.contentSmall.letterSpacing,
          wordSpacing: typography.contentSmall.wordSpacing,
          color: typography.contentSmall.color,
        },
        "&:focus:not(.focus-visible)": {
          background: "#FFC000",
          borderRadius: "0px",
          //
          fontSize: typography.contentSmall.fontSize,
          fontWeight: typography.contentSmall.fontWeight,
          lineHeight: typography.contentSmall.lineHeight,
          letterSpacing: typography.contentSmall.letterSpacing,
          wordSpacing: typography.contentSmall.wordSpacing,
          color: typography.contentSmall.color,
        },
      },
      "& .MuiPickersCalendarHeader-root": {
        fontSize: typography.contentMiddle.fontSize,
        fontWeight: typography.contentMiddle.fontWeight,
        lineHeight: typography.contentMiddle.lineHeight,
        letterSpacing: typography.contentMiddle.letterSpacing,
        wordSpacing: typography.contentMiddle.wordSpacing,
        color: typography.contentMiddle.color,
        //
        "& .MuiButtonBase-root": {
          background: "white",
          borderRadius: "0px",
          //
          fontSize: typography.contentSmall.fontSize,
          fontWeight: typography.contentSmall.fontWeight,
          lineHeight: typography.contentSmall.lineHeight,
          letterSpacing: typography.contentSmall.letterSpacing,
          wordSpacing: typography.contentSmall.wordSpacing,
          color: typography.contentSmall.color,

          "&.Mui-selected": {
            background: "white",
            borderRadius: "0px",
            //
            fontSize: typography.contentSmall.fontSize,
            fontWeight: typography.contentSmall.fontWeight,
            lineHeight: typography.contentSmall.lineHeight,
            letterSpacing: typography.contentSmall.letterSpacing,
            wordSpacing: typography.contentSmall.wordSpacing,
            color: typography.contentSmall.color,
          },
          "&.Mui-selected:hover": {
            background: "white",
            borderRadius: "0px",
            //
            fontSize: typography.contentSmall.fontSize,
            fontWeight: typography.contentSmall.fontWeight,
            lineHeight: typography.contentSmall.lineHeight,
            letterSpacing: typography.contentSmall.letterSpacing,
            wordSpacing: typography.contentSmall.wordSpacing,
            color: typography.contentSmall.color,
          },
          "&:hover": {
            background: "white",
            borderRadius: "0px",
            //
            fontSize: typography.contentSmall.fontSize,
            fontWeight: typography.contentSmall.fontWeight,
            lineHeight: typography.contentSmall.lineHeight,
            letterSpacing: typography.contentSmall.letterSpacing,
            wordSpacing: typography.contentSmall.wordSpacing,
            color: typography.contentSmall.color,
          },
          "&:focus:not(.focus-visible)": {
            background: "white",
            borderRadius: "0px",
            //
            fontSize: typography.contentSmall.fontSize,
            fontWeight: typography.contentSmall.fontWeight,
            lineHeight: typography.contentSmall.lineHeight,
            letterSpacing: typography.contentSmall.letterSpacing,
            wordSpacing: typography.contentSmall.wordSpacing,
            color: typography.contentSmall.color,
          },
        },
      },
    },
  },
};

const ModalPromoterPortalDatePickerSlotProps = {
  textField: { size: "small" },
  actionBar: {
    actions: ["today"],
  },
  popper: {
    sx: {
      "& .MuiDateCalendar-root": {
        background: "white",
      },
      "& .MuiPickersYear-yearButton": {
        fontSize: typography.contentSmall.fontSize,
        fontWeight: typography.contentSmall.fontWeight,
        lineHeight: typography.contentSmall.lineHeight,
        letterSpacing: typography.contentSmall.letterSpacing,
        wordSpacing: typography.contentSmall.wordSpacing,
        color: typography.contentSmall.color,
        //
        background: "white",
        borderRadius: "0px",
        "&.Mui-selected": {
          background: "#FFC000",
          borderRadius: "0px",
          //
          fontSize: typography.contentSmall.fontSize,
          fontWeight: typography.contentSmall.fontWeight,
          lineHeight: typography.contentSmall.lineHeight,
          letterSpacing: typography.contentSmall.letterSpacing,
          wordSpacing: typography.contentSmall.wordSpacing,
          color: typography.contentSmall.color,
        },
        "&.Mui-selected:hover": {
          background: "#FFC000",
          borderRadius: "0px",
          //
          fontSize: typography.contentSmall.fontSize,
          fontWeight: typography.contentSmall.fontWeight,
          lineHeight: typography.contentSmall.lineHeight,
          letterSpacing: typography.contentSmall.letterSpacing,
          wordSpacing: typography.contentSmall.wordSpacing,
          color: typography.contentSmall.color,
        },
        "&:hover": {
          background: "#FFC000",
          borderRadius: "0px",
          //
          fontSize: typography.contentSmall.fontSize,
          fontWeight: typography.contentSmall.fontWeight,
          lineHeight: typography.contentSmall.lineHeight,
          letterSpacing: typography.contentSmall.letterSpacing,
          wordSpacing: typography.contentSmall.wordSpacing,
          color: typography.contentSmall.color,
        },
        "&:focus:not(.focus-visible)": {
          background: "#FFC000",
          borderRadius: "0px",
          //
          fontSize: typography.contentSmall.fontSize,
          fontWeight: typography.contentSmall.fontWeight,
          lineHeight: typography.contentSmall.lineHeight,
          letterSpacing: typography.contentSmall.letterSpacing,
          wordSpacing: typography.contentSmall.wordSpacing,
          color: typography.contentSmall.color,
        },
      },
      "& .MuiButtonBase-root": {
        fontSize: typography.contentSmall.fontSize,
        fontWeight: typography.contentSmall.fontWeight,
        lineHeight: typography.contentSmall.lineHeight,
        letterSpacing: typography.contentSmall.letterSpacing,
        wordSpacing: typography.contentSmall.wordSpacing,
        color: typography.contentSmall.color,
        //
        background: "#F2F2F2",
        borderRadius: "0px",
        "&.Mui-selected": {
          background: "#FFC000",
          borderRadius: "0px",
          //
          fontSize: typography.contentSmall.fontSize,
          fontWeight: typography.contentSmall.fontWeight,
          lineHeight: typography.contentSmall.lineHeight,
          letterSpacing: typography.contentSmall.letterSpacing,
          wordSpacing: typography.contentSmall.wordSpacing,
          color: typography.contentSmall.color,
        },
        "&.Mui-selected:hover": {
          background: "#FFC000",
          borderRadius: "0px",
          //
          fontSize: typography.contentSmall.fontSize,
          fontWeight: typography.contentSmall.fontWeight,
          lineHeight: typography.contentSmall.lineHeight,
          letterSpacing: typography.contentSmall.letterSpacing,
          wordSpacing: typography.contentSmall.wordSpacing,
          color: typography.contentSmall.color,
        },
        "&:hover": {
          background: "#FFC000",
          borderRadius: "0px",
          //
          fontSize: typography.contentSmall.fontSize,
          fontWeight: typography.contentSmall.fontWeight,
          lineHeight: typography.contentSmall.lineHeight,
          letterSpacing: typography.contentSmall.letterSpacing,
          wordSpacing: typography.contentSmall.wordSpacing,
          color: typography.contentSmall.color,
        },
        "&:focus:not(.focus-visible)": {
          background: "#FFC000",
          borderRadius: "0px",
          //
          fontSize: typography.contentSmall.fontSize,
          fontWeight: typography.contentSmall.fontWeight,
          lineHeight: typography.contentSmall.lineHeight,
          letterSpacing: typography.contentSmall.letterSpacing,
          wordSpacing: typography.contentSmall.wordSpacing,
          color: typography.contentSmall.color,
        },
      },
      "& .MuiPickersCalendarHeader-root": {
        fontSize: typography.contentMiddle.fontSize,
        fontWeight: typography.contentMiddle.fontWeight,
        lineHeight: typography.contentMiddle.lineHeight,
        letterSpacing: typography.contentMiddle.letterSpacing,
        wordSpacing: typography.contentMiddle.wordSpacing,
        color: typography.contentMiddle.color,
        //
        "& .MuiButtonBase-root": {
          background: "white",
          borderRadius: "0px",
          //
          fontSize: typography.contentSmall.fontSize,
          fontWeight: typography.contentSmall.fontWeight,
          lineHeight: typography.contentSmall.lineHeight,
          letterSpacing: typography.contentSmall.letterSpacing,
          wordSpacing: typography.contentSmall.wordSpacing,
          color: typography.contentSmall.color,

          "&.Mui-selected": {
            background: "white",
            borderRadius: "0px",
            //
            fontSize: typography.contentSmall.fontSize,
            fontWeight: typography.contentSmall.fontWeight,
            lineHeight: typography.contentSmall.lineHeight,
            letterSpacing: typography.contentSmall.letterSpacing,
            wordSpacing: typography.contentSmall.wordSpacing,
            color: typography.contentSmall.color,
          },
          "&.Mui-selected:hover": {
            background: "white",
            borderRadius: "0px",
            //
            fontSize: typography.contentSmall.fontSize,
            fontWeight: typography.contentSmall.fontWeight,
            lineHeight: typography.contentSmall.lineHeight,
            letterSpacing: typography.contentSmall.letterSpacing,
            wordSpacing: typography.contentSmall.wordSpacing,
            color: typography.contentSmall.color,
          },
          "&:hover": {
            background: "white",
            borderRadius: "0px",
            //
            fontSize: typography.contentSmall.fontSize,
            fontWeight: typography.contentSmall.fontWeight,
            lineHeight: typography.contentSmall.lineHeight,
            letterSpacing: typography.contentSmall.letterSpacing,
            wordSpacing: typography.contentSmall.wordSpacing,
            color: typography.contentSmall.color,
          },
          "&:focus:not(.focus-visible)": {
            background: "white",
            borderRadius: "0px",
            //
            fontSize: typography.contentSmall.fontSize,
            fontWeight: typography.contentSmall.fontWeight,
            lineHeight: typography.contentSmall.lineHeight,
            letterSpacing: typography.contentSmall.letterSpacing,
            wordSpacing: typography.contentSmall.wordSpacing,
            color: typography.contentSmall.color,
          },
        },
      },
    },
  },
};

// ----------------------------------------------------------------------
// Code - AutocompleteOption
// ----------------------------------------------------------------------
const AutocompleteOption = styled("li")(({ theme }) => ({
  fontSize: theme.typography.contentSmall.fontSize,
  fontWeight: theme.typography.contentSmall.fontWeight,
  lineHeight: theme.typography.contentSmall.lineHeight,
  letterSpacing: theme.typography.contentSmall.letterSpacing,
  wordSpacing: theme.typography.contentSmall.wordSpacing,
  color: theme.typography.contentSmall.color,
  //
  '& + .MuiAutocomplete-option[aria-selected="true"]:hover': {
    backgroundColor: "#EbEbEb",
  },
}));

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export {
  B1Huge,
  B1Big,
  B1Middle,
  B1Small,
  B1Tiny,
  B2Huge,
  B2Big,
  B2Middle,
  B2Small,
  B2Tiny,
  B3Huge,
  B3Big,
  B3Middle,
  B3Small,
  B3Tiny,
  AuthenticationButton,
  AuthenticationTextField,
  AuthenticationAlertWarning,
  AuthenticationAlertError,
  AuthenticationAlertSuccess,
  FetchTablePagination,
  FetchTableTextField,
  FetchTableSlider,
  FetchTableLinearProgress,
  CardSwitch,
  CardLinearProgress,
  CardOutlinedInput,
  CardMenuItem,
  ItemSwitch,
  ItemOutlinedInput,
  ItemMenuItem,
  CardAlertWarning,
  CardAlertError,
  CardAlertSuccess,
  ModalAlertWarning,
  ModalAlertError,
  ModalAlertSuccess,
  ModalContentSwitch,
  ModalContentOutlinedInput,
  ModalContentMenuItem,
  ModalContentTextField,
  ModalActionsLoadingButton,
  ModalDatePicker,
  ModalDatePickerSlotProps,
  ModalPromoterPortalDatePickerSlotProps,
  AutocompleteOption,
};
