// react and js
import * as Yup from "yup";
import { React, useContext, useState, useRef, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import PropTypes from "prop-types";

// mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  Grid,
  Typography,
  Box,
} from "@mui/material";

// components
import RequestMessage from "./RequestMessage";
import CardOutlet from "./CardOutlet";
import Iconify from "./Iconify";
import backendRequest from "./BackendRequest";

// hooks
import appContext from "../hooks/appContext";
import { stateStorageInit } from "../hooks/stateStorageInit";

// layouts

// pages

// theme
import {
  AuthenticationButton,
  AuthenticationTextField,
  B1Small,
  ModalContentSwitch,
} from "../theme/styled";

// utils
import { combineReturnMessage } from "../utils/common";

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export function Login({ caller, RecaptchaOutlet }) {
  Login.propTypes = {
    caller: PropTypes.any.isRequired,
    RecaptchaOutlet: PropTypes.any.isRequired,
  };

  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);
  const [showPassword, setShowPassword] = useState(false);

  const wrapperRef = useRef(null);

  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const [success, setSuccess] = useState(false);

  const [returnMessage, setReturnMessage] = useState("");

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setError(false);
        setWarning(false);
        setSuccess(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside, {
      passive: false,
      capture: false,
    });
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef]);

  const ValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Please enter your password"),
  });

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleResendApprovalEmail = (emailParam) => {
    if (navigator.onLine === true) {
      setError(false);
      setWarning(false);
      setSuccess(false);

      const sendData = {
        request_type: "checkout",
        route_info: "authentication",
        client: {
          mode: "resend_approval_email_alt",
          email: emailParam,
        },
      };
      backendRequest(process.env.REACT_APP_URL_API_CHECKOUT, sendData)
        .then((contentFromBackend) => {
          const keyOnly = Object.keys(contentFromBackend);
          const checkKey = keyOnly.includes("answer");

          if (checkKey === true) {
            setReturnMessage("Approval email resend, please check your inbox!");
            setSuccess(true);
          } else {
            combineReturnMessage(contentFromBackend, setReturnMessage);
            setError(true);
          }
        })
        .catch(() => {
          if (navigator.onLine === true) {
            setReturnMessage("Unknown error!");
            setError(true);
          }
        });
    }
  };

  const handleLogin = (values, setSubmitting) => {
    setError(false);
    const myLocalSettingStorage = JSON.parse(
      sessionStorage.getItem("myLocalSettingStorage")
    );

    const sendData = {
      request_type: "checkout",
      route_info: "authentication",
      client: {
        mode: "login",
        email: values.email,
        password: values.password,
        request_known_accounts: "1",
      },
    };
    backendRequest(process.env.REACT_APP_URL_API_CHECKOUT, sendData)
      .then((contentFromBackend) => {
        const keyOnly = Object.keys(contentFromBackend);
        const checkKey = keyOnly.includes("answer");

        if (checkKey === true) {
          if (caller === "distribution") {
            // Perform after login checks
            let sameProductFound = false;
            contentFromBackend.dashboard_subscriptions.subscriptions.forEach(
              (subscription) => {
                if (
                  myLocalSettingStorage.distributionPlan.product_tag ===
                  subscription.product.product_tag
                ) {
                  if (
                    subscription.plan.payment_state === "distribution_inactive"
                  ) {
                    sameProductFound = false;
                  } else if (
                    subscription.plan.payment_state === "distribution_active" ||
                    subscription.plan.payment_state ===
                      "distribution_approval_pending"
                  ) {
                    sameProductFound = true;
                  }
                }
              }
            );

            // Error step handling
            if (sameProductFound === false) {
              // Continue
              setStateStorage((previousState) => ({
                ...previousState,
                distributionCurrentStep: 2,
                userInformation: contentFromBackend,
              }));

              let newLocalSettingStorage = JSON.parse(
                sessionStorage.getItem("myLocalSettingStorage")
              );
              if (newLocalSettingStorage === null) {
                newLocalSettingStorage = stateStorageInit;
              }

              newLocalSettingStorage.distributionCurrentStep = 2;
              newLocalSettingStorage.userInformation = contentFromBackend;
              sessionStorage.setItem(
                "myLocalSettingStorage",
                JSON.stringify(newLocalSettingStorage)
              );
            } else if (sameProductFound === true) {
              setStateStorage((previousState) => ({
                ...previousState,
                distributionCurrentStep: 1,
                distributionErrorStep: 1,
                distributionErrorStepCode: "same_product_subscribed",
                userInformation: contentFromBackend,
              }));

              let newLocalSettingStorage = JSON.parse(
                sessionStorage.getItem("myLocalSettingStorage")
              );
              if (newLocalSettingStorage === null) {
                newLocalSettingStorage = stateStorageInit;
              }

              newLocalSettingStorage.distributionCurrentStep = 1;
              newLocalSettingStorage.distributionErrorStep = 1;
              newLocalSettingStorage.distributionErrorStepCode =
                "same_product_subscribed";
              newLocalSettingStorage.userInformation = contentFromBackend;
              sessionStorage.setItem(
                "myLocalSettingStorage",
                JSON.stringify(newLocalSettingStorage)
              );
            }
          } else if (caller === "distribution-tick-data") {
            // Perform after login checks
            let sameProductFound = false;
            contentFromBackend.dashboard_subscriptions.subscriptions.forEach(
              (subscription) => {
                if (
                  myLocalSettingStorage.distributionTickDataPlan.product_tag ===
                  subscription.product.product_tag
                ) {
                  if (
                    subscription.plan.payment_state === "distribution_inactive"
                  ) {
                    sameProductFound = false;
                  } else if (
                    subscription.plan.payment_state === "distribution_active" ||
                    subscription.plan.payment_state ===
                      "distribution_approval_pending"
                  ) {
                    sameProductFound = true;
                  }
                }
              }
            );

            // Error step handling
            if (sameProductFound === false) {
              // Continue
              setStateStorage((previousState) => ({
                ...previousState,
                distributionTickDataCurrentStep: 2,
                userInformation: contentFromBackend,
              }));

              let newLocalSettingStorage = JSON.parse(
                sessionStorage.getItem("myLocalSettingStorage")
              );
              if (newLocalSettingStorage === null) {
                newLocalSettingStorage = stateStorageInit;
              }

              newLocalSettingStorage.distributionTickDataCurrentStep = 2;
              newLocalSettingStorage.userInformation = contentFromBackend;
              sessionStorage.setItem(
                "myLocalSettingStorage",
                JSON.stringify(newLocalSettingStorage)
              );
            } else if (sameProductFound === true) {
              setStateStorage((previousState) => ({
                ...previousState,
                distributionTickDataCurrentStep: 1,
                distributionTickDataErrorStep: 1,
                distributionTickDataErrorStepCode:
                  "same_tick_data_product_subscribed",
                userInformation: contentFromBackend,
              }));

              let newLocalSettingStorage = JSON.parse(
                sessionStorage.getItem("myLocalSettingStorage")
              );
              if (newLocalSettingStorage === null) {
                newLocalSettingStorage = stateStorageInit;
              }

              newLocalSettingStorage.distributionTickDataCurrentStep = 1;
              newLocalSettingStorage.distributionTickDataErrorStep = 1;
              newLocalSettingStorage.distributionTickDataErrorStepCode =
                "same_tick_data_product_subscribed";
              newLocalSettingStorage.userInformation = contentFromBackend;
              sessionStorage.setItem(
                "myLocalSettingStorage",
                JSON.stringify(newLocalSettingStorage)
              );
            }
          } else if (caller === "promotion") {
            // Perform after login checks
            let promotionPeriodOver = false;
            if (
              contentFromBackend.dashboard_promotion.message.includes(
                "access denied"
              )
            ) {
              promotionPeriodOver = true;
            }

            // Perform after login checks
            let promoterAlreadyInUse = false;
            if (
              contentFromBackend.dashboard_promotion.message.includes("success")
            ) {
              contentFromBackend.dashboard_promotion.promotion.forEach(
                (partner) => {
                  if (
                    partner.promotion_broker_details.broker_id ===
                    myLocalSettingStorage.promotionPromotionPartner.broker_id
                  ) {
                    if (partner.subscriptions[0].accounts.length !== 0) {
                      promoterAlreadyInUse = true;
                    }
                  }
                }
              );
            }

            // Error step handling
            if (
              promotionPeriodOver === false &&
              promoterAlreadyInUse === false
            ) {
              // Continue
              setStateStorage((previousState) => ({
                ...previousState,
                promotionCurrentStep: 2,
                userInformation: contentFromBackend,
              }));

              let newLocalSettingStorage = JSON.parse(
                sessionStorage.getItem("myLocalSettingStorage")
              );
              if (newLocalSettingStorage === null) {
                newLocalSettingStorage = stateStorageInit;
              }

              newLocalSettingStorage.promotionCurrentStep = 2;
              newLocalSettingStorage.userInformation = contentFromBackend;
              sessionStorage.setItem(
                "myLocalSettingStorage",
                JSON.stringify(newLocalSettingStorage)
              );
            } else if (promotionPeriodOver === true) {
              setStateStorage((previousState) => ({
                ...previousState,
                promotionCurrentStep: 1,
                promotionErrorStep: 1,
                promotionErrorStepCode: "promotion_period_over",
                userInformation: contentFromBackend,
              }));

              let newLocalSettingStorage = JSON.parse(
                sessionStorage.getItem("myLocalSettingStorage")
              );
              if (newLocalSettingStorage === null) {
                newLocalSettingStorage = stateStorageInit;
              }

              newLocalSettingStorage.promotionCurrentStep = 1;
              newLocalSettingStorage.promotionErrorStep = 1;
              newLocalSettingStorage.promotionErrorStepCode =
                "promotion_period_over";
              newLocalSettingStorage.userInformation = contentFromBackend;
              sessionStorage.setItem(
                "myLocalSettingStorage",
                JSON.stringify(newLocalSettingStorage)
              );
            } else if (promoterAlreadyInUse === true) {
              setStateStorage((previousState) => ({
                ...previousState,
                promotionCurrentStep: 1,
                promotionErrorStep: 1,
                promotionErrorStepCode: "promoter_already_in_use",
                userInformation: contentFromBackend,
              }));

              let newLocalSettingStorage = JSON.parse(
                sessionStorage.getItem("myLocalSettingStorage")
              );
              if (newLocalSettingStorage === null) {
                newLocalSettingStorage = stateStorageInit;
              }

              newLocalSettingStorage.promotionCurrentStep = 1;
              newLocalSettingStorage.promotionErrorStep = 1;
              newLocalSettingStorage.promotionErrorStepCode =
                "promoter_already_in_use";
              newLocalSettingStorage.userInformation = contentFromBackend;
              sessionStorage.setItem(
                "myLocalSettingStorage",
                JSON.stringify(newLocalSettingStorage)
              );
            }
          }

          setError(false);
        } else {
          setStateStorage((previousState) => ({
            ...previousState,
          }));
          combineReturnMessage(contentFromBackend, setReturnMessage);

          if (contentFromBackend.message.includes("please check your inbox")) {
            setReturnMessage(
              <>
                Email not approved, please check your inbox and confirm your
                email address! Click {/* eslint-disable */}
                <Typography variant="contentMiddle">
                  <Link
                    component={RouterLink}
                    onClick={() => {
                      handleResendApprovalEmail(values.email);

                      setStateStorage((previousState) => ({
                        ...previousState,
                        performRescroll: true,
                      }));
                    }}
                    underline="none"
                    variant="contentMiddleLink"
                    color="inherit"
                  >
                    here
                  </Link>
                </Typography>
                {/* eslint-enable */} to resend the approval email.
              </>
            );
          }

          setError(true);
        }
        setSubmitting(false);
      })
      // eslint-disable-next-line no-console
      .catch(() => {
        if (navigator.onLine === true) {
          setReturnMessage("Unknown error!");
          setError(true);
          setSubmitting(false);
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: ValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      handleLogin(values, setSubmitting);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <CardOutlet
      variant="outlet"
      backgroundProp="#F2F2F2"
      title="Client Account"
      subheader="Please sign in to an existing client account or create a new one!"
      mainOutlet={
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={0} sx={{ mt: 1 }}>
              <Box>
                <Typography
                  variant="contentSmall"
                  sx={{
                    color:
                      Boolean(touched.email && errors.email) === true
                        ? "red"
                        : "none",
                  }}
                >
                  {Boolean(touched.email && errors.email) === true
                    ? errors.email
                    : "Email address"}
                </Typography>
                <AuthenticationTextField
                  fullWidth
                  hiddenLabel
                  variant="outlined"
                  size="small"
                  type="email"
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  sx={{ mt: "4px", mb: 1 }}
                  InputLabelProps={{ shrink: false }}
                />
              </Box>

              <Box>
                <Typography
                  variant="contentSmall"
                  sx={{
                    color:
                      Boolean(touched.password && errors.password) === true
                        ? "red"
                        : "none",
                  }}
                >
                  {Boolean(touched.password && errors.password) === true
                    ? errors.password
                    : "Password"}
                </Typography>
                <AuthenticationTextField
                  autoComplete="off" // Avoids chrome warning
                  fullWidth
                  hiddenLabel
                  variant="outlined"
                  size="small"
                  type={showPassword ? "text" : "password"}
                  {...getFieldProps("password")}
                  error={Boolean(touched.password && errors.password)}
                  sx={{ mt: "4px", mb: 1 }}
                  InputLabelProps={{ shrink: false }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Iconify
                            icon={
                              showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  {RecaptchaOutlet}
                </Box>
              </Box>
              
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                    pr: 1,
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-end",
                    mt: 1,
                  }}
                >
                  <Typography variant="contentSmall">
                    {/* eslint-disable-next-line */}
                    <Link
                      component={RouterLink}
                      onClick={() => {
                        setStateStorage((previousState) => ({
                          ...previousState,
                          authState: "create",
                        }));

                        let newLocalSettingStorage = JSON.parse(
                          sessionStorage.getItem("myLocalSettingStorage")
                        );
                        if (newLocalSettingStorage === null) {
                          newLocalSettingStorage = stateStorageInit;
                        }

                        newLocalSettingStorage.authState = "create";
                        sessionStorage.setItem(
                          "myLocalSettingStorage",
                          JSON.stringify(newLocalSettingStorage)
                        );
                      }}
                      underline="none"
                      variant="contentSmallLink"
                      color="inherit"
                    >
                      Create new account!
                    </Link>
                  </Typography>
                </Box>

                <Box
                  sx={{
                    width: "50%",
                    pl: 1,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <Typography variant="contentSmall">
                    {/* eslint-disable-next-line */}
                    <Link
                      component={RouterLink}
                      onClick={() => {
                        setStateStorage((previousState) => ({
                          ...previousState,
                          authState: "password_forgot",
                        }));

                        let newLocalSettingStorage = JSON.parse(
                          sessionStorage.getItem("myLocalSettingStorage")
                        );
                        if (newLocalSettingStorage === null) {
                          newLocalSettingStorage = stateStorageInit;
                        }

                        newLocalSettingStorage.authState = "password_forgot";
                        sessionStorage.setItem(
                          "myLocalSettingStorage",
                          JSON.stringify(newLocalSettingStorage)
                        );
                      }}
                      underline="none"
                      variant="contentSmallLink"
                      color="inherit"
                    >
                      Forgot password?
                    </Link>
                  </Typography>
                </Box>
              </Box>

              <Box>
                <RequestMessage
                  wrapperRef={wrapperRef}
                  error={error}
                  warning={warning}
                  success={success}
                  returnMessage={returnMessage}
                  marginTopProp={
                    error === true || warning === true || success === true
                      ? 2
                      : 2
                  }
                  marginBottomProp={
                    error === true || warning === true || success === true
                      ? 2
                      : 0
                  }
                  fullWidth
                />
              </Box>
            </Stack>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <B1Small
                  fullWidth
                  size="large"
                  variant="contained"
                  onClick={() => {
                    if (caller === "distribution") {
                      setStateStorage((previousState) => ({
                        ...previousState,
                        authState: "login",
                        distributionCurrentStep: 0,
                      }));

                      let newLocalSettingStorage = JSON.parse(
                        sessionStorage.getItem("myLocalSettingStorage")
                      );
                      if (newLocalSettingStorage === null) {
                        newLocalSettingStorage = stateStorageInit;
                      }

                      newLocalSettingStorage.authState = "login";
                      newLocalSettingStorage.distributionCurrentStep = 0;

                      sessionStorage.setItem(
                        "myLocalSettingStorage",
                        JSON.stringify(newLocalSettingStorage)
                      );
                    } else if (caller === "distribution-tick-data") {
                      setStateStorage((previousState) => ({
                        ...previousState,
                        authState: "login",
                        distributionTickDataCurrentStep: 0,
                      }));

                      let newLocalSettingStorage = JSON.parse(
                        sessionStorage.getItem("myLocalSettingStorage")
                      );
                      if (newLocalSettingStorage === null) {
                        newLocalSettingStorage = stateStorageInit;
                      }

                      newLocalSettingStorage.authState = "login";
                      newLocalSettingStorage.distributionTickDataCurrentStep = 0;

                      sessionStorage.setItem(
                        "myLocalSettingStorage",
                        JSON.stringify(newLocalSettingStorage)
                      );
                    } else if (caller === "promotion") {
                      setStateStorage((previousState) => ({
                        ...previousState,
                        authState: "login",
                        promotionCurrentStep: 0,
                      }));

                      let newLocalSettingStorage = JSON.parse(
                        sessionStorage.getItem("myLocalSettingStorage")
                      );
                      if (newLocalSettingStorage === null) {
                        newLocalSettingStorage = stateStorageInit;
                      }

                      newLocalSettingStorage.authState = "login";
                      newLocalSettingStorage.promotionCurrentStep = 0;

                      sessionStorage.setItem(
                        "myLocalSettingStorage",
                        JSON.stringify(newLocalSettingStorage)
                      );
                    }
                  }}
                >
                  Back
                </B1Small>
              </Grid>
              <Grid item xs={6} md={6}>
                <AuthenticationButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  disabled={!stateStorage.recaptcha}
                >
                  Continue
                </AuthenticationButton>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      }
    />
  );
}

export function CreateAccount({ modeContent, caller, RecaptchaOutlet }) {
  CreateAccount.propTypes = {
    modeContent: PropTypes.any.isRequired,
    caller: PropTypes.any.isRequired,
    RecaptchaOutlet: PropTypes.any.isRequired,
  };

  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);
  const wrapperRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);

  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const [success, setSuccess] = useState(false);

  const [returnMessage, setReturnMessage] = useState("");

  const [formPpTac, setFormPpTac] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setError(false);
        setWarning(false);
        setSuccess(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside, {
      passive: false,
      capture: false,
    });
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef]);

  const ValidationSchema = Yup.object().shape({
    forename:
      caller === "promotion"
        ? Yup.string()
            .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9\s\-.]+$/)
            .max(40)
            .required()
        : Yup.string(),
    surname:
      caller === "promotion"
        ? Yup.string()
            .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9\s\-.]+$/)
            .max(40)
            .required()
        : Yup.string(),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string()
      .required("Please enter your new password")
      .min(8, "Password must be 8 characters long")
      .max(64, "Password must be shorter than 64 characters")
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .notOneOf(
        [Yup.ref("passwordOld"), null],
        "Old and new passwords must be different"
      ),
    passwordRepeat: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Password repeat is required"),
  });

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleCreate = (values, setSubmitting) => {
    setError(false);

    const myLocalSettingStorage = JSON.parse(
      sessionStorage.getItem("myLocalSettingStorage")
    );

    const sendData = {
      request_type: "checkout",
      route_info: "authentication",
      client: {
        mode: modeContent,
        forename: values.forename,
        surname: values.surname,
        email: values.email,
        password: values.password,
        legal_agreement: formPpTac ? "1" : "0",
        recaptcha_value: myLocalSettingStorage.recaptchaValue,
      },
    };

    backendRequest(process.env.REACT_APP_URL_API_CHECKOUT, sendData)
      .then((contentFromBackend) => {
        const keyOnly = Object.keys(contentFromBackend);
        const checkKey = keyOnly.includes("answer");

        if (checkKey === true) {
          setStateStorage((previousState) => ({
            ...previousState,
            authState: "approval_awaiting",
            userInformation: contentFromBackend,
          }));

          let newLocalSettingStorage = JSON.parse(
            sessionStorage.getItem("myLocalSettingStorage")
          );
          if (newLocalSettingStorage === null) {
            newLocalSettingStorage = stateStorageInit;
          }

          newLocalSettingStorage.authState = "approval_awaiting";
          newLocalSettingStorage.userInformation = contentFromBackend;
          sessionStorage.setItem(
            "myLocalSettingStorage",
            JSON.stringify(newLocalSettingStorage)
          );

          setError(false);

          setStateStorage((previousState) => ({
            ...previousState,
            performRescroll: true,
          }));
        } else {
          combineReturnMessage(contentFromBackend, setReturnMessage);
          setError(true);
        }
        setSubmitting(false);
      })
      .catch(() => {
        if (navigator.onLine === true) {
          setReturnMessage("Unknown error!");
          setError(true);
          setSubmitting(false);
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      forename: "",
      surname: "",
      email: "",
      password: "",
      passwordRepeat: "",
    },
    validationSchema: ValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      handleCreate(values, setSubmitting);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <CardOutlet
      variant="outlet"
      backgroundProp="#F2F2F2"
      title="Create New Account"
      subheader="Use the form below to establish a new account. Once the provided email address is confirmed, the checkout process will automatically proceed!"
      mainOutlet={
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={0} sx={{ mt: 1 }}>
              {caller === "promotion" ? (
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={6} lg={6}>
                      <Box>
                        <Typography
                          variant="contentSmall"
                          sx={{
                            color:
                              Boolean(touched.forename && errors.forename) ===
                              true
                                ? "red"
                                : "none",
                          }}
                        >
                          {Boolean(touched.forename && errors.forename) === true
                            ? "Forename is required"
                            : "Forename"}
                        </Typography>
                        <AuthenticationTextField
                          fullWidth
                          size="small"
                          type="text"
                          {...getFieldProps("forename")}
                          sx={{ mt: "4px", mb: 1 }}
                          error={Boolean(touched.forename && errors.forename)}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <Box>
                        <Typography
                          variant="contentSmall"
                          sx={{
                            color:
                              Boolean(touched.surname && errors.surname) ===
                              true
                                ? "red"
                                : "none",
                          }}
                        >
                          {Boolean(touched.surname && errors.surname) === true
                            ? "Surname is required"
                            : "Surname"}
                        </Typography>
                        <AuthenticationTextField
                          fullWidth
                          size="small"
                          type="text"
                          {...getFieldProps("surname")}
                          sx={{ mt: "4px", mb: 1 }}
                          error={Boolean(touched.surname && errors.surname)}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ) : null}

              <Box>
                <Typography
                  variant="contentSmall"
                  sx={{
                    color:
                      Boolean(touched.email && errors.email) === true
                        ? "red"
                        : "none",
                  }}
                >
                  {Boolean(touched.email && errors.email) === true
                    ? errors.email
                    : "Email address"}
                </Typography>

                <AuthenticationTextField
                  autoComplete="new-password"
                  fullWidth
                  hiddenLabel
                  variant="outlined"
                  size="small"
                  type="email"
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  sx={{ mt: "4px", mb: 1 }}
                  InputLabelProps={{ shrink: false }}
                />
              </Box>

              <Box>
                <Typography
                  variant="contentSmall"
                  sx={{
                    color:
                      Boolean(touched.password && errors.password) === true
                        ? "red"
                        : "none",
                  }}
                >
                  {Boolean(touched.password && errors.password) === true
                    ? errors.password
                    : "Password"}
                </Typography>
                <AuthenticationTextField
                  autoComplete="new-password" // Avoids chrome warning
                  fullWidth
                  hiddenLabel
                  variant="outlined"
                  size="small"
                  type={showPassword ? "text" : "password"}
                  {...getFieldProps("password")}
                  error={Boolean(touched.password && errors.password)}
                  sx={{ mt: "4px", mb: 1 }}
                  InputLabelProps={{ shrink: false }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Iconify
                            icon={
                              showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box>
                <Typography
                  variant="contentSmall"
                  sx={{
                    color:
                      Boolean(
                        touched.passwordRepeat && errors.passwordRepeat
                      ) === true
                        ? "red"
                        : "none",
                  }}
                >
                  {Boolean(touched.passwordRepeat && errors.passwordRepeat) ===
                  true
                    ? errors.passwordRepeat
                    : "Repeat password"}
                </Typography>

                <AuthenticationTextField
                  autoComplete="new-password" // Avoids chrome warning
                  fullWidth
                  hiddenLabel
                  variant="outlined"
                  size="small"
                  type={showPassword ? "text" : "password"}
                  {...getFieldProps("passwordRepeat")}
                  error={Boolean(
                    touched.passwordRepeat && errors.passwordRepeat
                  )}
                  sx={{ mt: "4px", mb: 1 }}
                  InputLabelProps={{ shrink: false }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Iconify
                            icon={
                              showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 1,
                    mb: 0,
                  }}
                >
                  <Typography variant="contentSmall">
                    {/* eslint-disable-next-line */}
                    <Link
                      component={RouterLink}
                      onClick={() => {
                        setStateStorage((previousState) => ({
                          ...previousState,
                          authState: "login",
                        }));

                        let newLocalSettingStorage = JSON.parse(
                          sessionStorage.getItem("myLocalSettingStorage")
                        );
                        if (newLocalSettingStorage === null) {
                          newLocalSettingStorage = stateStorageInit;
                        }

                        newLocalSettingStorage.authState = "login";
                        sessionStorage.setItem(
                          "myLocalSettingStorage",
                          JSON.stringify(newLocalSettingStorage)
                        );
                      }}
                      underline="none"
                      variant="contentSmallLink"
                      color="inherit"
                    >
                      Login with existing account
                    </Link>
                  </Typography>
                </Box>
              </Box>

              <Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  {RecaptchaOutlet}
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Box
                  sx={{
                    width: "80%",
                    pr: 1,
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-end",
                    mt: 1,
                  }}
                >
                  <Typography variant="contentSmall">
                    I confirm that I have read and agree with the
                    <Link
                      href={`${process.env.REACT_APP_URL_FRONTEND_PRIVACY_POLICY}`}
                      target="_blank"
                      underline="none"
                      variant="contentSmallLink"
                      color="inherit"
                    >
                      {` privacy policy `}
                    </Link>
                    and
                    <Link
                      href={`${process.env.REACT_APP_URL_FRONTEND_TERMS_AND_CONDITIONS}`}
                      target="_blank"
                      underline="none"
                      variant="contentSmallLink"
                      color="inherit"
                    >
                      {` terms and condition`}
                    </Link>
                    .
                  </Typography>
                </Box>

                <Box
                  sx={{
                    width: "20%",
                    pl: 1,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <ModalContentSwitch
                    checked={formPpTac}
                    onChange={(event) => {
                      setFormPpTac(event.target.checked);
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Box>
              </Box>

              <Box>
                <RequestMessage
                  wrapperRef={wrapperRef}
                  error={error}
                  warning={warning}
                  success={success}
                  returnMessage={returnMessage}
                  marginTopProp={
                    error === true || warning === true || success === true
                      ? 2
                      : 2
                  }
                  marginBottomProp={
                    error === true || warning === true || success === true
                      ? 2
                      : 0
                  }
                  fullWidth
                />
              </Box>
            </Stack>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <B1Small
                  fullWidth
                  size="large"
                  variant="contained"
                  onClick={() => {
                    if (caller === "distribution") {
                      setStateStorage((previousState) => ({
                        ...previousState,
                        authState: "login",
                        distributionCurrentStep: 0,
                      }));

                      let newLocalSettingStorage = JSON.parse(
                        sessionStorage.getItem("myLocalSettingStorage")
                      );
                      if (newLocalSettingStorage === null) {
                        newLocalSettingStorage = stateStorageInit;
                      }

                      newLocalSettingStorage.authState = "login";
                      newLocalSettingStorage.distributionCurrentStep = 0;

                      sessionStorage.setItem(
                        "myLocalSettingStorage",
                        JSON.stringify(newLocalSettingStorage)
                      );
                    } else if (caller === "distribution-tick-data") {
                      setStateStorage((previousState) => ({
                        ...previousState,
                        authState: "login",
                        distributionTickDataCurrentStep: 0,
                      }));

                      let newLocalSettingStorage = JSON.parse(
                        sessionStorage.getItem("myLocalSettingStorage")
                      );
                      if (newLocalSettingStorage === null) {
                        newLocalSettingStorage = stateStorageInit;
                      }

                      newLocalSettingStorage.authState = "login";
                      newLocalSettingStorage.distributionTickDataCurrentStep = 0;

                      sessionStorage.setItem(
                        "myLocalSettingStorage",
                        JSON.stringify(newLocalSettingStorage)
                      );
                    } else if (caller === "promotion") {
                      setStateStorage((previousState) => ({
                        ...previousState,
                        authState: "login",
                        promotionCurrentStep: 0,
                      }));

                      let newLocalSettingStorage = JSON.parse(
                        sessionStorage.getItem("myLocalSettingStorage")
                      );
                      if (newLocalSettingStorage === null) {
                        newLocalSettingStorage = stateStorageInit;
                      }

                      newLocalSettingStorage.authState = "login";
                      newLocalSettingStorage.promotionCurrentStep = 0;

                      sessionStorage.setItem(
                        "myLocalSettingStorage",
                        JSON.stringify(newLocalSettingStorage)
                      );
                    }
                  }}
                >
                  Back
                </B1Small>
              </Grid>

              <Grid item xs={6} md={6}>
                <AuthenticationButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  disabled={!stateStorage.recaptcha || !formPpTac}
                >
                  Continue
                </AuthenticationButton>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      }
    />
  );
}

export function PasswordForgot({ caller, RecaptchaOutlet }) {
  PasswordForgot.propTypes = {
    caller: PropTypes.any.isRequired,
    RecaptchaOutlet: PropTypes.any.isRequired,
  };

  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);
  const wrapperRef = useRef(null);

  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const [success, setSuccess] = useState(false);

  const [returnMessage, setReturnMessage] = useState("");

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setError(false);
        setWarning(false);
        setSuccess(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside, {
      passive: false,
      capture: false,
    });
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef]);

  const ValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
  });

  const handlePasswordReset = (emailContent, setSubmitting) => {
    setError(false);

    const sendData = {
      request_type: "checkout",
      route_info: "authentication",
      client: {
        mode: "password_reset",
        email: emailContent.email,
        return_url: `${process.env.REACT_APP_URL_FRONTEND_DASHBOARD}/new-password`,
        // return_url: "http://localhost:3000/new-password",
      },
    };

    backendRequest(process.env.REACT_APP_URL_API_CHECKOUT, sendData)
      .then((contentFromBackend) => {
        const keyOnly = Object.keys(contentFromBackend);
        const checkKey = keyOnly.includes("answer");

        if (checkKey === true) {
          if (contentFromBackend.answer.message === "success") {
            setReturnMessage("Reset email was sent successfully!");
            setSuccess(true);
            setError(false);
          } else {
            setReturnMessage("Please check email address again!");
            setError(true);
          }
          setSubmitting(false);
        } else {
          setReturnMessage("Please check email address again!");
          setError(true);
          setSubmitting(false);
        }
      })
      // eslint-disable-next-line no-console
      .catch(() => {
        if (navigator.onLine === true) {
          setReturnMessage("Unknown error!");
          setError(true);
          setSubmitting(false);
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: ValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      handlePasswordReset(values, setSubmitting);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <CardOutlet
      variant="outlet"
      backgroundProp="#F2F2F2"
      title="Reset Password"
      subheader="Use the form below to request a password reset email containing further instructions!"
      mainOutlet={
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={0} sx={{ mt: 1 }}>
              <Box>
                <Typography
                  variant="contentSmall"
                  sx={{
                    color:
                      Boolean(touched.email && errors.email) === true
                        ? "red"
                        : "none",
                  }}
                >
                  {Boolean(touched.email && errors.email) === true
                    ? errors.email
                    : "Email address"}
                </Typography>
                <AuthenticationTextField
                  fullWidth
                  hiddenLabel
                  variant="outlined"
                  size="small"
                  type="email"
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  sx={{ mt: "4px", mb: 1 }}
                  InputLabelProps={{ shrink: false }}
                />
              </Box>

              <Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 1,
                    mb: 0,
                  }}
                >
                  <Typography variant="contentSmall">
                    {/* eslint-disable-next-line */}
                    <Link
                      component={RouterLink}
                      onClick={() => {
                        setStateStorage((previousState) => ({
                          ...previousState,
                          authState: "login",
                        }));

                        let newLocalSettingStorage = JSON.parse(
                          sessionStorage.getItem("myLocalSettingStorage")
                        );
                        if (newLocalSettingStorage === null) {
                          newLocalSettingStorage = stateStorageInit;
                        }

                        newLocalSettingStorage.authState = "login";
                        sessionStorage.setItem(
                          "myLocalSettingStorage",
                          JSON.stringify(newLocalSettingStorage)
                        );
                      }}
                      underline="none"
                      variant="contentSmallLink"
                      color="inherit"
                    >
                      Login with existing account
                    </Link>
                  </Typography>
                </Box>
              </Box>

              <Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  {RecaptchaOutlet}
                </Box>
              </Box>

              <Box>
                <RequestMessage
                  wrapperRef={wrapperRef}
                  error={error}
                  warning={warning}
                  success={success}
                  returnMessage={returnMessage}
                  marginTopProp={
                    error === true || warning === true || success === true
                      ? 2
                      : 2
                  }
                  marginBottomProp={
                    error === true || warning === true || success === true
                      ? 2
                      : 0
                  }
                  fullWidth
                />
              </Box>
            </Stack>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <B1Small
                  fullWidth
                  size="large"
                  variant="contained"
                  onClick={() => {
                    if (caller === "distribution") {
                      setStateStorage((previousState) => ({
                        ...previousState,
                        authState: "login",
                        distributionCurrentStep: 0,
                      }));

                      let newLocalSettingStorage = JSON.parse(
                        sessionStorage.getItem("myLocalSettingStorage")
                      );
                      if (newLocalSettingStorage === null) {
                        newLocalSettingStorage = stateStorageInit;
                      }

                      newLocalSettingStorage.authState = "login";
                      newLocalSettingStorage.distributionCurrentStep = 0;

                      sessionStorage.setItem(
                        "myLocalSettingStorage",
                        JSON.stringify(newLocalSettingStorage)
                      );
                    } else if (caller === "distribution-tick-data") {
                      setStateStorage((previousState) => ({
                        ...previousState,
                        authState: "login",
                        distributionTickDataCurrentStep: 0,
                      }));

                      let newLocalSettingStorage = JSON.parse(
                        sessionStorage.getItem("myLocalSettingStorage")
                      );
                      if (newLocalSettingStorage === null) {
                        newLocalSettingStorage = stateStorageInit;
                      }

                      newLocalSettingStorage.authState = "login";
                      newLocalSettingStorage.distributionTickDataCurrentStep = 0;

                      sessionStorage.setItem(
                        "myLocalSettingStorage",
                        JSON.stringify(newLocalSettingStorage)
                      );
                    } else if (caller === "promotion") {
                      setStateStorage((previousState) => ({
                        ...previousState,
                        authState: "login",
                        promotionCurrentStep: 0,
                      }));

                      let newLocalSettingStorage = JSON.parse(
                        sessionStorage.getItem("myLocalSettingStorage")
                      );
                      if (newLocalSettingStorage === null) {
                        newLocalSettingStorage = stateStorageInit;
                      }

                      newLocalSettingStorage.authState = "login";
                      newLocalSettingStorage.promotionCurrentStep = 0;

                      sessionStorage.setItem(
                        "myLocalSettingStorage",
                        JSON.stringify(newLocalSettingStorage)
                      );
                    }
                  }}
                >
                  Back
                </B1Small>
              </Grid>
              <Grid item xs={6} md={6}>
                <AuthenticationButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  disabled={!stateStorage.recaptcha}
                >
                  Request email
                </AuthenticationButton>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      }
    />
  );
}

export function ApprovalAwaiting({ caller }) {
  ApprovalAwaiting.propTypes = {
    caller: PropTypes.any.isRequired,
  };

  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);
  const [awaitingCount, setAwaitingCount] = useState(0);

  const wrapperRef = useRef(null);

  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const [success, setSuccess] = useState(false);

  const [returnMessage, setReturnMessage] = useState("");

  const myLocalSettingStorage = JSON.parse(
    sessionStorage.getItem("myLocalSettingStorage")
  );

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setError(false);
        setWarning(false);
        setSuccess(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside, {
      passive: false,
      capture: false,
    });
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef]);

  const handleAwaiting = () => {
    const sendData = {
      request_type: "checkout",
      route_info: "authentication",
      client: {
        mode: "is_email_approved",
        dashboard_access_token:
          myLocalSettingStorage.userInformation.answer.dashboard_access_token,
      },
    };
    backendRequest(process.env.REACT_APP_URL_API_CHECKOUT, sendData).then(
      (contentFromBackend) => {
        const keyOnly = Object.keys(contentFromBackend);
        const checkKey = keyOnly.includes("answer");

        if (checkKey === true) {
          if (contentFromBackend.answer.is_email_approved === "true") {
            if (caller === "distribution") {
              setStateStorage((previousState) => ({
                ...previousState,
                distributionCurrentStep: 2,
                alreadyApprovedPpTac: true,
              }));

              let newLocalSettingStorage = JSON.parse(
                sessionStorage.getItem("myLocalSettingStorage")
              );
              if (newLocalSettingStorage === null) {
                newLocalSettingStorage = stateStorageInit;
              }

              newLocalSettingStorage.distributionCurrentStep = 2;
              newLocalSettingStorage.alreadyApprovedPpTac = true;

              sessionStorage.setItem(
                "myLocalSettingStorage",
                JSON.stringify(newLocalSettingStorage)
              );
            } else if (caller === "distribution-tick-data") {
              setStateStorage((previousState) => ({
                ...previousState,
                distributionTickDataCurrentStep: 2,
                alreadyApprovedPpTac: true,
              }));

              let newLocalSettingStorage = JSON.parse(
                sessionStorage.getItem("myLocalSettingStorage")
              );
              if (newLocalSettingStorage === null) {
                newLocalSettingStorage = stateStorageInit;
              }

              newLocalSettingStorage.distributionTickDataCurrentStep = 2;
              newLocalSettingStorage.alreadyApprovedPpTac = true;

              sessionStorage.setItem(
                "myLocalSettingStorage",
                JSON.stringify(newLocalSettingStorage)
              );
            } else if (caller === "promotion") {
              setStateStorage((previousState) => ({
                ...previousState,
                promotionCurrentStep: 2,
                alreadyApprovedPpTac: true,
              }));

              let newLocalSettingStorage = JSON.parse(
                sessionStorage.getItem("myLocalSettingStorage")
              );
              if (newLocalSettingStorage === null) {
                newLocalSettingStorage = stateStorageInit;
              }

              newLocalSettingStorage.promotionCurrentStep = 2;
              newLocalSettingStorage.alreadyApprovedPpTac = true;

              sessionStorage.setItem(
                "myLocalSettingStorage",
                JSON.stringify(newLocalSettingStorage)
              );
            }
          }
        }
      }
    );
  };

  useEffect(() => {
    setTimeout(() => {
      setAwaitingCount(awaitingCount + 1);
      handleAwaiting();
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [awaitingCount]);

  const handleResendApprovalEmail = (emailParam) => {
    if (navigator.onLine === true) {
      setError(false);
      setWarning(false);
      setSuccess(false);

      const sendData = {
        request_type: "checkout",
        route_info: "authentication",
        client: {
          mode: "resend_approval_email_alt",
          email: emailParam,
        },
      };
      backendRequest(process.env.REACT_APP_URL_API_CHECKOUT, sendData)
        .then((contentFromBackend) => {
          const keyOnly = Object.keys(contentFromBackend);
          const checkKey = keyOnly.includes("answer");

          if (checkKey === true) {
            setReturnMessage("Approval email resend, please check your inbox!");
            setSuccess(true);
          } else {
            combineReturnMessage(contentFromBackend, setReturnMessage);
            setError(true);
          }
        })
        .catch(() => {
          if (navigator.onLine === true) {
            setReturnMessage("Unknown error!");
            setError(true);
          }
        });
    }
  };

  return (
    <CardOutlet
      variant="outlet"
      backgroundProp="#F2F2F2"
      title="Confirm Email Address"
      subheader={
        <>
          We have sent an email confirmation to the address below. Please check
          your inbox and click the link in the email to proceed. Some providers
          may categorize emails like this as spam, therefore inspect your spam
          folder as well. Click {/* eslint-disable */}
          <Typography variant="contentMiddleBold">
            <Link
              component={RouterLink}
              onClick={() => {
                handleResendApprovalEmail(
                  myLocalSettingStorage.userInformation.account_settings
                    .config_data.config_data_client.email
                );

                setStateStorage((previousState) => ({
                  ...previousState,
                  performRescroll: true,
                }));
              }}
              underline="none"
              variant="contentMiddleLink"
              color="inherit"
            >
              here
            </Link>
          </Typography>
          {/* eslint-enable */} to resend the confirmation email!
        </>
      }
      mainOutlet={
        <>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              item
              xs={12}
              sm={10}
              md={8}
              lg={6}
              sx={{
                pt: 1,
                pb: 2,
                px: 2,
                mt: 3,
                mb: 0,
                background: "white",
                borderRadius: "0px",
                boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5);",
              }}
            >
              <Stack sx={{ width: "100%" }}>
                <Box
                  sx={{
                    p: "5px 0px 0px 0px",
                    borderBottom: "1px solid #C8C8C8",
                    borderColor: "#C8C8C8",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "40%",
                      pr: 1,
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Typography variant="contentBig" align="left">
                      User name
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      width: "60%",
                      pl: 1,
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Typography
                      variant="contentBig"
                      align="right"
                      sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
                    >
                      {
                        myLocalSettingStorage.userInformation.account_settings
                          .config_data.config_data_client.email
                      }
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    p: "5px 0px 0px 0px",
                    borderBottom: "1px solid #C8C8C8",
                    borderColor: "#C8C8C8",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "40%",
                      pr: 1,
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Typography variant="contentBig" align="left">
                      User ID (UID)
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      width: "60%",
                      pl: 1,
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Typography
                      variant="contentBig"
                      align="right"
                      sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
                    >
                      {
                        myLocalSettingStorage.userInformation.account_settings
                          .config_data.config_data_client.dashboard_id
                      }
                    </Typography>
                  </Box>
                </Box>
              </Stack>
            </Grid>
          </Grid>

          <Box>
            <RequestMessage
              wrapperRef={wrapperRef}
              error={error}
              warning={warning}
              success={success}
              returnMessage={returnMessage}
              marginTopProp={
                error === true || warning === true || success === true ? 4 : 4
              }
              marginBottomProp={
                error === true || warning === true || success === true ? 2 : 0
              }
              fullWidth
            />
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <B1Small
                fullWidth
                size="large"
                variant="contained"
                onClick={() => {
                  if (caller === "distribution") {
                    setStateStorage((previousState) => ({
                      ...previousState,
                      authState: "login",
                      distributionCurrentStep: 0,
                    }));

                    let newLocalSettingStorage = JSON.parse(
                      sessionStorage.getItem("myLocalSettingStorage")
                    );
                    if (newLocalSettingStorage === null) {
                      newLocalSettingStorage = stateStorageInit;
                    }

                    newLocalSettingStorage.authState = "login";
                    newLocalSettingStorage.distributionCurrentStep = 0;

                    sessionStorage.setItem(
                      "myLocalSettingStorage",
                      JSON.stringify(newLocalSettingStorage)
                    );
                  } else if (caller === "distribution-tick-data") {
                    setStateStorage((previousState) => ({
                      ...previousState,
                      authState: "login",
                      distributionTickDataCurrentStep: 0,
                    }));

                    let newLocalSettingStorage = JSON.parse(
                      sessionStorage.getItem("myLocalSettingStorage")
                    );
                    if (newLocalSettingStorage === null) {
                      newLocalSettingStorage = stateStorageInit;
                    }

                    newLocalSettingStorage.authState = "login";
                    newLocalSettingStorage.distributionTickDataCurrentStep = 0;

                    sessionStorage.setItem(
                      "myLocalSettingStorage",
                      JSON.stringify(newLocalSettingStorage)
                    );
                  } else if (caller === "promotion") {
                    setStateStorage((previousState) => ({
                      ...previousState,
                      authState: "login",
                      promotionCurrentStep: 0,
                    }));

                    let newLocalSettingStorage = JSON.parse(
                      sessionStorage.getItem("myLocalSettingStorage")
                    );
                    if (newLocalSettingStorage === null) {
                      newLocalSettingStorage = stateStorageInit;
                    }

                    newLocalSettingStorage.authState = "login";
                    newLocalSettingStorage.promotionCurrentStep = 0;

                    sessionStorage.setItem(
                      "myLocalSettingStorage",
                      JSON.stringify(newLocalSettingStorage)
                    );
                  }
                }}
              >
                Back
              </B1Small>
            </Grid>
            <Grid item xs={6} md={6}>
              <AuthenticationButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                disabled={Boolean(true)}
              >
                Confirmation required!
              </AuthenticationButton>
            </Grid>
          </Grid>
        </>
      }
    />
  );
}
