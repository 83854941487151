// react and js
import PropTypes from "prop-types";
import { React, useState, useRef, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

// mui
import {
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Link,
  Box,
  Stack,
} from "@mui/material";

// components
import ModalDialog from "../../../components/ModalDialog";
import Iconify from "../../../components/Iconify";

// hooks

// layouts

// pages

// theme
import { B1Small } from "../../../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function numberWithCommas(x) {
  return parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function PromotionPartnerLimitations({ promotionPartner }) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  PromotionPartnerLimitations.propTypes = {
    promotionPartner: PropTypes.any.isRequired,
  };

  const myLocalSettingStorage = JSON.parse(
    sessionStorage.getItem("myLocalSettingStorage")
  );

  let referenceTradingCurrency = "";
  if (myLocalSettingStorage !== null) {
    if (myLocalSettingStorage.retailerInformation !== "") {
      if (
        myLocalSettingStorage.retailerInformation.reference_trading_currency ===
        "EUR"
      ) {
        referenceTradingCurrency = "€";
      }
    }
  }

  const shownLimitationsObject = {};
  promotionPartner.data.forEach((promotionLimitation) => {
    if (shownLimitationsObject[promotionLimitation[0]] !== undefined) {
      if (
        Number(
          shownLimitationsObject[promotionLimitation[0]][2].replace("_", ".")
        ) < Number(promotionLimitation[2].replace("_", "."))
      ) {
        shownLimitationsObject[promotionLimitation[0]] = promotionLimitation;
      }
    } else {
      shownLimitationsObject[promotionLimitation[0]] = promotionLimitation;
    }
  });

  const keys = Object.keys(shownLimitationsObject);
  const shownLimitations = [];
  keys.forEach((shownLimitationObjectKey) => {
    shownLimitations.push(shownLimitationsObject[shownLimitationObjectKey]);
  });

  // eslint-disable-next-line
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Typography variant="contentMiddleBold">
        {/* eslint-disable-next-line */}
        <Link
          component={RouterLink}
          onClick={handleClickOpen("paper")}
          underline="none"
          variant="contentMiddleLink"
          color="inherit"
        >
          minimum trading account equity
        </Link>
      </Typography>

      <ModalDialog
        open={open}
        handleClose={handleClose}
        scroll={scroll}
        outlet={
          <>
            <DialogTitle
              id="dialog-title"
              sx={{
                background: "#F2F2F2",
                textTransform: "none",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
                py: 1,
                pr: 1,
              }}
            >
              <Typography variant="contentBig">
                Application limitations for {promotionPartner.broker_name}
              </Typography>

              <IconButton
                onClick={handleClickClose}
                sx={{ borderRadius: "0px" }}
              >
                <Iconify
                  icon="eva:close-outline"
                  minWidth={25}
                  minHeight={25}
                  color="#474A4C"
                />
              </IconButton>
            </DialogTitle>

            <DialogContent
              dividers
              sx={{
                background: "#F2F2F2",
                borderTop: "1px solid #C8C8C8",
                borderBottom: "1px solid #C8C8C8",
              }}
            >
              <Box sx={{ mb: 2 }}>
                <Typography variant="contentMiddle">
                We collaborate with some promotion partners, which we have verified to be reliable and have passed our technical assessment regarding order execution time, spread, and server reliability.
                  <br />
                  <br />
                  This offer is part of the partner's affiliate program and requires minimum trading account equity to qualify. Therefore, please note that the following minimum account prerequisites must be met to initiate the applications:
                </Typography>
              </Box>

              <Box>
                {shownLimitations.map((row, index) => (
                  <Stack
                    key={index}
                    spacing="3px"
                    sx={{
                      background: "white",
                      mb: 1,
                      px: "10px",
                      py: "5px",
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Box sx={{ width: "40%", pr: 1 }}>
                        <Typography variant="contentSmallBold">
                          Application
                        </Typography>
                      </Box>
                      <Box sx={{ width: "60%", pl: 1 }}>
                        <Typography variant="contentSmall">{row[0]}</Typography>
                      </Box>
                    </Box>

                    {row[3] !== null ? (
                      <Box sx={{ display: "flex" }}>
                        <Box sx={{ width: "40%", pr: 1 }}>
                          <Typography variant="contentSmallBold">
                            Min. equity<sup>*</sup>
                          </Typography>
                        </Box>
                        <Box sx={{ width: "60%", pl: 1 }}>
                          <Typography variant="contentSmall">
                            {`${numberWithCommas(
                              row[3]
                            )} ${referenceTradingCurrency}`}
                          </Typography>
                        </Box>
                      </Box>
                    ) : null}

                    {row[4] !== null ? (
                      <Box sx={{ display: "flex" }}>
                        <Box sx={{ width: "40%", pr: 1 }}>
                          <Typography variant="contentSmallBold">
                            Max. equity<sup>*</sup>
                          </Typography>
                        </Box>
                        <Box sx={{ width: "60%", pl: 1 }}>
                          <Typography variant="contentSmall">
                            {`${numberWithCommas(
                              row[4]
                            )} ${referenceTradingCurrency}`}
                          </Typography>
                        </Box>
                      </Box>
                    ) : null}

                    {row[5] !== null ? (
                      <Box sx={{ display: "flex" }}>
                        <Box sx={{ width: "40%", pr: 1 }}>
                          <Typography variant="contentSmallBold">
                            Min. leverage
                          </Typography>
                        </Box>
                        <Box sx={{ width: "60%", pl: 1 }}>
                          <Typography variant="contentSmall">
                            {row[5]}
                          </Typography>
                        </Box>
                      </Box>
                    ) : null}

                    {row[6] !== null ? (
                      <Box sx={{ display: "flex" }}>
                        <Box sx={{ width: "40%", pr: 1 }}>
                          <Typography variant="contentSmallBold">
                            Max. leverage
                          </Typography>
                        </Box>
                        <Box sx={{ width: "60%", pl: 1 }}>
                          <Typography variant="contentSmall">
                            {row[6]}
                          </Typography>
                        </Box>
                      </Box>
                    ) : null}
                  </Stack>
                ))}
              </Box>

              <Box
                sx={{
                  mt: 1,
                  mb: 0,
                }}
              >
                <Typography variant="contentTiny">
                  <sup>*</sup> If the trading currency differs, the equivalent amount in your trading currency will be used instead.
                </Typography>
              </Box>
            </DialogContent>

            <DialogActions
              sx={{
                background: "#F2F2F2",
                p: 0,
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <B1Small
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={handleClose}
              >
                Close
              </B1Small>
            </DialogActions>
          </>
        }
      />
    </>
  );
}
