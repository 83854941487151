// react and js
import PropTypes from "prop-types";
import { React, useState } from "react";

// mui
import {
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
} from "@mui/material";

// components
import ModalDialog from "../../components/ModalDialog";
import Iconify from "../../components/Iconify";

// hooks

// layouts

// pages

// theme
import { B1Small } from "../../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function DialoguePspWindowOpen({
  handleClickClose,
  handlePspUrlReopen,
}) {
  DialoguePspWindowOpen.propTypes = {
    handleClickClose: PropTypes.any.isRequired,
    handlePspUrlReopen: PropTypes.any.isRequired,
  };
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  const [scroll] = useState("paper");

  return (
    <ModalDialog
      open={Boolean(true)}
      scroll={scroll}
      outlet={
        <>
          <DialogTitle
            id="dialog-title"
            sx={{
              background: "#F2F2F2",
              textTransform: "none",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              alignContent: "center",
              py: 1,
              pr: 1,
            }}
          >
            <Typography variant="contentBig">
              Payment service provider
            </Typography>

            <IconButton onClick={handleClickClose} sx={{ borderRadius: "0px" }}>
              <Iconify
                icon="eva:close-outline"
                minWidth={25}
                minHeight={25}
                color="#474A4C"
              />
            </IconButton>
          </DialogTitle>

          <DialogContent
            dividers
            sx={{
              background: "#F2F2F2",
              borderTop: "1px solid #C8C8C8",
              borderBottom: "1px solid #C8C8C8",
            }}
          >
            <Box sx={{ mb: 0 }}>
              <Typography variant="contentMiddle">
                Please follow the instructions from our payment service
                provider. After a successful checkout process, you immediately
                can start to use the application.
              </Typography>
            </Box>
          </DialogContent>

          <DialogActions
            sx={{
              background: "#F2F2F2",
              p: 0,
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <B1Small
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={handlePspUrlReopen}
            >
              Reopen
            </B1Small>
          </DialogActions>
        </>
      }
    />
  );
}
