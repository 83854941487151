// react and js
import { React, useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

// mui
import {
  Step,
  StepLabel,
  Stepper,
  Box,
  Grid,
  Card,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// components
import {
  CustomStepperConnector,
  CustomStepperStepIcon,
  StepSx,
} from "../../components/Stepper";
import backendRequest from "../../components/BackendRequest";
import DialoguePromotionUnavailable from "./DialoguePromotionUnavailable";

// hooks
import defines from "../../hooks/defines";
import appContext from "../../hooks/appContext";
import { stateStorageInit } from "../../hooks/stateStorageInit";

// layouts

// pages

// theme

// utils

// widgets
import PromotionPartner from "./checkout/PromotionPartner";
import ClientAccount from "./checkout/ClientAccount";
import TradingAccounts from "./checkout/TradingAccounts";
import GettingStarted from "./checkout/GettingStarted";

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  const { stateStorage, setStateStorage } = useContext(appContext);
  const myLocalSettingStorage = JSON.parse(
    sessionStorage.getItem("myLocalSettingStorage")
  );
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);

  const [searchParams] = useSearchParams();
  const brokerId = searchParams.get("broker_id");
  const emailParam = searchParams.get("email");
  const dashboardIdParam = searchParams.get("dashboard_id");
  const dashboardAccessTokenParam = searchParams.get("dashboard_access_token");
  const dashboardAccessTokenExpireParam = searchParams.get(
    "dashboard_access_token_expire"
  );

  const [error, setError] = useState(false);
  const [, setWarning] = useState(false);
  const [, setSuccess] = useState(false);

  const [loading, setLoading] = useState(navigator.onLine);
  const [content, setContent] = useState();
  const [, setReturnMessage] = useState("");

  const [regularRefreshIntervalCounter, setRegularRefreshIntervalCounter] =
    useState(0);

  const [scrollCounter, setScrollCounter] = useState(0);
  const [scrollStepId, setScrollStepId] = useState("promotion-partner-scroll");

  const recaptchaRef = useRef();

  // Example: http://localhost:3000/promotion?broker_id=2
  // Example: http://localhost:3000/promotion?broker_id=3&email=R|request@free-expert-advisor.com&dashboard_id=R|9004047&dashboard_access_token=a6f76a1d-a696-47f1-b9fc-62d3de0407e7&dashboard_access_token_expire=1686751762 (via my dashboard)

  // http://localhost:3000/promotion?broker_id=4&email=R|support@fea-trading.com&dashboard_id=R|9004051&dashboard_access_token=9aa73cb6-5808-409f-ad63-a9e7c273db27&dashboard_access_token_expire=1687052597

  // ######################################################
  // Main backend request
  // ######################################################
  function MainRequest(periodic) {
    if (
      sessionStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      if (periodic === false) {
        setError(false);
        setWarning(false);
        setSuccess(false);
        setReturnMessage("");
        setLoading(true);
      }
    }

    const sendData = {
      request_type: "checkout",
      route_info: "portfolio_request_promotion",
    };

    backendRequest(process.env.REACT_APP_URL_API_CHECKOUT, sendData)
      .then((contentFromBackend) => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
          // eslint-disable-next-line
          console.log(
            "Session data (Promoter name - Broker - Promoter link ID - Sel sum - Sel Rand - Sel start selected - Sel end selected):"
          );
          Object.keys(contentFromBackend.promotion_partners).forEach((key) => {
            // eslint-disable-next-line
            console.log(
              contentFromBackend.promotion_partners[key].promoter_name,
              " - ",
              key,
              " - ",
              contentFromBackend.promotion_partners[key].promoter_link_id,
              " - ",
              contentFromBackend.promotion_partners[key].sel_sum,
              " - ",
              contentFromBackend.promotion_partners[key].sel_rand,
              " - ",
              contentFromBackend.promotion_partners[key].sel_start_selected,
              " - ",
              contentFromBackend.promotion_partners[key].sel_end_selected,
              " - ",
              contentFromBackend.promotion_partners[key].link_rotator
            );
          });
        }

        setError(false);
        setLoading(false);
        setContent(contentFromBackend);

        let IsInList = false;
        let LocalizationLinePredicted = null;
        //
        contentFromBackend.localization.t_localization.forEach((val) => {
          if (
            contentFromBackend.localization.request_location
              .client_ip_country_code === val.country_code
          ) {
            LocalizationLinePredicted = val;
            IsInList = true;
          }
        });
        //
        if (IsInList === false) {
          LocalizationLinePredicted =
            contentFromBackend.localization.t_localization[0];
        }

        setStateStorage((previousState) => ({
          ...previousState,
          retailerInformation: contentFromBackend.retailer,
          localization: contentFromBackend.localization,
          localizationLine: LocalizationLinePredicted,
          localizationLineSelected: LocalizationLinePredicted,
        }));

        setStateStorage((previousState) => ({
          ...previousState,
          retailerInformation: contentFromBackend.retailer,
        }));

        let newLocalSettingStorage = JSON.parse(
          sessionStorage.getItem("myLocalSettingStorage")
        );
        if (newLocalSettingStorage === null) {
          newLocalSettingStorage = stateStorageInit;
        }

        newLocalSettingStorage.retailerInformation =
          contentFromBackend.retailer;
        newLocalSettingStorage.localization = contentFromBackend.localization;
        newLocalSettingStorage.localizationLine = LocalizationLinePredicted;
        newLocalSettingStorage.localizationLineSelected =
          LocalizationLinePredicted;
        sessionStorage.setItem(
          "myLocalSettingStorage",
          JSON.stringify(newLocalSettingStorage)
        );

        if (brokerId === null) {
          if (myLocalSettingStorage !== null) {
            const lastActivity = JSON.parse(
              sessionStorage.getItem("lastActivity")
            );
            const TimestampSecond = Math.round(Date.now() / 1000);
            const lastActivityDiff = TimestampSecond - lastActivity;

            /*
            if (myLocalSettingStorage.promotionCurrentStep !== 3 && lastActivityDiff < 18) 
            {
            */
            if (periodic === false) {
              if (
                myLocalSettingStorage.promotionCurrentStep !== 3 &&
                lastActivityDiff < 3540
              ) {
                const myLocalSettingStorageInner = JSON.parse(
                  sessionStorage.getItem("myLocalSettingStorage")
                );

                setStateStorage(myLocalSettingStorageInner);
              } else {
                setStateStorage(stateStorageInit);

                sessionStorage.setItem(
                  "myLocalSettingStorage",
                  JSON.stringify(stateStorageInit)
                );
                sessionStorage.removeItem("myLocalSettingStorage");

                setStateStorage((previousState) => ({
                  ...previousState,
                  retailerInformation: contentFromBackend.retailer,
                  localization: contentFromBackend.localization,
                  localizationLine: LocalizationLinePredicted,
                  localizationLineSelected: LocalizationLinePredicted,
                }));

                let newLocalSettingStorageInner = JSON.parse(
                  sessionStorage.getItem("myLocalSettingStorage")
                );
                if (newLocalSettingStorageInner === null) {
                  newLocalSettingStorageInner = stateStorageInit;
                }
                newLocalSettingStorageInner.retailerInformation =
                  contentFromBackend.retailer;
                newLocalSettingStorageInner.localization =
                  contentFromBackend.localization;
                newLocalSettingStorageInner.localizationLine =
                  LocalizationLinePredicted;
                newLocalSettingStorageInner.localizationLineSelected =
                  LocalizationLinePredicted;
                sessionStorage.setItem(
                  "myLocalSettingStorage",
                  JSON.stringify(newLocalSettingStorageInner)
                );
              }
            }
          }
        }

        if (brokerId !== null) {
          const keyOnly = Object.keys(contentFromBackend.promotion_partners);

          for (let i = 0; i < keyOnly.length; i += 1) {
            const keyOnly2 = Object.keys(
              contentFromBackend.promotion_partners[keyOnly[i]]
            );

            for (let x = 0; x < keyOnly2.length; x += 1) {
              if (
                brokerId ===
                contentFromBackend.promotion_partners[keyOnly[i]].broker_id
              ) {
                if (
                  emailParam === null ||
                  dashboardIdParam === null ||
                  dashboardAccessTokenParam === null ||
                  dashboardAccessTokenExpireParam === null
                ) {
                  setStateStorage((previousState) => ({
                    ...previousState,
                    promotionCurrentStep: 1,
                    promotionPromotionPartner:
                      contentFromBackend.promotion_partners[keyOnly[i]],
                  }));

                  let myLocalSettingStorageInner = JSON.parse(
                    sessionStorage.getItem("myLocalSettingStorage")
                  );
                  if (myLocalSettingStorageInner === null) {
                    myLocalSettingStorageInner = stateStorageInit;
                  }

                  myLocalSettingStorageInner.promotionPromotionPartner =
                    contentFromBackend.promotion_partners[keyOnly[i]];
                  myLocalSettingStorageInner.promotionCurrentStep = 1;
                  sessionStorage.setItem(
                    "myLocalSettingStorage",
                    JSON.stringify(myLocalSettingStorageInner)
                  );
                } else {
                  const newUserInformation = {
                    answer: {
                      dashboard_access_token: dashboardAccessTokenParam,
                      dashboard_access_token_expire:
                        dashboardAccessTokenExpireParam,
                    },
                    account_settings: {
                      config_data: {
                        config_data_client: {
                          email: emailParam,
                          dashboard_id: dashboardIdParam,
                        },
                      },
                    },
                  };

                  setStateStorage((previousState) => ({
                    ...previousState,
                    userInformation: newUserInformation,
                    promotionCurrentStep: 2,
                    promotionPromotionPartner:
                      contentFromBackend.promotion_partners[keyOnly[i]],
                  }));

                  let myLocalSettingStorageInner = JSON.parse(
                    sessionStorage.getItem("myLocalSettingStorage")
                  );
                  if (myLocalSettingStorageInner === null) {
                    myLocalSettingStorageInner = stateStorageInit;
                  }

                  myLocalSettingStorageInner.userInformation =
                    newUserInformation;
                  myLocalSettingStorageInner.promotionPromotionPartner =
                    contentFromBackend.promotion_partners[keyOnly[i]];
                  myLocalSettingStorageInner.promotionCurrentStep = 2;
                  sessionStorage.setItem(
                    "myLocalSettingStorage",
                    JSON.stringify(myLocalSettingStorageInner)
                  );
                }
                navigate("/promotion");
              }
            }
          }
        }
      })
      // eslint-disable-next-line no-console
      .catch(() => {
        if (navigator.onLine === true) {
          setError(true);
          setLoading(false);
          setReturnMessage("Unknown error!");
        }
      });
  }

  // ######################################################
  // Inital and triggered refreshs
  // ######################################################
  useEffect(() => {
    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ######################################################
  // Regular refreshs
  // ######################################################
  useEffect(() => {
    const timerID = setTimeout(() => {
      setRegularRefreshIntervalCounter(regularRefreshIntervalCounter + 1);
    }, stateStorage.widgetUpdateIntervalPromotion);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  /*
  useEffect(() => {
    if (stateStorage.promotionCurrentStep === 0) {
      MainRequest(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);
  */

  // ######################################################
  // Auto scroll handling
  // ######################################################
  function scrollToStepWithId(stepId) {
    const autoScrollContainer = document.querySelector(
      "#auto-scroll-container"
    );
    const element = document.getElementById(stepId);
    const elementPosition = element.getBoundingClientRect().top;

    let offsetPosition = 0;
    if (isDesktop) {
      offsetPosition =
        elementPosition +
        autoScrollContainer.scrollTop -
        defines.headerHeightDesktop;
    } else {
      offsetPosition =
        elementPosition +
        autoScrollContainer.scrollTop -
        defines.headerHeightMobile;
    }

    if (autoScrollContainer !== null) {
      autoScrollContainer.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      scrollToStepWithId(scrollStepId);
    }, 50);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollCounter]);

  useEffect(() => {
    if (stateStorage.promotionCurrentStep === 0 && activeStep !== 0) {
      setActiveStep(0);
      if (loading === false) {
        setScrollStepId("promotion-partner-scroll");
        setScrollCounter(scrollCounter + 1);
      }
    }

    if (stateStorage.promotionCurrentStep === 1 && activeStep !== 1) {
      setActiveStep(1);
      if (loading === false) {
        setScrollStepId("client-account-scroll");
        setScrollCounter(scrollCounter + 1);
      }
    }

    if (stateStorage.promotionCurrentStep === 2 && activeStep !== 2) {
      setActiveStep(2);
      if (loading === false) {
        setScrollStepId("trading-accounts-scroll");
        setScrollCounter(scrollCounter + 1);
      }
    }

    if (stateStorage.promotionCurrentStep === 3 && activeStep !== 3) {
      setActiveStep(3);
      if (loading === false) {
        setScrollStepId("getting-started-scroll");
        setScrollCounter(scrollCounter + 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage]);

  useEffect(() => {
    if (!stateStorage.performRescroll) return;

    setStateStorage((previousState) => ({
      ...previousState,
      performRescroll: false,
    }));

    if (
      stateStorage.promotionCurrentStep === 0 &&
      stateStorage.performRescroll === true
    ) {
      if (loading === false) {
        setScrollStepId("promotion-partner-scroll");
        setScrollCounter(scrollCounter + 1);
      }
    }

    if (
      stateStorage.promotionCurrentStep === 1 &&
      stateStorage.performRescroll === true
    ) {
      if (loading === false) {
        setScrollStepId("client-account-scroll");
        setScrollCounter(scrollCounter + 1);
      }
    }

    if (
      stateStorage.promotionCurrentStep === 2 &&
      stateStorage.performRescroll === true
    ) {
      if (loading === false) {
        setScrollStepId("trading-accounts-scroll");
        setScrollCounter(scrollCounter + 1);
      }
    }

    if (
      stateStorage.promotionCurrentStep === 3 &&
      stateStorage.performRescroll === true
    ) {
      if (loading === false) {
        setScrollStepId("getting-started-scroll");
        setScrollCounter(scrollCounter + 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage.performRescroll]);

  useEffect(() => {
    if (stateStorage.promotionCurrentStep === 0) {
      setScrollStepId("promotion-partner-scroll");
      setScrollCounter(scrollCounter + 1);
    }

    if (stateStorage.promotionCurrentStep === 1) {
      setScrollStepId("client-account-scroll");
      setScrollCounter(scrollCounter + 1);
    }

    if (stateStorage.promotionCurrentStep === 2) {
      setScrollStepId("trading-accounts-scroll");
      setScrollCounter(scrollCounter + 1);
    }

    if (stateStorage.promotionCurrentStep === 3) {
      setScrollStepId("getting-started-scroll");
      setScrollCounter(scrollCounter + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  // ######################################################
  // Error step handling
  // ######################################################
  useEffect(() => {
    if (myLocalSettingStorage !== null) {
      if (
        myLocalSettingStorage.promotionErrorStep >
        myLocalSettingStorage.promotionCurrentStep
      ) {
        setStateStorage((previousState) => ({
          ...previousState,
          promotionErrorStep: -1,
          promotionErrorStepCode: "none",
        }));

        let newLocalSettingStorage = JSON.parse(
          sessionStorage.getItem("myLocalSettingStorage")
        );
        if (newLocalSettingStorage === null) {
          newLocalSettingStorage = stateStorageInit;
        }

        newLocalSettingStorage.promotionErrorStep = -1;
        newLocalSettingStorage.promotionErrorStepCode = "none";
        sessionStorage.setItem(
          "myLocalSettingStorage",
          JSON.stringify(newLocalSettingStorage)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage]);

  // ######################################################
  // Stepper handling
  // ######################################################
  const steps = [
    "Promotion Partner",
    "Client Account",
    "Trading Accounts",
    "Getting Started",
  ];

  const isStepFailed = (step) => {
    if (myLocalSettingStorage !== null) {
      if (step === myLocalSettingStorage.promotionErrorStep) {
        return true;
      }
    }
    return false;
  };

  const StepperOutlet = (
    <Card
      sx={{
        background: "#F2F2F2",
        border: 0,
        borderColor: "#6D6E70",
        boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
      }}
    >
      <Box
        sx={{
          pt: {
            xs: 2,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 3,
          },
          pb: {
            xs: 2,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 3,
          },
          px: {
            xs: 1,
            sm: 1,
            md: 0,
            lg: 0,
            xl: 0,
          },
        }}
        dir="center"
      >
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<CustomStepperConnector />}
        >
          {steps.map((label, index) => {
            const labelProps = {};
            if (isStepFailed(index)) {
              labelProps.error = true;
            }

            return (
              <Step key={label} sx={StepSx}>
                <StepLabel
                  {...labelProps}
                  StepIconComponent={CustomStepperStepIcon}
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
    </Card>
  );

  // ######################################################
  // Recaptcha outlet (outsourced cause of remounting)
  // ######################################################
  const [RecaptchaOutlet, setRecaptchaOutlet] = useState(null);
  if (myLocalSettingStorage !== null && myLocalSettingStorage !== undefined) {
    if (
      myLocalSettingStorage.retailerInformation !== "" &&
      myLocalSettingStorage.retailerInformation !== undefined &&
      RecaptchaOutlet === null
    ) {
      const sitekey =
        myLocalSettingStorage.retailerInformation.recaptcha_site_key;

      setRecaptchaOutlet(
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={sitekey}
          onErrored={() => {
            setStateStorage((previousState) => ({
              ...previousState,
              recaptcha: false,
              recaptchaValue: "unset",
            }));

            let newLocalSettingStorage = JSON.parse(
              sessionStorage.getItem("myLocalSettingStorage")
            );
            if (newLocalSettingStorage === null) {
              newLocalSettingStorage = stateStorageInit;
            }

            newLocalSettingStorage.recaptcha = false;
            newLocalSettingStorage.recaptchaValue = "unset";
            sessionStorage.setItem(
              "myLocalSettingStorage",
              JSON.stringify(newLocalSettingStorage)
            );
          }}
          onExpired={() => {
            setStateStorage((previousState) => ({
              ...previousState,
              recaptcha: false,
              recaptchaValue: "unset",
            }));

            let newLocalSettingStorage = JSON.parse(
              sessionStorage.getItem("myLocalSettingStorage")
            );
            if (newLocalSettingStorage === null) {
              newLocalSettingStorage = stateStorageInit;
            }

            newLocalSettingStorage.recaptcha = false;
            newLocalSettingStorage.recaptchaValue = "unset";
            sessionStorage.setItem(
              "myLocalSettingStorage",
              JSON.stringify(newLocalSettingStorage)
            );
          }}
          onChange={() => {
            setStateStorage((previousState) => ({
              ...previousState,
              recaptcha: true,
              recaptchaValue: recaptchaRef.current.getValue(),
            }));

            let newLocalSettingStorage = JSON.parse(
              sessionStorage.getItem("myLocalSettingStorage")
            );
            if (newLocalSettingStorage === null) {
              newLocalSettingStorage = stateStorageInit;
            }

            newLocalSettingStorage.recaptcha = true;
            newLocalSettingStorage.recaptchaValue =
              recaptchaRef.current.getValue();
            sessionStorage.setItem(
              "myLocalSettingStorage",
              JSON.stringify(newLocalSettingStorage)
            );
          }}
        />
      );
    }
  }

  // ######################################################
  // Step outlet
  // ######################################################
  const PromotionPartnerOutlet = (
    <div id="promotion-partner-scroll">
      {stateStorage.promotionCurrentStep === 0 ? (
        <Box sx={{ mt: 2 }}>{StepperOutlet}</Box>
      ) : null}

      <Box sx={{ mt: 2 }}>
        <PromotionPartner
          content={content}
          loadingProp={loading}
          errorProp={error}
        />
      </Box>
    </div>
  );

  // ######################################################
  // Step outlet
  // ######################################################
  const ClientAccountOutlet = (
    <div id="client-account-scroll">
      {stateStorage.promotionCurrentStep === 1 ? (
        <Box sx={{ mt: 2 }}>{StepperOutlet}</Box>
      ) : null}
      <Box sx={{ mt: 2 }}>
        <ClientAccount
          content={content}
          loadingProp={loading}
          errorProp={error}
          RecaptchaOutlet={RecaptchaOutlet}
        />
      </Box>
    </div>
  );

  // ######################################################
  // Step outlet
  // ######################################################
  const TradingAccountsOutlet = (
    <div id="trading-accounts-scroll">
      {stateStorage.promotionCurrentStep === 2 ? (
        <Box sx={{ mt: 2 }}>{StepperOutlet}</Box>
      ) : null}
      <Box sx={{ mt: 2 }}>
        <TradingAccounts
          content={content}
          loadingProp={loading}
          errorProp={error}
        />
      </Box>
    </div>
  );

  // ######################################################
  // Step outlet
  // ######################################################
  const GettingStartedOutlet = (
    <div id="getting-started-scroll">
      {stateStorage.promotionCurrentStep === 3 ? (
        <Box sx={{ mt: 2 }}>{StepperOutlet}</Box>
      ) : null}
      <Box sx={{ mt: 2 }}>
        <GettingStarted />
      </Box>
    </div>
  );

  // ######################################################
  // Main JSX return
  // ######################################################
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        direction="column"
        alignItems="center"
        sx={{
          width: {
            xs: "95vw",
            sm: "90vw",
            md: "80vw",
            lg: "75vw",
            xl: "70vw",
          },
        }}
      >
        <Stack spacing={0}>
          {stateStorage.localizationLine !== null &&
          stateStorage.localizationLine.availability_promotion !== "1" ? (
            <DialoguePromotionUnavailable />
          ) : null}

          {stateStorage.promotionCurrentStep >= 0
            ? PromotionPartnerOutlet
            : null}

          {stateStorage.promotionCurrentStep >= 1 ? ClientAccountOutlet : null}

          {stateStorage.promotionCurrentStep >= 2
            ? TradingAccountsOutlet
            : null}

          {stateStorage.promotionCurrentStep >= 3 ? GettingStartedOutlet : null}
        </Stack>
      </Box>
    </Grid>
  );
}
