// react and js
import { Link as RouterLink } from "react-router-dom";
import { React } from "react";

// mui
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Box, AppBar, Typography, Grid } from "@mui/material";

// components

// hooks
import defines from "../hooks/defines";

// layouts

// pages

// theme

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function PublicHeader() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <AppBar
      sx={{
        background: "#F2F2F2",
        boxShadow: "0px 0px 2px rgba(0,0,0,0.5)",
        minHeight: isDesktop
          ? defines.headerHeightDesktop
          : defines.headerHeightMobile,
        maxHeight: isDesktop
          ? defines.headerHeightDesktop
          : defines.headerHeightMobile,
      }}
    >
      <Grid
        container
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          justifyItems: "center",
          minHeight: isDesktop
            ? defines.headerHeightDesktop
            : defines.headerHeightMobile,
          maxHeight: isDesktop
            ? defines.headerHeightDesktop
            : defines.headerHeightMobile,
        }}
      >
        <RouterLink to={process.env.REACT_APP_URL_FRONTEND_WEBSITE} style={{ display: "flex" }}>
          {isDesktop ? (
            <Box
              component="img"
              src={process.env.REACT_APP_PATH_LOGO}
              sx={{
                marginTop: "0px",
                marginLeft: "22px",
                width: "100px",
              }}
            />
          ) : (
            <Box
              component="img"
              src={process.env.REACT_APP_PATH_LOGO}
              sx={{
                marginTop: "1px",
                marginLeft: "22px",
                height: "47px",
                width: "100px",
              }}
            />
          )}
        </RouterLink>

        <Typography
          variant="topbar"
          sx={{
            marginRight: "32px",
            "&:hover": {
              color: "#474A4C",
            },
          }}
        >
          Checkout
        </Typography>

        <RouterLink
          to={process.env.REACT_APP_URL_FRONTEND_WEBSITE}
          style={{ textDecoration: "none", display: "flex" }}
        >
          <Typography
            variant="topbar"
            sx={{
              marginRight: "32px",
            }}
          >
            Website
          </Typography>
        </RouterLink>
      </Grid>
    </AppBar>
  );
}
