// react and js
import PropTypes from "prop-types";
import * as Yup from "yup";
import { React, useState, useRef, useEffect, useContext } from "react";
import { useFormik, Form, FormikProvider } from "formik";

// mui
import {
  Stack,
  Select,
  NativeSelect,
  Grid,
  FormControl,
  Box,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Autocomplete from "@mui/material/Autocomplete";

// components
import CardOutlet from "../../../components/CardOutlet";
import LoadingAnimation from "../../../components/LoadingAnimation";
import MissingContent from "../../../components/MissingContent";
import RequestMessage from "../../../components/RequestMessage";
import backendRequest from "../../../components/BackendRequest";

// hooks
import appContext from "../../../hooks/appContext";
import { stateStorageInit } from "../../../hooks/stateStorageInit";

// layouts

// pages

// theme
import {
  B1Small,
  AuthenticationButton,
  ModalContentMenuItem,
  ModalContentOutlinedInput,
  AutocompleteOption,
  ModalContentTextField,
} from "../../../theme/styled";

// utils
import { combineReturnMessage } from "../../../utils/common";

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
export default function TradingAccounts({ content, loadingProp, errorProp }) {
  TradingAccounts.propTypes = {
    content: PropTypes.any,
    loadingProp: PropTypes.any.isRequired,
    errorProp: PropTypes.any.isRequired,
  };

  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);
  const myLocalSettingStorage = JSON.parse(
    sessionStorage.getItem("myLocalSettingStorage")
  );

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const wrapperRef = useRef(null);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [warning, setWarning] = useState(false);
  const [returnMessage, setReturnMessage] = useState("");

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setError(false);
        setSuccess(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside, {
      passive: false,
      capture: false,
    });
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef]);

  let selectedTypeInitValue = "live";
  let selectedServerInitValue = "";
  let selectedAccountInitValue = "";

  const KnownAccountServerShown = { known_account: [], known_server: [] };

  if (myLocalSettingStorage !== null) {
    if (myLocalSettingStorage.distributionAccountType !== null) {
      selectedTypeInitValue = myLocalSettingStorage.distributionAccountType;
    }

    if (myLocalSettingStorage.distributionAccountNumber !== null) {
      selectedAccountInitValue =
        myLocalSettingStorage.distributionAccountNumber;
    }

    if (myLocalSettingStorage.distributionTradingServer !== null) {
      selectedServerInitValue = myLocalSettingStorage.distributionTradingServer;
    }
  }

  let LiveAdable = false;
  let DemoAdable = false;

  if (stateStorage !== null) {
    if (stateStorage.distributionPlan !== null) {
      if (
        stateStorage.distributionPlan.distribution_live_included_accounts !==
        "0"
      ) {
        LiveAdable = true;
      }
      if (
        stateStorage.distributionPlan.distribution_demo_included_accounts !==
        "0"
      ) {
        DemoAdable = true;
      }
    }

    if (LiveAdable === false && DemoAdable === false) {
      selectedTypeInitValue = "live";
    } else if (LiveAdable === true && DemoAdable === false) {
      selectedTypeInitValue = "live";

      const KnownAccount =
        myLocalSettingStorage.userInformation.answer.known_account_server
          .known_account;
      const KnownServer = [];

      myLocalSettingStorage.userInformation.answer.known_account_server.known_server.forEach(
        (server) => {
          if (!server.toUpperCase().includes("DEMO")) {
            KnownServer.push(server);
          }
        }
      );

      KnownAccountServerShown.known_account = KnownAccount;
      KnownAccountServerShown.known_server = KnownServer;
    } else if (LiveAdable === false && DemoAdable === true) {
      selectedTypeInitValue = "demo";

      const KnownAccount =
        myLocalSettingStorage.userInformation.answer.known_account_server
          .known_account;
      const KnownServer = [];

      myLocalSettingStorage.userInformation.answer.known_account_server.known_server.forEach(
        (server) => {
          if (!server.toUpperCase().includes("LIVE")) {
            KnownServer.push(server);
          }
        }
      );

      KnownAccountServerShown.known_account = KnownAccount;
      KnownAccountServerShown.known_server = KnownServer;
    } else if (LiveAdable === true && DemoAdable === true) {
      selectedTypeInitValue = "live";
    }
  }

  const [accountType, setAccountType] = useState(selectedTypeInitValue);

  if (myLocalSettingStorage !== null) {
    if (myLocalSettingStorage.userInformation !== "") {
      if (LiveAdable === true && DemoAdable === true) {
        const KnownAccount =
          myLocalSettingStorage.userInformation.answer.known_account_server
            .known_account;
        const KnownServer = [];

        if (accountType === "live") {
          myLocalSettingStorage.userInformation.answer.known_account_server.known_server.forEach(
            (server) => {
              if (!server.toUpperCase().includes("DEMO")) {
                KnownServer.push(server);
              }
            }
          );
        } else if (accountType === "demo") {
          myLocalSettingStorage.userInformation.answer.known_account_server.known_server.forEach(
            (server) => {
              if (!server.toUpperCase().includes("LIVE")) {
                KnownServer.push(server);
              }
            }
          );
        }

        KnownAccountServerShown.known_account = KnownAccount;
        KnownAccountServerShown.known_server = KnownServer;
      }
    }
  }

  if (LiveAdable === true && DemoAdable === false && accountType !== "live") {
    setAccountType("live");
  } else if (
    LiveAdable === false &&
    DemoAdable === true &&
    accountType !== "demo"
  ) {
    setAccountType("demo");
  }

  function RequestBackendAction(
    setSubmitting,
    setErrorProp,
    setSuccessProp,
    setWarningProp,
    setReturnMessageProp,
    SubmitRequestContent
  ) {
    setErrorProp(false);
    setSuccessProp(false);
    setWarningProp(false);
    setReturnMessageProp("");

    backendRequest(process.env.REACT_APP_URL_API_CHECKOUT, SubmitRequestContent)
      .then((contentFromBackend) => {
        const keyOnly = Object.keys(contentFromBackend);
        const checkKey = keyOnly.includes("message");

        if (checkKey === true) {
          if (contentFromBackend.message === "success") {
            setErrorProp(false);
            // setSuccessProp(true);
            combineReturnMessage(contentFromBackend, setReturnMessageProp);

            setStateStorage((previousState) => ({
              ...previousState,
              distributionCurrentStep: 3,
              distributionAccountType: SubmitRequestContent.accounts[0].type,
              distributionAccountNumber:
                SubmitRequestContent.accounts[0].account,
              distributionTradingServer:
                SubmitRequestContent.accounts[0].server,
            }));

            let newLocalSettingStorage = JSON.parse(
              sessionStorage.getItem("myLocalSettingStorage")
            );
            if (newLocalSettingStorage === null) {
              newLocalSettingStorage = stateStorageInit;
            }

            newLocalSettingStorage.distributionCurrentStep = 3;
            newLocalSettingStorage.distributionAccountType =
              SubmitRequestContent.accounts[0].type;
            newLocalSettingStorage.distributionAccountNumber =
              SubmitRequestContent.accounts[0].account;
            newLocalSettingStorage.distributionTradingServer =
              SubmitRequestContent.accounts[0].server;
            sessionStorage.setItem(
              "myLocalSettingStorage",
              JSON.stringify(newLocalSettingStorage)
            );
          } else {
            setErrorProp(true);
            combineReturnMessage(contentFromBackend, setReturnMessageProp);
          }
        } else {
          setWarningProp(true);
          setReturnMessageProp("Unknown error!");
        }
        setSubmitting(false);
      })
      .catch(() => {
        setReturnMessageProp("Unknown error!");
        setErrorProp(true);
        setSubmitting(false);
      });
  }

  const myValidationScheme = Yup.object().shape({
    selected_account: Yup.number()
      .required("Account number is required")
      .positive()
      .integer(),
    selected_server: Yup.string().required("Trading server is required"),
  });

  const formik = useFormik({
    initialValues: {
      selected_account: selectedAccountInitValue,
      selected_server: selectedServerInitValue,
    },
    validationSchema: myValidationScheme,
    onSubmit: (values, { setSubmitting }) => {
      const SubmitRequestContent = {
        request_type: "checkout",
        route_info: "verify_accounts",
        authentication: {
          dashboard_access_token:
            myLocalSettingStorage.userInformation.answer.dashboard_access_token,
        },
        selection: {
          mode: "distribution",
          distributor_id:
            myLocalSettingStorage.retailerInformation.checkout_distributor_id,
          plan_id: myLocalSettingStorage.distributionPlan.plan_id,
        },
        accounts: [
          {
            operation_id: 1,
            type: accountType,
            account: values.selected_account,
            server: values.selected_server,
          },
        ],
      };

      RequestBackendAction(
        setSubmitting,
        setError,
        setSuccess,
        setWarning,
        setReturnMessage,
        SubmitRequestContent
      );
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  let subheaderMessage = (
    <Typography variant="contentMiddle">
      Please add your first MetaTrader
      <Typography variant="contentMiddleBold">{` live or demo `}</Typography>
      trading account to your subscription. You can later add or remove trading accounts within your dashboard! The server name and account number must be the same as the one you use to log in to the MetaTrader trading platform.
    </Typography>
  );

  if (LiveAdable === false && DemoAdable === true) {
    subheaderMessage = (
      <Typography variant="contentMiddle">
        Please add your MetaTrader
        <Typography variant="contentMiddleBold">{` demo `}</Typography>
        trading account to your subscription. You can later add or remove trading accounts within your dashboard! The server name and account number must be the same as the one you use to log in to the MetaTrader trading platform.
      </Typography>
    );
  }
  if (LiveAdable === true && DemoAdable === false) {
    subheaderMessage = (
      <Typography variant="contentMiddle">
        Please add your MetaTrader
        <Typography variant="contentMiddleBold">{` live `}</Typography>
        trading account to your subscription. You can later add or remove trading accounts within your dashboard! The server name and account number must be the same as the one you use to log in to the MetaTrader trading platform.
      </Typography>
    );
  }

  const TradingAccountsOptionsOutlet = (
    <CardOutlet
      variant="outlet"
      backgroundProp="#F2F2F2"
      title="Trading Account"
      subheader={subheaderMessage}
      mainOutlet={
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={1} sx={{ mt: 1 }}>
              {LiveAdable === true && DemoAdable === true ? (
                // eslint-disable-next-line
                <>
                  {isDesktop ? (
                    <Box>
                      <Typography variant="contentSmall">
                        Account type
                      </Typography>
                      <FormControl fullWidth>
                        <Select
                          value={accountType}
                          onChange={(event) => {
                            setAccountType(event.target.value);
                          }}
                          input={<ModalContentOutlinedInput />}
                        >
                          <ModalContentMenuItem value="live">
                            Live
                          </ModalContentMenuItem>
                          <ModalContentMenuItem value="demo">
                            Demo
                          </ModalContentMenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  ) : (
                    <Box>
                      <Typography variant="contentSmall">
                        Account type
                      </Typography>
                      <FormControl fullWidth>
                        <NativeSelect
                          value={accountType}
                          onChange={(event) => {
                            setAccountType(event.target.value);
                          }}
                          input={<ModalContentOutlinedInput />}
                        >
                          <option value="live">Live</option>
                          <option value="demo">Demo</option>
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  )}
                </>
              ) : null}

              <Box>
                <Typography
                  variant="contentSmall"
                  sx={{
                    color:
                      Boolean(
                        touched.selected_account && errors.selected_account
                      ) === true
                        ? "red"
                        : "none",
                  }}
                >
                  {Boolean(
                    touched.selected_account && errors.selected_account
                  ) === true
                    ? "Invalid account number"
                    : "Account number"}
                </Typography>
                <Autocomplete
                  fullWidth
                  freeSolo
                  size="small"
                  onChange={(event, value) => {
                    values.selected_account = value;
                  }}
                  defaultValue={selectedAccountInitValue}
                  id="combo-box-demo"
                  options={KnownAccountServerShown.known_account}
                  renderOption={(props, option) => (
                    <AutocompleteOption {...props} key={option}>
                      {option}
                    </AutocompleteOption>
                  )}
                  renderInput={(params) => (
                    <ModalContentTextField
                      {...params}
                      type="text"
                      {...getFieldProps("selected_account")}
                      error={Boolean(
                        touched.selected_account && errors.selected_account
                      )}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Box>

              <Box>
                <Box>
                  <Typography
                    variant="contentSmall"
                    sx={{
                      color:
                        Boolean(
                          touched.selected_server && errors.selected_server
                        ) === true
                          ? "red"
                          : "none",
                    }}
                  >
                    {Boolean(
                      touched.selected_server && errors.selected_server
                    ) === true
                      ? "Invalid trading server"
                      : "Trading server"}
                  </Typography>
                  <Autocomplete
                    fullWidth
                    freeSolo
                    size="small"
                    onChange={(event, value) => {
                      values.selected_server = value;
                    }}
                    defaultValue={selectedServerInitValue}
                    id="combo-box-demo"
                    options={KnownAccountServerShown.known_server}
                    renderOption={(props, option) => (
                      <AutocompleteOption {...props} key={option}>
                        {option}
                      </AutocompleteOption>
                    )}
                    renderInput={(params) => (
                      <ModalContentTextField
                        {...params}
                        type="text"
                        {...getFieldProps("selected_server")}
                        error={Boolean(
                          touched.selected_server && errors.selected_server
                        )}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Box>
              </Box>

              <Box>
                <RequestMessage
                  wrapperRef={wrapperRef}
                  error={error}
                  warning={warning}
                  success={success}
                  returnMessage={returnMessage}
                  marginTopProp={
                    error === true || warning === true || success === true
                      ? 2
                      : 2
                  }
                  marginBottomProp={
                    error === true || warning === true || success === true
                      ? 2
                      : 0
                  }
                  fullWidth
                />
              </Box>
            </Stack>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <B1Small
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    setStateStorage((previousState) => ({
                      ...previousState,
                      authState: "login",
                      distributionCurrentStep: 1,
                      alreadyApprovedPpTac: false,
                    }));

                    let newLocalSettingStorage = JSON.parse(
                      sessionStorage.getItem("myLocalSettingStorage")
                    );
                    if (newLocalSettingStorage === null) {
                      newLocalSettingStorage = stateStorageInit;
                    }

                    newLocalSettingStorage.authState = "login";
                    newLocalSettingStorage.distributionCurrentStep = 1;
                    newLocalSettingStorage.alreadyApprovedPpTac = false;

                    sessionStorage.setItem(
                      "myLocalSettingStorage",
                      JSON.stringify(newLocalSettingStorage)
                    );
                  }}
                >
                  Back
                </B1Small>
              </Grid>
              <Grid item xs={6} md={6}>
                <AuthenticationButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Continue
                </AuthenticationButton>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      }
    />
  );

  let TradingAccountsSelectedOutlet = (
    <MissingContent message="Trading Accounts" />
  );
  if (stateStorage !== null) {
    if (loadingProp) {
      TradingAccountsSelectedOutlet = (
        <LoadingAnimation title="Trading Accounts" />
      );
    } else if (errorProp) {
      TradingAccountsSelectedOutlet = (
        <MissingContent message="Trading Accounts" />
      );
    } else if (content && stateStorage.distributionCurrentStep > 2) {
      TradingAccountsSelectedOutlet = (
        <CardOutlet
          variant="outlet"
          backgroundProp="#F2F2F2"
          title="Trading Account"
          subheader="The trading account listed below has been selected. Depending on your plan, you can add, change, or remove trading accounts anytime."
          mainOutlet={
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  pt: 1,
                  pb: 2,
                  px: 2,
                  mt: 2,
                  mb: 0,
                  background: "white",
                  borderRadius: "0px",
                  boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5);",
                }}
              >
                <Stack sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      p: "5px 0px 0px 0px",
                      display: "flex",
                      borderBottom: "1px solid #C8C8C8",
                      borderColor: "#C8C8C8",
                    }}
                  >
                    <Box
                      sx={{
                        width: "40%",
                        pr: 1,
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography variant="contentBig" align="left">
                        Account type
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        width: "60%",
                        pl: 1,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography
                        variant="contentBig"
                        align="right"
                        sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
                      >
                        {stateStorage.distributionAccountType}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      p: "5px 0px 0px 0px",
                      display: "flex",
                      borderBottom: "1px solid #C8C8C8",
                      borderColor: "#C8C8C8",
                    }}
                  >
                    <Box
                      sx={{
                        width: "40%",
                        pr: 1,
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography variant="contentBig" align="left">
                        Account number
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        width: "60%",
                        pl: 1,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography
                        variant="contentBig"
                        align="right"
                        sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
                      >
                        {stateStorage.distributionAccountNumber}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      p: "5px 0px 0px 0px",
                      display: "flex",
                      borderBottom: "1px solid #C8C8C8",
                      borderColor: "#C8C8C8",
                    }}
                  >
                    <Box
                      sx={{
                        width: "40%",
                        pr: 1,
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography variant="contentBig" align="left">
                        Trading server
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        width: "60%",
                        pl: 1,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography
                        variant="contentBig"
                        align="right"
                        sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
                      >
                        {stateStorage.distributionTradingServer}
                      </Typography>
                    </Box>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          }
        />
      );
    }
  }

  return stateStorage.distributionCurrentStep === 2
    ? TradingAccountsOptionsOutlet
    : TradingAccountsSelectedOutlet;
}
