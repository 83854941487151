// react and js
import PropTypes from "prop-types";
import { React, useState, useEffect, useContext } from "react";
import { initializePaddle } from "@paddle/paddle-js";

// mui
import { Box, Grid } from "@mui/material";

// components
import backendRequest from "../../components/BackendRequest";
import LoadingAnimation from "../../components/LoadingAnimation";
import MissingContent from "../../components/MissingContent";

// hooks
import appContext from "../../hooks/appContext";

// layouts

// pages

// theme

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget() {
  const { stateStorage, setStateStorage } = useContext(appContext);

  const [error, setError] = useState(false);
  const [, setWarning] = useState(false);
  const [, setSuccess] = useState(false);

  const [loading, setLoading] = useState(navigator.onLine);
  const [content, setContent] = useState();
  const [, setReturnMessage] = useState("");

  const [paddle, setPaddle] = useState(null);
  const [paddleClose, setPaddleClose] = useState(false);

  // ######################################################
  // Main backend request
  // ######################################################
  function MainRequest() {
    setError(false);
    setWarning(false);
    setSuccess(false);
    setReturnMessage("");
    setLoading(true);

    const sendData = {
      request_type: "checkout",
      route_info: "portfolio_request_distribution",
    };

    backendRequest(process.env.REACT_APP_URL_API_CHECKOUT, sendData)
      .then((contentFromBackend) => {
        setError(false);
        setLoading(false);
        setContent(contentFromBackend);

        setStateStorage((previousState) => ({
          ...previousState,
          retailerInformation: contentFromBackend.retailer,
        }));
      })
      .catch(() => {
        if (navigator.onLine === true) {
          setError(true);
          setLoading(false);
          setReturnMessage("Unknown error!");
        }
      });
  }

  // ######################################################
  // Inital and triggered refreshs
  // ######################################################

  useEffect(() => {
    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Event callback for paddle checkout
  const paddleEventCallback = (EventData) => {
    if (
      EventData.name === "checkout.completed" ||
      EventData.name === "checkout.closed"
    ) {
      setPaddleClose(true);
      window.location.href = `${process.env.REACT_APP_URL_FRONTEND_DASHBOARD}/subscriptions`;
    }
  };

  // Download and initialize Paddle instance from CDN
  if (stateStorage.retailerInformation !== "" && paddle === null) {
    if (stateStorage.retailerInformation.psp_mode === "sandbox") {
      initializePaddle({
        environment: "sandbox",
        token: stateStorage.retailerInformation.paddle_client_sided_token,
        eventCallback: paddleEventCallback,
      }).then((paddleInstance) => {
        if (paddleInstance) {
          setPaddle(paddleInstance);
        }
      });
    }

    if (stateStorage.retailerInformation.psp_mode === "live") {
      initializePaddle({
        environment: "production",
        token: stateStorage.retailerInformation.paddle_client_sided_token,
        eventCallback: paddleEventCallback,
      }).then((paddleInstance) => {
        if (paddleInstance) {
          setPaddle(paddleInstance);
        }
      });
    }
  }

  // Method to close paddle checkout
  if (paddleClose === true) {
    paddle.Checkout.close();
  }

  // ######################################################
  // Main JSX return
  // ######################################################
  function PspMessage({ contentProp, loadingProp, errorProp }) {
    PspMessage.propTypes = {
      contentProp: PropTypes.any,
      loadingProp: PropTypes.any.isRequired,
      errorProp: PropTypes.any.isRequired,
    };

    let PspMessageOptionsOutlet = null;
    if (loadingProp) {
      PspMessageOptionsOutlet = <LoadingAnimation title="Client Consent" />;
    } else if (errorProp) {
      PspMessageOptionsOutlet = <MissingContent message="Client Consent" />;
    } else if (contentProp) {
      PspMessageOptionsOutlet = null;
    }

    return PspMessageOptionsOutlet;
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        direction="column"
        alignItems="center"
        sx={{
          width: {
            xs: "95vw",
            sm: "90vw",
            md: "80vw",
            lg: "75vw",
            xl: "70vw",
          },
        }}
      >
        <Box sx={{ mt: 6 }}>
          <PspMessage
            content={content}
            loadingProp={loading}
            errorProp={error}
          />
        </Box>
      </Box>
    </Grid>
  );
}
