// react and js
import PropTypes from "prop-types";
import { React, useState, useRef, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

// mui
import {
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Link,
  Stack,
  Box,
} from "@mui/material";

// components
import ModalDialog from "../../../components/ModalDialog";
import Iconify from "../../../components/Iconify";

// hooks

// layouts

// pages

// theme
import { B1Small } from "../../../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function IncludedSymbols({ plan, details }) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  IncludedSymbols.propTypes = {
    plan: PropTypes.any.isRequired,
    details: PropTypes.any.isRequired,
  };

  // console.log(details);

  // eslint-disable-next-line
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Typography variant="contentSmallBold">
        {/* eslint-disable-next-line */}
        <Link
          component={RouterLink}
          onClick={handleClickOpen("paper")}
          underline="none"
          variant="contentSmallLink"
          color="inherit"
        >
          Included symbols
        </Link>
      </Typography>

      <ModalDialog
        open={open}
        handleClose={handleClose}
        scroll={scroll}
        outlet={
          <>
            <DialogTitle
              id="dialog-title"
              sx={{
                background: "#F2F2F2",
                textTransform: "none",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
                py: 1,
                pr: 1,
              }}
            >
              <Typography variant="contentBig">
                Included symbols for {plan.plan_description.toLowerCase()}
              </Typography>

              <IconButton
                onClick={handleClickClose}
                sx={{ borderRadius: "0px" }}
              >
                <Iconify
                  icon="eva:close-outline"
                  minWidth={25}
                  minHeight={25}
                  color="#474A4C"
                />
              </IconButton>
            </DialogTitle>

            <DialogContent
              dividers
              sx={{
                background: "#F2F2F2",
                borderTop: "1px solid #C8C8C8",
                borderBottom: "1px solid #C8C8C8",
              }}
            >
              <Box sx={{ mb: 2 }}>
                <Typography variant="contentMiddle">
                The MT5 Tick Data for each symbol listed below can be downloaded as an executable installer. In just a few minutes, all data will be accessible in a MetaTrader 5 instance of your choice.
                </Typography>

                <Typography variant="contentMiddle">
                  <br />
                  <br />
                  MT5 Tick Data for each symbol is available in{' '}
                  <Typography
                    variant="contentMiddle"
                    sx={{ textDecoration: "underline" }}
                  >
                    low, medium and high spread
                  </Typography>{' '}
                  configurations. For precise spread values, please refer to the
                  <Typography variant="contentMiddleBold">
                    <Link
                      variant="contentMiddleLink"
                      underline="none"
                      target="_blank"
                      color="inherit"
                      href={plan.user_manual_url}
                    >
                      {` user manual`}
                    </Link>
                  </Typography>
                  .
                </Typography>
              </Box>

              {details.map((row, index) => (
                <Stack
                  key={index}
                  spacing="3px"
                  sx={{ background: "white", mb: 1, px: "10px", py: "5px" }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ width: "40%", pr: 1 }}>
                      <Typography variant="contentSmallBold">
                        Symbol
                      </Typography>
                    </Box>
                    <Box sx={{ width: "60%", pl: 1 }}>
                      <Typography variant="contentSmall">
                        {row.symbol_name}
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ width: "40%", pr: 1 }}>
                      <Typography variant="contentSmallBold"> Type </Typography>
                    </Box>
                    <Box sx={{ width: "60%", pl: 1 }}>
                      <Typography variant="contentSmall">
                        {row.symbol_group}
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ width: "40%", pr: 1 }}>
                      <Typography variant="contentSmallBold">
                        Start date
                      </Typography>
                    </Box>
                    <Box sx={{ width: "60%", pl: 1 }}>
                      <Typography variant="contentSmall">
                        {row.start_date}
                      </Typography>
                    </Box>
                  </Box>
                </Stack>
              ))}
            </DialogContent>

            <DialogActions
              sx={{
                background: "#F2F2F2",
                p: 0,
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <B1Small
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={handleClose}
              >
                Close
              </B1Small>
            </DialogActions>
          </>
        }
      />
    </>
  );
}
