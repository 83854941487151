// react and js
import { React } from "react";

// mui
import { Box } from "@mui/material";

// components

// hooks

// layouts

// pages

// theme

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export function combineReturnMessage(content, setReturnMessage) {
  const formattedMessage =
    content.message.charAt(0).toUpperCase() + content.message.slice(1);

  if (content.expected_pattern !== undefined) {
    let patterns = "";
    content.expected_pattern.forEach((pattern) => {
      patterns += `${pattern}, `;
    });

    setReturnMessage(
      <>
        {formattedMessage}
        <Box sx={{ m: 1 }} />
        {patterns.substring(0, patterns.length - 2)}
      </>
    );
  } else {
    setReturnMessage(formattedMessage);
  }
}
