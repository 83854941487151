// react and js

// mui

// components

// hooks

// layouts

// pages

// theme

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
// Default state storage values
export const stateStorageInit = {
  // Backbone
  authState: "login",
  userInformation: "",
  retailerInformation: "",
  recaptcha: false,
  recaptchaValue: "unset",
  performRescroll: false,
  localization: null,
  localizationLine: null,
  localizationLineSelected: null,
  alreadyApprovedPpTac: false,

  // Page: distribution
  widgetUpdateIntervalDistribution: 10000,

  // Page: distribution_tick_data
  widgetUpdateIntervalDistributionTickData: 10000,

  // Page: promotion
  widgetUpdateIntervalPromotion: 10000,

  // Widget: distribution
  distributionCurrentStep: 0,
  distributionErrorStep: -1,
  distributionErrorStepCode: "none",
  distributionProduct: null,
  distributionPlan: null,
  distributionAccountType: null,
  distributionAccountNumber: null,
  distributionTradingServer: null,
  distributionPaymentIntentToken: null,

  // Widget: distribution_tick_data
  distributionTickDataCurrentStep: 0,
  distributionTickDataErrorStep: -1,
  distributionTickDataErrorStepCode: "none",
  distributionTickDataPlan: null,
  distributionTickDataPaymentIntentToken: null,

  // Widget: promotion
  promotionCurrentStep: 0,
  promotionErrorStep: -1,
  promotionErrorStepCode: "none",
  promotionPromotionPartner: null,
  promotionAccountType: null,
  promotionAccountNumber: null,
  promotionTradingServer: null,
  promotionAdditionalInformation: null,
};
