// react and js
import * as React from "react";

// mui
import { styled } from "@mui/material/styles";

// components
import Page from "../../components/Page";

// hooks

// layouts

// pages

// theme

// utils

// widgets
import WidgetDistribution from "../../widgets/distribution/Distribution";

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function StartDistribution() {
  return (
    <Page title="Start Distribution">
      <RootStyle>
        <WidgetDistribution />
      </RootStyle>
    </Page>
  );
}
