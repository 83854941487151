// react and js
import { React, useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

// mui
import {
  Step,
  StepLabel,
  Stepper,
  Box,
  Grid,
  Card,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// components
import {
  CustomStepperConnector,
  CustomStepperStepIcon,
  StepSx,
} from "../../components/Stepper";
import backendRequest from "../../components/BackendRequest";
import DialogueDistributionUnavailable from "./DialogueDistributionUnavailable";
import DialogueProductUnavailable from "./DialogueProductUnavailable";
import DialoguePaymentServiceMaintenance from "./DialoguePaymentServiceMaintenance";

// hooks
import defines from "../../hooks/defines";
import appContext from "../../hooks/appContext";
import { stateStorageInit } from "../../hooks/stateStorageInit";

// layouts

// pages

// theme

// utils

// widgets
import ProductPlan from "./checkout/ProductPlan";
import ClientAccount from "./checkout/ClientAccount";
import BillingPayment from "./checkout/BillingPayment";
import GettingStarted from "./checkout/GettingStarted";

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  const { stateStorage, setStateStorage } = useContext(appContext);
  const myLocalSettingStorage = JSON.parse(
    sessionStorage.getItem("myLocalSettingStorage")
  );
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);

  const [searchParams] = useSearchParams();
  const planId = searchParams.get("plan_id");

  const [error, setError] = useState(false);
  const [, setWarning] = useState(false);
  const [, setSuccess] = useState(false);

  const [loading, setLoading] = useState(navigator.onLine);
  const [content, setContent] = useState();
  const [, setReturnMessage] = useState("");

  const [regularRefreshIntervalCounter, setRegularRefreshIntervalCounter] =
    useState(0);

  const [scrollCounter, setScrollCounter] = useState(0);
  const [scrollStepId, setScrollStepId] = useState("product-plan-scroll");

  const recaptchaRef = useRef();

  const hideDialoguesParam = searchParams.get(
    "d22675197fede620cb6c5504ddb4ef0d"
  );
  const [hideDialogues, setHideDialogues] = useState(false);

  if (
    hideDialoguesParam === "d74853d40d50a91d31b1625c1e938fad" &&
    hideDialogues === false
  ) {
    setHideDialogues(true);
  }

  // Example: http://localhost:3000/distribution-tick-data?plan_id=53

  // Example: http://localhost:3000/distribution?d22675197fede620cb6c5504ddb4ef0d=d74853d40d50a91d31b1625c1e938fad

  // ######################################################
  // Main backend request
  // ######################################################
  function MainRequest(periodic) {
    if (
      sessionStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      if (periodic === false) {
        setError(false);
        setWarning(false);
        setSuccess(false);
        setReturnMessage("");
        setLoading(true);
      }
    }

    const sendData = {
      request_type: "checkout",
      route_info: "portfolio_request_distribution_tick_data",
    };
    backendRequest(process.env.REACT_APP_URL_API_CHECKOUT, sendData)
      .then((contentFromBackend) => {
        setError(false);
        setLoading(false);
        setContent(contentFromBackend);

        let IsInList = false;
        let LocalizationLinePredicted = null;
        //
        contentFromBackend.localization.t_localization.forEach((val) => {
          if (
            contentFromBackend.localization.request_location
              .client_ip_country_code === val.country_code
          ) {
            LocalizationLinePredicted = val;
            IsInList = true;
          }
        });
        //
        if (IsInList === false) {
          LocalizationLinePredicted =
            contentFromBackend.localization.t_localization[0];
        }

        setStateStorage((previousState) => ({
          ...previousState,
          retailerInformation: contentFromBackend.retailer,
          localization: contentFromBackend.localization,
          localizationLine: LocalizationLinePredicted,
          localizationLineSelected: LocalizationLinePredicted,
        }));

        let newLocalSettingStorage = JSON.parse(
          sessionStorage.getItem("myLocalSettingStorage")
        );
        if (newLocalSettingStorage === null) {
          newLocalSettingStorage = stateStorageInit;
        }

        newLocalSettingStorage.retailerInformation =
          contentFromBackend.retailer;
        newLocalSettingStorage.localization = contentFromBackend.localization;
        newLocalSettingStorage.localizationLine = LocalizationLinePredicted;
        newLocalSettingStorage.localizationLineSelected =
          LocalizationLinePredicted;
        sessionStorage.setItem(
          "myLocalSettingStorage",
          JSON.stringify(newLocalSettingStorage)
        );

        if (planId === null) {
          if (myLocalSettingStorage !== null) {
            const lastActivity = JSON.parse(
              sessionStorage.getItem("lastActivity")
            );
            const TimestampSecond = Math.round(Date.now() / 1000);
            const lastActivityDiff = TimestampSecond - lastActivity;

            if (periodic === false) {
              if (
                myLocalSettingStorage.distributionTickDataCurrentStep !== 3 &&
                lastActivityDiff < 3540
              ) {
                setStateStorage(myLocalSettingStorage);
              } else {
                setStateStorage(stateStorageInit);

                sessionStorage.setItem(
                  "myLocalSettingStorage",
                  JSON.stringify(stateStorageInit)
                );
                sessionStorage.removeItem("myLocalSettingStorage");

                setStateStorage((previousState) => ({
                  ...previousState,
                  retailerInformation: contentFromBackend.retailer,
                  localization: contentFromBackend.localization,
                  localizationLine: LocalizationLinePredicted,
                  localizationLineSelected: LocalizationLinePredicted,
                }));

                let newLocalSettingStorageInner = JSON.parse(
                  sessionStorage.getItem("myLocalSettingStorage")
                );
                if (newLocalSettingStorageInner === null) {
                  newLocalSettingStorageInner = stateStorageInit;
                }
                newLocalSettingStorageInner.retailerInformation =
                  contentFromBackend.retailer;
                newLocalSettingStorageInner.localization =
                  contentFromBackend.localization;
                newLocalSettingStorageInner.localizationLine =
                  LocalizationLinePredicted;
                newLocalSettingStorageInner.localizationLineSelected =
                  LocalizationLinePredicted;
                sessionStorage.setItem(
                  "myLocalSettingStorage",
                  JSON.stringify(newLocalSettingStorageInner)
                );
              }
            }
          }
        }

        if (planId !== null) {
          for (
            let i = 0;
            i <
            contentFromBackend.distribution_mt5_tick_data_plans
              .distribution_mt5_tick_data_plans.length;
            i += 1
          ) {
            if (
              planId ===
              contentFromBackend.distribution_mt5_tick_data_plans
                .distribution_mt5_tick_data_plans[i].plan_id
            ) {
              setStateStorage((previousState) => ({
                ...previousState,
                distributionTickDataCurrentStep: 1,
                distributionTickDataPlan:
                  contentFromBackend.distribution_mt5_tick_data_plans
                    .distribution_mt5_tick_data_plans[i],
                backUrl:
                  contentFromBackend.distribution_mt5_tick_data_plans
                    .distribution_mt5_tick_data_plans[i].home_url,
              }));

              let newLocalSettingStorageInner = JSON.parse(
                sessionStorage.getItem("myLocalSettingStorage")
              );
              if (newLocalSettingStorageInner === null) {
                newLocalSettingStorageInner = stateStorageInit;
              }

              newLocalSettingStorageInner.distributionTickDataCurrentStep = 1;
              newLocalSettingStorageInner.distributionTickDataPlan =
                contentFromBackend.distribution_mt5_tick_data_plans.distribution_mt5_tick_data_plans[
                  i
                ];
              newLocalSettingStorageInner.backUrl =
                contentFromBackend.distribution_mt5_tick_data_plans.distribution_mt5_tick_data_plans[
                  i
                ].home_url;
              sessionStorage.setItem(
                "myLocalSettingStorage",
                JSON.stringify(newLocalSettingStorageInner)
              );

              navigate("/distribution-tick-data");
            }
          }
        }
      })
      .catch(() => {
        if (navigator.onLine === true) {
          setError(true);
          setLoading(false);
          setReturnMessage("Unknown error!");
        }
      });
  }

  // ######################################################
  // Inital and triggered refreshs
  // ######################################################
  useEffect(() => {
    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ######################################################
  // Regular refreshs
  // ######################################################
  useEffect(() => {
    const timerID = setTimeout(() => {
      setRegularRefreshIntervalCounter(regularRefreshIntervalCounter + 1);
    }, stateStorage.widgetUpdateIntervalDistributionTickData);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  useEffect(() => {
    if (stateStorage.distributionTickDataCurrentStep === 0) {
      MainRequest(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  // ######################################################
  // Auto scroll handling
  // ######################################################
  function scrollToStepWithId(stepId) {
    const autoScrollContainer = document.querySelector(
      "#auto-scroll-container"
    );
    const element = document.getElementById(stepId);
    const elementPosition = element.getBoundingClientRect().top;

    let offsetPosition = 0;
    if (isDesktop) {
      offsetPosition =
        elementPosition +
        autoScrollContainer.scrollTop -
        defines.headerHeightDesktop;
    } else {
      offsetPosition =
        elementPosition +
        autoScrollContainer.scrollTop -
        defines.headerHeightMobile;
    }

    if (autoScrollContainer !== null) {
      autoScrollContainer.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      scrollToStepWithId(scrollStepId);
    }, 50);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollCounter]);

  useEffect(() => {
    if (
      stateStorage.distributionTickDataCurrentStep === 0 &&
      activeStep !== 0
    ) {
      setActiveStep(0);
      if (loading === false) {
        setScrollStepId("product-plan-scroll");
        setScrollCounter(scrollCounter + 1);
      }
    }

    if (
      stateStorage.distributionTickDataCurrentStep === 1 &&
      activeStep !== 1
    ) {
      setActiveStep(1);
      if (loading === false) {
        setScrollStepId("client-account-scroll");
        setScrollCounter(scrollCounter + 1);
      }
    }

    if (
      stateStorage.distributionTickDataCurrentStep === 2 &&
      activeStep !== 2
    ) {
      setActiveStep(2);
      if (loading === false) {
        setScrollStepId("subscription-payment-scroll");
        setScrollCounter(scrollCounter + 1);
      }
    }

    if (
      stateStorage.distributionTickDataCurrentStep === 3 &&
      activeStep !== 3
    ) {
      setActiveStep(3);
      if (loading === false) {
        setScrollStepId("getting-started-scroll");
        setScrollCounter(scrollCounter + 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage]);

  useEffect(() => {
    if (!stateStorage.performRescroll) return;

    setStateStorage((previousState) => ({
      ...previousState,
      performRescroll: false,
    }));

    if (
      stateStorage.distributionTickDataCurrentStep === 0 &&
      stateStorage.performRescroll === true
    ) {
      if (loading === false) {
        setScrollStepId("product-plan-scroll");
        setScrollCounter(scrollCounter + 1);
      }
    }

    if (
      stateStorage.distributionTickDataCurrentStep === 1 &&
      stateStorage.performRescroll === true
    ) {
      if (loading === false) {
        setScrollStepId("client-account-scroll");
        setScrollCounter(scrollCounter + 1);
      }
    }

    if (
      stateStorage.distributionTickDataCurrentStep === 2 &&
      stateStorage.performRescroll === true
    ) {
      if (loading === false) {
        setScrollStepId("subscription-payment-scroll");
        setScrollCounter(scrollCounter + 1);
      }
    }

    if (
      stateStorage.distributionTickDataCurrentStep === 3 &&
      stateStorage.performRescroll === true
    ) {
      if (loading === false) {
        setScrollStepId("getting-started-scroll");
        setScrollCounter(scrollCounter + 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage.performRescroll]);

  useEffect(() => {
    if (stateStorage.distributionTickDataCurrentStep === 0) {
      setScrollStepId("product-plan-scroll");
      setScrollCounter(scrollCounter + 1);
    }

    if (stateStorage.distributionTickDataCurrentStep === 1) {
      setScrollStepId("client-account-scroll");
      setScrollCounter(scrollCounter + 1);
    }

    if (stateStorage.distributionTickDataCurrentStep === 2) {
      setScrollStepId("subscription-payment-scroll");
      setScrollCounter(scrollCounter + 1);
    }

    if (stateStorage.distributionTickDataCurrentStep === 3) {
      setScrollStepId("getting-started-scroll");
      setScrollCounter(scrollCounter + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  // ######################################################
  // Error step handling
  // ######################################################
  useEffect(() => {
    if (myLocalSettingStorage !== null) {
      if (
        myLocalSettingStorage.distributionTickDataErrorStep >
        myLocalSettingStorage.distributionTickDataCurrentStep
      ) {
        setStateStorage((previousState) => ({
          ...previousState,
          distributionTickDataErrorStep: -1,
          distributionTickDataErrorStepCode: "none",
        }));

        let newLocalSettingStorage = JSON.parse(
          sessionStorage.getItem("myLocalSettingStorage")
        );
        if (newLocalSettingStorage === null) {
          newLocalSettingStorage = stateStorageInit;
        }

        newLocalSettingStorage.distributionTickDataErrorStep = -1;
        newLocalSettingStorage.distributionTickDataErrorStepCode = "none";
        sessionStorage.setItem(
          "myLocalSettingStorage",
          JSON.stringify(newLocalSettingStorage)
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage]);

  // ######################################################
  // Stepper handling
  // ######################################################
  const steps = [
    "Product & Plan",
    "Client Account",
    "Billing & Payment",
    "Getting Started",
  ];

  const isStepFailed = (step) => {
    if (myLocalSettingStorage !== null) {
      if (step === myLocalSettingStorage.distributionTickDataErrorStep) {
        return true;
      }
    }
    return false;
  };

  const StepperOutlet = (
    <Card
      sx={{
        background: "#F2F2F2",
        border: 0,
        borderColor: "#6D6E70",
        boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
      }}
    >
      <Box
        sx={{
          pt: {
            xs: 2,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 3,
          },
          pb: {
            xs: 2,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 3,
          },
          px: {
            xs: 1,
            sm: 1,
            md: 0,
            lg: 0,
            xl: 0,
          },
        }}
        dir="center"
      >
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<CustomStepperConnector />}
        >
          {steps.map((label, index) => {
            const labelProps = {};
            if (isStepFailed(index)) {
              labelProps.error = true;
            }

            return (
              <Step key={label} sx={StepSx}>
                <StepLabel
                  {...labelProps}
                  StepIconComponent={CustomStepperStepIcon}
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
    </Card>
  );

  // ######################################################
  // Recaptcha outlet (outsourced cause of remounting)
  // ######################################################
  const [RecaptchaOutlet, setRecaptchaOutlet] = useState(null);
  if (myLocalSettingStorage !== null && myLocalSettingStorage !== undefined) {
    if (
      myLocalSettingStorage.retailerInformation !== "" &&
      myLocalSettingStorage.retailerInformation !== undefined &&
      RecaptchaOutlet === null
    ) {
      setRecaptchaOutlet(
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={myLocalSettingStorage.retailerInformation.recaptcha_site_key}
          onErrored={() => {
            setStateStorage((previousState) => ({
              ...previousState,
              recaptcha: false,
              recaptchaValue: "unset",
            }));

            let newLocalSettingStorage = JSON.parse(
              sessionStorage.getItem("myLocalSettingStorage")
            );
            if (newLocalSettingStorage === null) {
              newLocalSettingStorage = stateStorageInit;
            }

            newLocalSettingStorage.recaptcha = false;
            newLocalSettingStorage.recaptchaValue = "unset";
            sessionStorage.setItem(
              "myLocalSettingStorage",
              JSON.stringify(newLocalSettingStorage)
            );
          }}
          onExpired={() => {
            setStateStorage((previousState) => ({
              ...previousState,
              recaptcha: false,
              recaptchaValue: "unset",
            }));

            let newLocalSettingStorage = JSON.parse(
              sessionStorage.getItem("myLocalSettingStorage")
            );
            if (newLocalSettingStorage === null) {
              newLocalSettingStorage = stateStorageInit;
            }

            newLocalSettingStorage.recaptcha = false;
            newLocalSettingStorage.recaptchaValue = "unset";
            sessionStorage.setItem(
              "myLocalSettingStorage",
              JSON.stringify(newLocalSettingStorage)
            );
          }}
          onChange={() => {
            setStateStorage((previousState) => ({
              ...previousState,
              recaptcha: true,
              recaptchaValue: recaptchaRef.current.getValue(),
            }));

            let newLocalSettingStorage = JSON.parse(
              sessionStorage.getItem("myLocalSettingStorage")
            );
            if (newLocalSettingStorage === null) {
              newLocalSettingStorage = stateStorageInit;
            }

            newLocalSettingStorage.recaptcha = true;
            newLocalSettingStorage.recaptchaValue =
              recaptchaRef.current.getValue();
            sessionStorage.setItem(
              "myLocalSettingStorage",
              JSON.stringify(newLocalSettingStorage)
            );
          }}
        />
      );
    }
  }

  // ######################################################
  // Step outlet
  // ######################################################
  const ProductPlanOutlet = (
    <div id="product-plan-scroll">
      {stateStorage.distributionTickDataCurrentStep === 0 ? (
        <Box sx={{ mt: 2 }}>{StepperOutlet}</Box>
      ) : null}

      <Box sx={{ mt: 2 }}>
        <ProductPlan
          content={content}
          loadingProp={loading}
          errorProp={error}
        />
      </Box>
    </div>
  );

  // ######################################################
  // Step outlet
  // ######################################################
  const ClientAccountOutlet = (
    <div id="client-account-scroll">
      {stateStorage.distributionTickDataCurrentStep === 1 ? (
        <Box sx={{ mt: 2 }}>{StepperOutlet}</Box>
      ) : null}
      <Box sx={{ mt: 2 }}>
        <ClientAccount
          content={content}
          loadingProp={loading}
          errorProp={error}
          RecaptchaOutlet={RecaptchaOutlet}
        />
      </Box>
    </div>
  );

  // ######################################################
  // Step outlet
  // ######################################################
  const BillingPaymentOutlet = (
    <div id="subscription-payment-scroll">
      {stateStorage.distributionTickDataCurrentStep === 2 ? (
        <Box sx={{ mt: 2 }}>{StepperOutlet}</Box>
      ) : null}
      <Box sx={{ mt: 2 }}>
        <BillingPayment
          content={content}
          loadingProp={loading}
          errorProp={error}
        />
      </Box>
    </div>
  );

  // ######################################################
  // Step outlet
  // ######################################################
  const GettingStartedOutlet = (
    <div id="getting-started-scroll">
      {stateStorage.distributionTickDataCurrentStep === 3 ? (
        <Box sx={{ mt: 2 }}>{StepperOutlet}</Box>
      ) : null}
      <Box sx={{ mt: 2 }}>
        <GettingStarted
          content={content}
          loadingProp={loading}
          errorProp={error}
        />
      </Box>
    </div>
  );

  // ######################################################
  // Main JSX return
  // ######################################################
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        direction="column"
        alignItems="center"
        sx={{
          width: {
            xs: "95vw",
            sm: "90vw",
            md: "80vw",
            lg: "75vw",
            xl: "70vw",
          },
        }}
      >
        <Stack spacing={0}>
          {process.env.REACT_APP_TICK_DATA_AVAILABLE === "false" &&
          !(hideDialogues === true) ? (
            <DialogueProductUnavailable />
          ) : null}

          {process.env.REACT_APP_PAYMENT_SERVICE_MAINTENANCE === "true" &&
          !(hideDialogues === true) ? (
            <DialoguePaymentServiceMaintenance />
          ) : null}

          {stateStorage.localizationLine !== null &&
          stateStorage.localizationLine.availability_distribution !== "1" ? (
            <DialogueDistributionUnavailable />
          ) : null}

          {stateStorage.distributionTickDataCurrentStep >= 0
            ? ProductPlanOutlet
            : null}

          {stateStorage.distributionTickDataCurrentStep >= 1
            ? ClientAccountOutlet
            : null}

          {stateStorage.distributionTickDataCurrentStep >= 2
            ? BillingPaymentOutlet
            : null}

          {stateStorage.distributionTickDataCurrentStep >= 3
            ? GettingStartedOutlet
            : null}
        </Stack>
      </Box>
    </Grid>
  );
}
